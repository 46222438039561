import { useQuery } from 'react-query'
import { getSurveyConfiguration } from 'src/api/services/survey'

const SURVEY_CONFIGURATION_QUERY_KEY = 'SURVEY_CONFIGURATION'

const useSurveyConfiguration = (configId: string) => {
  return useQuery({
    queryFn: () => getSurveyConfiguration(configId),
    queryKey: [SURVEY_CONFIGURATION_QUERY_KEY, configId]
  })
}

export default useSurveyConfiguration
