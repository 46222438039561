import moment from 'moment'
import { useQuery } from 'react-query'
import { QUERY_KEYS } from 'src/enums'
import { useAuth } from 'src/context/authContext'
import { getEvent, getEvents, getUserEvents } from 'src/api/services/events'

export const useGetEvents = () => {
  const { isLoggedIn, isAdmin } = useAuth()

  const { data, isError, isLoading } = useQuery([QUERY_KEYS.EVENTS], isAdmin ? getEvents : getUserEvents, {
    enabled: isLoggedIn
  })

  const events = data?.events?.map((event) => {
    return {
      id: event.id,
      link: event.link,
      title: event.name,
      description: event.comment,
      end: moment(event.endDateUtc).toISOString(),
      start: moment(event.startDateUtc).toISOString()
      // end: moment().format('2023-04-28T07:49:24.19+00:00'),
      // start: moment().format('2023-04-28T01:49:24.19+00:00')
    }
  })

  return { events, isError, isLoading }
}

export const useEvent = (eventId: string) => {
  const { isLoggedIn } = useAuth()

  return useQuery([QUERY_KEYS.EVENT, eventId], () => getEvent(eventId), { enabled: isLoggedIn })
}
