import { AxiosError } from 'axios'
import { QUERY_KEYS } from 'src/enums'
import { deleteEvent } from 'src/api/services/events'
import { useMutation, useQueryClient } from 'react-query'

export const useDeleteEvent = () => {
  const client = useQueryClient()

  return useMutation<{ success?: boolean }, AxiosError, string>(deleteEvent, {
    onSuccess: () => client.invalidateQueries([QUERY_KEYS.EVENTS])
  })
}
