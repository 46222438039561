import React from 'react'
import styled, { css } from 'styled-components'
import Section from 'src/components/common/Section'
import TeamDetails from './components/TeamDetails'
import UpcomingEventsList from './components/UpcumingEventsList'
import FeedbacksList from './components/FeedbacksList'
import InternshipProgram from './components/InternshipProgram'

const primary = 'primary'

const DetailsPageWrapper = styled.div`
  ${({ theme }) => css`
    padding: 80px 0 0;
    color: ${({ theme }) => theme.colors[primary]};
    min-height: 100vh;

    @media ${theme.devices.tablet} {
      padding-top: 40px;
    }
  `}
`
const WithSidebar = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 70px;
`

const AnalyticsPage = () => {
  return (
    <DetailsPageWrapper>
      <Section>
        <WithSidebar>
          <InternshipProgram />
          <UpcomingEventsList />
        </WithSidebar>
        <TeamDetails />
        {/*<FeedbacksList />*/}
      </Section>
    </DetailsPageWrapper>
  )
}

export default AnalyticsPage
