import React from 'react'
import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { useModal } from 'src/context/modalContext'
import { useGetUser } from 'src/hooks/queries/user'
import { useAuth } from 'src/context/authContext'
import Avatar from './Avatar'
import { colorPalette } from 'src/themes/theme'

const ProfileSideAndNavigationWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 48px;
    width: 190px;
    font-family: ${theme.fonts.family.primary};
  `}
`

const PictureNameStack = styled.div`
  min-width: 168px;
  text-align: center;
`

const ProfilePageNavigation = styled.ul`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 30px;

    @media ${theme.devices.tablet} {
      display: none;
    }
  `}
`

const ProfilePageNavigationItem = styled(NavLink)`
  ${({ theme }) => css`
    font-weight: 500;
    color: ${theme.pages.profilePage.profilePageNavigation.color};
    text-decoration: none;
    font-weight: 400;
    display: flex;
    gap: 13px;
    align-items: center;

    &.active {
      color: ${theme.pages.profilePage.profilePageNavigation.active};
    }

    &.active::before {
      content: '';
      display: block;
      width: 6px;
      height: 6px;
      background-color: ${theme.pages.profilePage.profilePageNavigation.dot};
      font-weight: 500;
    }
  `}
`
const LogOutLink = styled.a`
  ${({ theme }) => css`
    font-weight: 500;
    color: ${theme.pages.profilePage.profilePageNavigation.color};
    text-decoration: none;
    font-weight: 400;
    display: flex;
    gap: 13px;
    align-items: center;

    &:hover {
      cursor: pointer;
    }
  `}
`

const renderUserRole = (type: string) => {
  switch (type) {
    case 'SuperAdmin':
      return 'Super Admin'
    case 'EduTeamLead':
      return 'Team lead'
    case 'EduIntern':
      return 'Intern'
    case 'EduMentor':
      return 'Mentor'
    case 'EduSquadLead':
      return 'SquadLead'
    default:
      return ''
  }
}

const Username = styled.p`
  color: ${colorPalette.blue.primary[100]};
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 8px;
`

const UserRole = styled.p`
  color: ${colorPalette.grey.primary[100]};
  font-size: 14px;
  line-height: 21px;
`

const Sidebar = () => {
  const { setShowModal, setModalType } = useModal()
  const { user } = useGetUser()

  const { firstName, lastName, eduIntern, userTypeText = '' } = user || {}
  const stackName =
    user?.eduIntern?.eduStack.name || user?.eduMentor?.eduStack.name || user?.eduTeamLead?.eduStack.name || ''

  return (
    <ProfileSideAndNavigationWrapper>
      <PictureNameStack>
        <Avatar />
        <Username>
          {firstName} {lastName}
        </Username>
        <UserRole>
          {stackName} • {renderUserRole(userTypeText)}
        </UserRole>
      </PictureNameStack>
      <ProfilePageNavigation>
        {user?.userTypeText === 'EduTeamLead' && (
          <ProfilePageNavigationItem end to={'/pages/profile/my-stack'}>
            My Stack
          </ProfilePageNavigationItem>
        )}
        {user?.userTypeText === 'EduMentor' && (
          <ProfilePageNavigationItem end to={'/pages/profile/my-interns'}>
            My Interns
          </ProfilePageNavigationItem>
        )}
        <ProfilePageNavigationItem end to={'/pages/profile'}>
          Personal Information
        </ProfilePageNavigationItem>
        <ProfilePageNavigationItem to={'/pages/profile/change-password'}>Change Password</ProfilePageNavigationItem>
        <LogOutLink
          onClick={() => {
            setModalType('logoutModal')
            setShowModal(true)
          }}>
          Log Out
        </LogOutLink>
      </ProfilePageNavigation>
    </ProfileSideAndNavigationWrapper>
  )
}

export default Sidebar
