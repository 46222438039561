import React, { InputHTMLAttributes } from 'react'
import { colorPalette } from 'src/themes/theme'
import styled, { css } from 'styled-components'

const InputComponent = styled.div`
  ${({ theme }) => css`
    &:not(:first-of-type) {
      margin-top: 20px;
    }

    label {
      color: ${colorPalette.grey.primary[100]};
      font-family: ${theme.fonts.family.primary};
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
    }
  `}
`

const InputElementWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    border: 1px solid ${theme.components.form.inputReadOnly.borderColor};
    border-radius: ${theme.borderRadius.primary};
    align-items: center;
    padding-right: 0px;
    margin-top: 10px;

    input {
      width: 100%;
      padding: 10px 13px 10px 17px;
      outline: none;
      border: none;
      font-family: ${theme.fonts.family.primary};
      color: ${colorPalette.grey.primary[100]};
      font-weight: 300;
      background: ${theme.components.form.inputReadOnly.backgroundColor};
    }
  `}
`

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string
  type: string
  name: string
}

const InputReadOnly = ({ label, type, name, value }: InputProps) => {
  return (
    <InputComponent>
      <label>{label}</label>
      <InputElementWrapper>
        <input readOnly type={type} name={name} value={value} />
      </InputElementWrapper>
    </InputComponent>
  )
}

export default InputReadOnly
