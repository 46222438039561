import { DayCellContentArg, EventContentArg } from '@fullcalendar/core'
import FullCalendar from '@fullcalendar/react'
import React, { forwardRef, useEffect, useState } from 'react'
import DayOverviewModal from 'src/components/modals/DayOverviewModal'
import DeleteEventModal from 'src/components/modals/DeleteEventModal'
import EventInfoModal from 'src/components/modals/EventInfoModal'
import { useGetEvents } from 'src/hooks/queries/events/useGetEvents'
import styled, { css } from 'styled-components'
import { ICalendar, IDayProps } from '../../calendarTypes'
import { getDateEvents, injectElementInDOM } from '../../utils'
import Event from '../Event'
import { CalendarDesktopWrapper } from './calendarDesktop.styles'

const Day = styled.p<IDayProps>`
  ${({ isToday }) => css`
    font-size: 32px;
    font-weight: 600;
    ${isToday && 'color: #652CDB'}
  `}
`

const WeekDay = styled.p`
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
`

const DayHeader = ({ info }: any) => {
  return <WeekDay>{info.text}</WeekDay>
}

const MoreLink = ({ info }: any) => {
  return <p>More</p>
}

const DayCell = ({ info, onClick }: { info: DayCellContentArg; onClick: () => void }) => {
  return (
    <Day isToday={info.isToday} onClick={onClick}>
      {info.dayNumberText}
    </Day>
  )
}

// eslint-disable-next-line react/display-name
const CalendarDesktop = forwardRef<FullCalendar, ICalendar>(
  // eslint-disable-next-line react/prop-types
  ({ dayGridPlugin, timeGridPlugin, calendarView, CALENDAR_VIEW, aspectRatio }, ref) => {
    const [deleteEventId, setDeleteEventId] = useState<string | undefined>(undefined)
    const [selectedDay, setSelectedDay] = useState<Date>()
    const [selectedEvent, setSelectedEvent] = useState<
      | {
          id: string
          link: string
          title: string
          description: string
          end: string
          start: string
        }
      | undefined
    >()

    useEffect(() => {
      injectElementInDOM()

      /*  Everytime Calendar component is unmounted from DOM (for example when switching view or selecting month),
      "more" spans are removed and recreated not to cause multiple creations during component rerendering. 
      useEffect's return function is called after component is unmounted and thus serves removing part of the node.
    */

      return () => {
        document.querySelectorAll('.more')?.forEach((node) => {
          node.remove()
        })
      }
    })

    const { events } = useGetEvents()

    const handleDeleteClick = (eventId: string) => setDeleteEventId(eventId)

    const handleDayClick = (date: Date) => {
      if (!events) {
        return
      }
      const dateEvents = getDateEvents(date, events)

      if (dateEvents.length === 0) {
        return
      }

      setSelectedDay(date)
    }

    const handleEventSelect = (eventInfo: EventContentArg) => {
      if (!events) {
        return
      }

      const event = events.find((e) => e.id === eventInfo.event._def.publicId)

      if (!event) {
        return
      }

      setSelectedEvent(event)
    }

    return (
      <>
        <CalendarDesktopWrapper>
          <FullCalendar
            ref={ref}
            initialView={calendarView}
            plugins={[dayGridPlugin, timeGridPlugin]}
            headerToolbar={false}
            // eslint-disable-next-line react/prop-types
            height={CALENDAR_VIEW[0].value === calendarView ? 'auto' : aspectRatio()}
            events={events}
            displayEventEnd
            eventTimeFormat={{
              hour: '2-digit',
              minute: '2-digit',
              meridiem: 'short',
              hour12: false
            }}
            eventContent={(eventInfo) => (
              <Event eventInfo={eventInfo} onDelete={() => handleDeleteClick(eventInfo.event._def.publicId)} />
            )}
            eventClassNames={['my-event']}
            dayHeaderContent={(info) => <DayHeader info={info} />}
            dayCellClassNames={['my-day-cell-classname']}
            dayCellContent={(info) => <DayCell info={info} onClick={() => handleDayClick(info.date)} />}
            moreLinkContent={(info) => <MoreLink info={info} />}
            showNonCurrentDates={false}
            firstDay={1} // Start from Monday
          />
        </CalendarDesktopWrapper>
        {deleteEventId ? (
          <DeleteEventModal eventId={deleteEventId} onCancel={() => setDeleteEventId(undefined)} />
        ) : null}
        <DayOverviewModal
          date={selectedDay}
          onClose={() => setSelectedDay(undefined)}
          onClickEvent={(event) => setSelectedEvent(event)}
          eventSelected={!!selectedEvent}
        />
        <EventInfoModal event={selectedEvent} onClose={() => setSelectedEvent(undefined)} />
      </>
    )
  }
)
export default CalendarDesktop
