import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { createSurveySubmissionId, getSurveySubmission } from 'src/api/services/survey'

export const SURVEY_SUBMISSION_ID_QUERY_KEY = 'SURVEY_SUBMISSION_ID'

const useGetSurveySubmission = (configId: string) => {
  const { data, isError, refetch, ...rest } = useQuery({
    queryKey: [SURVEY_SUBMISSION_ID_QUERY_KEY, configId],
    queryFn: () => getSurveySubmission(configId)
  })

  useEffect(() => {
    const createNewSubmission = async () => {
      await createSurveySubmissionId(configId)

      refetch()
    }

    if (!isError) {
      return
    }

    createNewSubmission()
  }, [isError, configId, refetch])

  useEffect(() => {
    if (!data) {
      return
    }
  }, [data])

  return { data, isError, refetch, ...rest }
}

export default useGetSurveySubmission
