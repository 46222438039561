import React from 'react'
import styled from 'styled-components'
import Form from 'src/components/common/Form'
import Input from 'src/components/common/Input'
import { useFormHook } from 'src/hooks/custom'
import { useNavigate } from 'react-router'
import Button from 'src/components/common/buttons/Button'
import { ChangePasswordSchema } from 'src/utils/validations'
import { useChangePassword } from 'src/hooks/mutations/user'
import { colorPalette } from 'src/themes/theme'

type IChangePasswordFormInputs = {
  currentPassword: string
  newPassword: string
  confirmPassword: string
}

export const WarningText = styled.span`
  color: #bc5252;
`

const ChangePasswordForm = () => {
  const navigate = useNavigate()
  const { register, handleSubmit, errors } = useFormHook<IChangePasswordFormInputs>(ChangePasswordSchema)
  const { mutate, isLoading, isError } = useChangePassword()

  const onSubmit = ({ newPassword, currentPassword }: IChangePasswordFormInputs) => {
    mutate({ currentPassword, newPassword }, { onSuccess: () => navigate('/') })
  }

  return (
    <Form
      titleFontSize="22px"
      margin="0px"
      title="Change Password"
      onSubmit={handleSubmit(onSubmit)}
      titleResponsive
      text={
        <span>
          <WarningText>New password must contain:</WarningText> min. 3 characters, 1 lowercase, 1 uppercase, one numeric
          digit and one special character (.@#$%*)
        </span>
      }>
      <Input
        backgroundColor={colorPalette.additional[4]}
        type="password"
        name="currentPassword"
        id="currentPassword"
        label="Current Password"
        register={register}
        error={errors.currentPassword?.message}
      />
      <Input
        backgroundColor={colorPalette.additional[4]}
        type="password"
        name="newPassword"
        id="newPassword"
        label="New Password"
        register={register}
        margin="20px 0 0"
        error={errors.newPassword?.message}
      />
      <Input
        backgroundColor={colorPalette.additional[4]}
        type="password"
        name="confirmPassword"
        id="confirmPassword"
        label="Confirm Password"
        register={register}
        margin="20px 0 0"
        error={errors.confirmPassword?.message}
      />
      <Button buttonType="full" margin="32px 0" padding="9.5px 49px" disabled={isLoading}>
        Update
      </Button>
    </Form>
  )
}

export default ChangePasswordForm
