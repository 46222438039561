import { AxiosError } from 'axios'
import { decodeJWT } from 'src/helpers'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router'
import { useAuth } from 'src/context/authContext'
import { LoginPayload, LoginResponse, loginUser } from 'src/api/services/user'
import { USER_ROLES } from '../../../enums'

export const useLogin = () => {
  const navigate = useNavigate()
  const { setUserRole, setToken } = useAuth()

  const { mutate, isLoading, isError } = useMutation<LoginResponse, AxiosError, LoginPayload>(loginUser, {
    onSuccess: ({ token, refreshToken }) => {
      localStorage.setItem('access_token', token)
      localStorage.setItem('refresh_token', refreshToken)
      setToken(token)

      const { role } = decodeJWT()
      localStorage.setItem('role', role)
      setUserRole(role)

      if (role === USER_ROLES.STUDENT) {
        navigate('/pages/analytics')
      } else {
        navigate('/pages/syllabus')
      }
    }
  })

  return { mutate, isLoading, isError }
}
