import { axiosInstanceAdmin, axiosInstanceEdu } from 'src/components/interceptor'
import { IStack } from './stack'
import { SubjectType } from 'src/context/syllabusContext'
export interface CreateSyllabusPayload {
  Name: string
  EduStackId?: string
  Attachment?: File
}

export type MonthType = {
  id: string
  name: string
}

export interface AddSubjectPayload {
  eduSyllabusId: string
  monthId: string
  name: string
  description: string
  week: 1 | 2 | 3 | 4
  id?: string
}

interface AllSyllabusResponse {
  eduSyllabuses: EduSyllabus[]
}

export interface EduSyllabus {
  id: string
  name: string
  attachmentDownloadUrl: string
  eduStack: IStack
  eduSubjects: EduSyllabusSubject[]
}

export interface EduMonth {
  id: string
  name: string
}

export interface EduSyllabusSubject {
  description: string
  eduMonth: EduMonth
  eduSyllabus: {
    id: string
    name: string
  }
  id: string
  name: string
  week: 1
}

export interface GetSyllabusByIdPayload {
  eduSyllabus: EduSyllabus
}

export interface GetSyllabusByStackIdPayload {
  eduStackId: string
  eduSyllabus: EduSyllabus
}

export const createSyllabus = async (payload: CreateSyllabusPayload) => {
  const { data } = await axiosInstanceEdu.post('/api/v1/EduSyllabus', payload, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })

  return data
}

export const getAllSyllabus = async () => {
  const { data } = await axiosInstanceEdu.get<AllSyllabusResponse>('api/v1/EduSyllabus', {
    params: { page: 1, pageSize: 50 }
  })

  return data
}

export const getSyllabusById = async (id: string) => {
  const { data } = await axiosInstanceEdu.get<GetSyllabusByIdPayload>(`api/v1/EduSyllabus/${id}`)

  return data
}

export const getSyllabusByStackId = async (stackId: string) => {
  const { data } = await axiosInstanceEdu.get<GetSyllabusByStackIdPayload>(`api/v1/EduStack/${stackId}/syllabus`)

  return data
}

export const addSyllabusSubject = async (payload: AddSubjectPayload) => {
  const { data } = await axiosInstanceEdu.post('/api/v1/EduSubject', payload)

  return data
}

export const deleteSyllabusSubject = async (id: string) => {
  const { data } = await axiosInstanceEdu.delete(`/api/v1/EduSubject/${id}`)

  return data
}

export const editSyllabusSubject = async (payload: AddSubjectPayload) => {
  const { data } = await axiosInstanceEdu.put(`/api/v1/EduSubject/${payload.id}`, payload)

  return data
}

export const getSyllabus = async (stackId: string) => {
  const { data } = await axiosInstanceAdmin.get(`api/v1/EduSyllabus/stackId?stackId=${stackId}`)

  return data
}

export const addMonth = async (payload: { name: string }) => {
  const { data } = await axiosInstanceEdu.post('/api/v1/EduMonth', payload)

  return data
}

export const getMonths = async () => {
  const { data } = await axiosInstanceEdu.get<{ eduMonths: MonthType[] }>('api/v1/EduMonth')

  return data
}

export const deleteMonth = async (id: string) => {
  const { data } = await axiosInstanceEdu.delete(`/api/v1/EduMonth/${id}`)

  return data
}

export const deleteSyllabus = async (id: string) => {
  const { data } = await axiosInstanceEdu.delete(`/api/v1/EduSyllabus/${id}`)

  return data
}

export interface CreateSyllabusPDFPayload {
  Id: string
  Attachment: File
}

export const createSyllabusPDF = async (payload: FormData) => {
  const { data } = await axiosInstanceEdu.post('/api/v1/EduSyllabus/attachment-upload', payload)

  return data
}
