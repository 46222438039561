import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useGetUser } from 'src/hooks/queries/user'
import styled from 'styled-components'
import Icon from '../icon/Icon'
import { colorPalette } from 'src/themes/theme'
import { IUser } from 'src/api/services/user'
import MediaQuery from 'react-responsive'

const getUserRole = (user: IUser | undefined) => {
  if (!user) return ''

  if (user.eduIntern) {
    return `${user.eduIntern.eduStack.name} Intern`
  }

  if (user.eduMentor) {
    return `${user.eduMentor.eduStack.name} Developer`
  }

  if (user.eduTeamLead) {
    return `${user.eduTeamLead.eduStack.name} Team Lead`
  }

  return ''
}

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    cursor: pointer;
  }
`

const InfoContainer = styled.div`
  ${({ theme }) => `
    gap: 4px;
    color: ${colorPalette.neutral[1]};
    font-family: ${theme.fonts.family.primary}
    
`}
`

const UserName = styled.p`
  font-size: 14px;
  line-height: 24px;
`
const UserRole = styled.p`
  font-size: 12px;
  line-height: 20px;
`

const UserImage = styled.img`
  width: 42px;
  height: 42px;
  border-radius: 21px;
`

const ProfileLink = () => {
  const { user } = useGetUser()

  const navigate = useNavigate()

  const handleClick = () => {
    navigate('pages/profile')
  }

  return (
    <Container onClick={handleClick}>
      {user?.imageUrl ? <UserImage src={user.imageUrl} /> : <Icon iconName="profile" />}
      <MediaQuery minWidth={1025}>
        <InfoContainer>
          <UserName>
            {user?.firstName} {user?.lastName}
          </UserName>
          <UserRole>{getUserRole(user)}</UserRole>
        </InfoContainer>
      </MediaQuery>
      <Icon iconName="chevron" color={colorPalette.pink.primary[60]} size="18px" />
    </Container>
  )
}

export default ProfileLink
