import { AxiosError } from 'axios'
import { useMutation } from 'react-query'
import { RestorePasswordPayload, restorePassword } from 'src/api/services/user'

export const useRestorePassword = () => {
  const { mutate, isLoading, isError } = useMutation<{ success?: boolean }, AxiosError, RestorePasswordPayload>(
    restorePassword
  )

  return { mutate, isLoading, isError }
}
