import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from 'src/components/header/Header'
import Footer from 'src/components/footer/Footer'
import styled from 'styled-components'
import MobileNavigation from 'src/components/mobile-navigation/MobileNavigation'
import { useModal } from 'src/context/modalContext'
import ModalVariant from 'src/components/common/ModalVariants'
import BurgerMenuProvider from 'src/context/burgerMenuContext'

const Wrapper = styled.div`
  overflow: hidden;
  width: 100%;
  position: relative;
`

const Main = () => {
  const { showModal, modalType } = useModal()

  return (
    <BurgerMenuProvider>
      <Wrapper>
        <MobileNavigation />
        <Header />
        <Outlet />
        <Footer />
        {showModal && <ModalVariant modalType={modalType} />}
      </Wrapper>
    </BurgerMenuProvider>
  )
}

export default Main
