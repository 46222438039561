import styled, { css } from 'styled-components'

type BaseButtonType = {
  margin?: string
  padding: string
}

const baseButton = styled.button<BaseButtonType>`
  ${({ theme, margin = '0px', padding }) => css`
    outline: none;
    font-family ${theme.fonts.family.primary}; 
    font-size: ${theme.components.buttons.fontSize};
    font-weight: 600;
    border-radius: ${theme.borderRadius.primary};
    margin: ${margin};
    color: ${theme.components.buttons.color};
    padding: ${padding};

    &:hover {
      cursor: pointer;
    }
  `}
`

export const buttonOutline = styled(baseButton)`
  ${({ theme }) => css`
    border: 1px solid ${theme.components.buttons.buttonOutline.borderColor};
    background-color: ${theme.components.buttons.buttonOutline.backgroundColor};
  `}
`

export const buttonFull = styled(baseButton)`
  ${({ theme, disabled }) => css`
    border: 1px solid
      ${!disabled
        ? theme.components.buttons.buttonFull.borderColor
        : theme.components.buttons.buttonFull.disabled.borderColor};
    background-color: ${!disabled
      ? theme.components.buttons.buttonFull.backgroundColor
      : theme.components.buttons.buttonFull.disabled.backgroundColor};
  `}
`

export const buttonAccordion = styled(baseButton)`
  ${({ theme }) => css`
    color: ${theme.components.buttons.buttonAccordion.color};
    background-color: ${theme.components.buttons.buttonAccordion.backgroundColor};
    border: 1px solid ${theme.components.buttons.buttonAccordion.backgroundColor};
  `}
`

export const buttonAccordionAdmin = styled(baseButton)`
  ${({ theme }) => css`
    background-color: ${theme.components.buttons.buttonAccordionAdmin.backgroundColor};
    border: 1px solid ${theme.components.buttons.buttonAccordionAdmin.backgroundColor};
  `}
`

export const buttonEvents = styled(baseButton)`
  ${({ theme }) => css`
    background-color: ${theme.components.buttons.buttonEvents.backgroundColor};
    border: 1px solid ${theme.components.buttons.buttonEvents.backgroundColor};
  `}
`
