import React from 'react'
import styled, { css } from 'styled-components'
import PositionBadge from '../../../components/common/PositionBadge/PositionBadge'
import UserInfoRow from '../../../components/common/UserInfoRow/UserInfoRow'
import { useGetInternAnalytic, useGetUser } from '../../../hooks/queries/user'
import { theme } from '../../../themes/theme'

const SectionWrapper = styled.div`
  margin-top: 50px;
`

const TeamDetailsWrapper = styled.div`
  border: 1px solid #f7f5fa;
  border-radius: 6px;
  padding: 44px 60px;
`

const Wrapper = styled.ul`
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr 120px;
  margin-bottom: 5px;

  @media ${theme.devices.tablet} {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #f7f5fa;
  }
`

const TitleHeader = styled.h2`
  color: #170738;
  font-size: 22px;
  margin-bottom: 28px;
`
const TeamDetails = () => {
  const { user } = useGetUser()
  const { data: intern } = useGetInternAnalytic(user?.eduIntern?.id || '')

  const mentorName = intern?.mentor ? `${intern?.mentor?.firstName || ''} ${intern?.mentor?.lastName || ''}` : ''
  const mentorEmail = intern?.mentor?.email
  const teamLeadName = intern?.teamLead
    ? `${intern?.teamLead?.firstName || ''} ${intern?.teamLead?.lastName || ''}`
    : ''
  const teamLeadEmail = intern?.teamLead?.email
  const squadLeadName = intern?.squadLead
    ? `${intern?.squadLead?.firstName || ''} ${intern?.squadLead?.lastName || ''}`
    : ''
  const squadLeadEmail = intern?.squadLead?.email

  const interns =
    intern?.interns.map((intern: any) => ({
      name: `${intern?.firstName || ''} ${intern?.lastName || ''}`,
      email: intern?.email,
      role: 'Intern',
      isValid: Boolean(`${intern?.firstName || ''} ${intern?.lastName || ''}`)
    })) || []

  const users = intern
    ? [
        { name: mentorName, email: mentorEmail, role: 'Mentor', isValid: Boolean(mentorName) },
        { name: teamLeadName, email: teamLeadEmail, role: 'Team Lead', isValid: Boolean(teamLeadName) },
        { name: squadLeadName, email: squadLeadEmail, role: 'Squad Lead', isValid: Boolean(squadLeadName) }
      ].concat(interns)
    : []
  return (
    <SectionWrapper>
      <TitleHeader>Team</TitleHeader>
      <TeamDetailsWrapper>
        {users
          .filter((item) => Boolean(item.isValid))
          .map((item) => {
            return (
              <Wrapper key={item.name}>
                <div>
                  <UserInfoRow title={item.name} iconPath="iconInvertedProfile" />
                </div>
                <div>
                  <UserInfoRow title={item.email} />
                </div>
                <div>
                  <PositionBadge>{item.role}</PositionBadge>
                </div>
              </Wrapper>
            )
          })}
      </TeamDetailsWrapper>
    </SectionWrapper>
  )
}

export default TeamDetails
