import React from 'react'
import { useGetSyllabus } from '../../hooks/queries/syllabus'
import StudyMaterialItem from './StudyMaterialItem'
import styled from 'styled-components'
import Row from '../../components/common/Row'

interface SyllabusContentProps {
  syllabusId: string
  monthId: string
  activeWeek: number
}

const MaterialItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const Title = styled.h2`
  font-size: 24px;
  color: #073763;
  font-weight: 700;
  margin-bottom: 24px;
`

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: #fbfcff;
  padding: 24px;
  margin-bottom: 64px;
`

const Description = styled.p`
  font-size: 14px;
  font-weight: 700;
  color: #073763;
`

const Ball = styled.div`
  border-radius: 50%;
  background-color: #fb60a5;
  width: 12px;
  height: 12px;
`

const Text = styled.p`
  font-weight: 500;
  color: #55555f;
  font-size: 14px;
`
const SyllabusContent = ({ monthId, syllabusId, activeWeek }: SyllabusContentProps) => {
  const { data } = useGetSyllabus(syllabusId)
  const subjects = data?.eduSyllabus?.eduSubjects || []
  const filteredSubs = subjects?.filter((sub) => sub?.eduMonth?.id === monthId && sub?.week === activeWeek)
  return (
    <div>
      <DescriptionWrapper>
        <Title>What you will learn</Title>
        <Description>Week {activeWeek}:</Description>
        {filteredSubs?.map((sub, index) => {
          return (
            <Row key={sub.id} gap="8px" alignItems="center" justify="flex-start" margin={'8px'}>
              <Ball />
              <Text>{sub.name}</Text>
            </Row>
          )
        })}
      </DescriptionWrapper>
      <Title>Study Material</Title>
      <MaterialItemsWrapper>
        {filteredSubs?.map((sub, index) => {
          return <StudyMaterialItem key={sub.id} data={sub} colored={index % 2 !== 0} />
        })}
      </MaterialItemsWrapper>
    </div>
  )
}

export default SyllabusContent
