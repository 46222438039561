import React, { forwardRef } from 'react'
import FullCalendar from '@fullcalendar/react'
import { ICalendar } from '../../calendarTypes'
import { CalendarMobileWrapper, WeekDay, Day } from './calendarMobile.styles'
import { dateFormatter } from '../../utils'
import { useGetEvents } from 'src/hooks/queries/events/useGetEvents'
import styled from 'styled-components'

const DayHeader = ({ info }: any) => {
  return <WeekDay>{info.text}</WeekDay>
}

interface IDayCell {
  info: Info
  eventDays: Set<string>
}

interface Info {
  date: Date
  isToday: boolean
  dayNumberText: string
}

const DayCell = ({ info, eventDays }: IDayCell) => {
  // Checking if there is any event on this day
  const hasEvent = eventDays.has(dateFormatter(info.date))

  return (
    <Day hasEvent={hasEvent} isToday={info.isToday}>
      {info.dayNumberText}
    </Day>
  )
}

// eslint-disable-next-line react/display-name
const CalendarMobile = forwardRef<FullCalendar, ICalendar>(
  ({ calendarView, dayGridPlugin, timeGridPlugin, CALENDAR_VIEW, aspectRatio }, ref) => {
    // All event dates
    const { events } = useGetEvents()
    const allEventDates = events?.map(({ start }) => {
      return dateFormatter(start)
    })

    // Only unique event dates
    const eventDays = new Set<string>(allEventDates)

    return (
      <CalendarMobileWrapper>
        <FullCalendar
          ref={ref}
          initialView={calendarView}
          plugins={[dayGridPlugin, timeGridPlugin]}
          headerToolbar={false}
          events={events}
          height={CALENDAR_VIEW[0].value === calendarView ? 'auto' : aspectRatio()}
          dayHeaderContent={(info) => <DayHeader info={info} />}
          dayCellContent={(info) => <DayCell info={info} eventDays={eventDays} />}
          dayCellClassNames={['my-day-cell-classname']}
          eventClassNames={['my-event']}
        />
      </CalendarMobileWrapper>
    )
  }
)

export default CalendarMobile
