import React from 'react'
import Program from './components/program-section/Program'
import StackSection from './components/stack-section/StackSection'
import Gallery from './components/gallery/Gallery'
import Benefits from './components/benefit-section/Benefits'
import styled from 'styled-components'
import Hero from './components/hero-section/Hero'
import ContuctUs from './components/contact-us-section/ContactUs'

const LandingPageWrapper = styled.div`
  font-family: ${({ theme }) => theme.fonts.family.primary};
`

const LandingPage = () => {
  return (
    <LandingPageWrapper>
      <Hero />
      <Program />
      <StackSection />
      <Gallery />
      <Benefits />
      <ContuctUs />
    </LandingPageWrapper>
  )
}

export default LandingPage
