import React from 'react'
import Button from 'src/components/common/buttons/Button'
import Column from 'src/components/common/Column'
import Row from 'src/components/common/Row'
import Icon from 'src/components/icon/Icon'
import { Month, ToolbarViewButtonComponent } from './customToolbar.styles'
import { CALENDAR_VIEW } from '../../calendarTypes'
import { useModal } from 'src/context/modalContext'
import SectionHeadingComp from 'src/components/common/SectionHeading'
import MediaQuery from 'react-responsive'
import { theme } from 'src/themes/theme'
import { useNavigate } from 'react-router'
import { useAuth } from 'src/context/authContext'

type ICustomToolbar = {
  month: string | null
  setCalendarView: (value: string) => void
  calendarView: string
  goToNextMonth: () => void
  goToPrevMonth: () => void
}

const CustomToolbar = ({ month, setCalendarView, calendarView, goToNextMonth, goToPrevMonth }: ICustomToolbar) => {
  const { setShowModal, setModalType } = useModal()
  const navigate = useNavigate()
  const { isIntern } = useAuth()

  const handleModalOpen = () => {
    setModalType('addEventModal')
    setShowModal(true)
  }

  interface IArrows {
    size?: string
  }

  const Arrows = ({ size = '20px' }: IArrows) => {
    return (
      <Column flex gap="40px">
        <Icon onClick={goToPrevMonth} size={size} rotate="180" iconName="iconCalendarArrow" />
        <Icon onClick={goToNextMonth} size={size} iconName="iconCalendarArrow" />
      </Column>
    )
  }

  const button = (
    <Column>
      <Button onClick={handleModalOpen} padding="9.5px 42px" buttonType="events">
        Add Event
      </Button>
    </Column>
  )

  const buttonPlus = (
    <Column>
      <Icon
        onClick={() => navigate('add-event')}
        color={theme.colors.primary}
        size="16px"
        cursorPointer
        iconName="iconPlus"
      />
    </Column>
  )

  const monthComp = (
    <Column>
      <Month>{month}</Month>
    </Column>
  )

  const sectionHeading = (
    <SectionHeadingComp mobile marginBottom="0">
      Events
    </SectionHeadingComp>
  )

  const toolbarViewButton = (
    <Column>
      <>
        {CALENDAR_VIEW.map(({ view, value }) => {
          return (
            <ToolbarViewButtonComponent
              onClick={() => setCalendarView(value)}
              key={view}
              isActive={calendarView === value}>
              {view}
            </ToolbarViewButtonComponent>
          )
        })}
      </>
    </Column>
  )

  return (
    <>
      <MediaQuery minWidth={+theme.devices.tabletLandscape.slice(-7, -3)}>
        <Row margin="0 0 54px 0">
          {monthComp}
          <>{!isIntern && <>{button}</>}</>
        </Row>
        <Row margin="0 0 66px 0">
          {toolbarViewButton}
          <Arrows />
        </Row>
      </MediaQuery>
      <MediaQuery maxWidth={+theme.devices.tabletLandscape.slice(-7, -3)}>
        <Row margin="0 0 30px 0">
          {sectionHeading}
          <>{!isIntern && <>{buttonPlus}</>}</>
        </Row>
        <Row margin="0 0 32px 0">
          {monthComp}
          <Arrows size="16px" />
        </Row>
        <Row margin="0 0 40px 0">{toolbarViewButton}</Row>
      </MediaQuery>
    </>
  )
}

export default CustomToolbar
