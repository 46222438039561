import { AxiosError } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { EditUserPayload, editUserInfo } from 'src/api/services/user'
import { QUERY_KEYS } from 'src/enums'

const useEditUser = () => {
  const client = useQueryClient()

  return useMutation<void, AxiosError, EditUserPayload & { userId: string }>({
    mutationFn: ({ userId, ...payload }) => editUserInfo(userId, payload),
    onSettled: () => client.invalidateQueries([QUERY_KEYS.USER_PROFILE])
  })
}

export default useEditUser
