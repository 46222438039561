import { useQuery } from 'react-query'
import { QUERY_KEYS } from 'src/enums'
import { useAuth } from 'src/context/authContext'
import { getUserInfo } from 'src/api/services/user'

export const useGetUser = () => {
  const { isLoggedIn } = useAuth()

  const { data, isError, isLoading, refetch } = useQuery([QUERY_KEYS.USER_PROFILE], getUserInfo, {
    enabled: isLoggedIn
  })

  return { user: data?.user, isError, isLoading, refetch }
}
