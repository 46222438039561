import { axiosInstanceEdu } from '../../components/interceptor'

export interface CourseOverviewResponse {
  page: number
  pageSize: number
  total: number
  courseOverviews: CourseOverview[]
}

export interface CourseOverview {
  id: string
  name: string
  description: string
  position: number
  eduStack: EduStack
}

export interface EduStack {
  id: string
  name: string
  description: string
  eduStackType: number
}

export const getCourseOverview = async (eduStackId: string) => {
  const { data } = await axiosInstanceEdu.get<CourseOverviewResponse>('api/v1/CourseOverview', {
    params: { page: 1, pageSize: 50, eduStackId }
  })

  return data
}
