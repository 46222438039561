import React from 'react'
import styled, { css } from 'styled-components'
import Section from 'src/components/common/Section'
import SectionHeadingComp from 'src/components/common/SectionHeading'
import { data } from './data'
import SectionText from 'src/components/common/SectionText'
import Icon from 'src/components/icon/Icon'
import { colorPalette, theme } from 'src/themes/theme'

const StackSectionWrapper = styled.div`
  color: ${({ theme }) => theme.layout.section.heading.color};
`

const StackList = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 11px 14px;
  margin: 40px 0 160px;

  @media ${({ theme }) => theme.devices.laptop} {
    grid-template-columns: repeat(auto-fit, minmax(296px, 1fr));
    grid-auto-rows: max-content;
  }
`
const StackListItem = styled.li`
  ${({ theme }) => css`
    padding: 50px 10px;
    background-color: ${theme.layout.section.listItem.backgroundColor};
    border-radius: ${theme.borderRadius.primary};
    transition: box-shadow 0.2s ease-in;

    &:hover {
      box-shadow: inset 0 0 0 1px ${theme.pages.landingPage.stacksSection.onHover.borderColor};
  `}
`

const StackListItemTitleWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 25px;

    span:first-child {
      svg {
        display: block;
        fill: inherit;
      }
    }

    span:last-child {
      ${({ theme }) => css`
        font-weight: 600;
        font-size: ${theme.pages.landingPage.stacksSection.stackList.title.size.laptop};
        color: ${colorPalette.blue.primary[100]};

        @media ${theme.devices.mobile} {
          font-size: ${theme.pages.landingPage.stacksSection.stackList.title.size.mobile};
        }
      `}
    }
  }
`
const StackListItemContentWrapper = styled.div`
  ${({ theme }) => css`
    max-width: 400px;
    margin: 0 auto;
    padding: 0 10px;

    p {
      font-family: ${theme.fonts.family.secondary};
      font-weight: 400;

      font-size: ${theme.pages.landingPage.stacksSection.stackList.text.size.laptop};

      @media ${theme.devices.mobile} {
        font-size: ${theme.pages.landingPage.stacksSection.stackList.text.size.mobile};
      }
    }
  `}
`

const StackTechnologies = styled.p`
  ${({ theme }) => css`
    color: ${colorPalette.grey.primary[100]};
    font-family: ${theme.fonts.family.primary};
    font-weight: 400;
  `}
`

const StackSection = () => {
  return (
    <StackSectionWrapper>
      <Section>
        <SectionHeadingComp color={colorPalette.blue.primary[100]}>
          Software development and marketing stacks
        </SectionHeadingComp>
        <SectionText fontFamily={theme.fonts.family.secondary} color={colorPalette.grey.primary[100]} fontWeight="400">
          We provide career opportunities in different directions
        </SectionText>
        <StackList>
          {data.map(({ stack, technologies, icon }, id) => {
            return (
              <StackListItem key={id}>
                <StackListItemContentWrapper>
                  <StackListItemTitleWrapper>
                    {/* <span>{icon}</span> */}
                    <Icon iconName={icon} />
                    <span>{stack}</span>
                  </StackListItemTitleWrapper>
                  <StackTechnologies>{technologies}</StackTechnologies>
                </StackListItemContentWrapper>
              </StackListItem>
            )
          })}
        </StackList>
      </Section>
    </StackSectionWrapper>
  )
}

export default StackSection
