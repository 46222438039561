import React from 'react'
import styled, { css } from 'styled-components'

// NOTE: component style

interface IColumnComponentProps {
  flex?: boolean
  gap?: string
  width?: string
  justify?: string
  align?: string
  flexProp?: string
  textAlign?: string
  tabletLandscape?: {
    justifyForResponsive: string
  }
  mobile?: {
    flexProp: string
  }
}

export const ColumnComponent = styled.div<IColumnComponentProps>`
  ${({ theme, flex, gap, width, justify, flexProp, tabletLandscape, textAlign, mobile, align = 'center' }) => css`
    ${flex && `display: flex`};
    ${gap &&
    css`
      gap: ${gap};
    `};
    ${width &&
    css`
      width: ${width};
    `};
    ${justify &&
    css`
      justify-content: ${justify};
    `};
    align-items: ${align};
    ${flexProp &&
    css`
      flex: ${flexProp};
    `};
    ${textAlign &&
    css`
      text-align: ${textAlign};
    `};
    ${tabletLandscape &&
    `@media ${theme.devices.tabletLandscape} {
      width: 100%;
      justify-content: ${tabletLandscape?.justifyForResponsive};
    }`}
    ${mobile &&
    `@media ${theme.devices.mobile} {
      flex: ${mobile?.flexProp};
    }`}
  `}
`

// NOTE: component

interface IColumnProps extends IColumnComponentProps {
  children: JSX.Element | JSX.Element[]
}

const Column = ({
  children,
  flex,
  gap,
  width,
  justify,
  flexProp,
  tabletLandscape,
  textAlign,
  mobile,
  align
}: IColumnProps) => {
  return (
    <ColumnComponent
      flex={flex}
      gap={gap}
      width={width}
      justify={justify}
      align={align}
      flexProp={flexProp}
      tabletLandscape={tabletLandscape}
      textAlign={textAlign}
      mobile={mobile}>
      {children}
    </ColumnComponent>
  )
}

export default Column
