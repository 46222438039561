import styled, { css } from 'styled-components'
import { IDayProps } from '../../calendarTypes'

export const CalendarMobileWrapper = styled.div`
  ${({ theme }) => css`
    // NOTE: Disabling table border
    .fc-theme-standard td,
    .fc-theme-standard th {
      border: 10px solid transparent;
    }

    .fc-theme-standard .fc-scrollgrid {
      border: none;
    }

    .fc .my-day-cell-classname {
      // NOTE: styling date row
      .fc-daygrid-day-top {
        transition: padding-left 0.2s;
        justify-content: center;
        margin-bottom: 10px;
      }

      // NOTE: min-height of cell
      .fc-daygrid-day-frame.fc-scrollgrid-sync-inner {
        max-height: 9.4em;
      }
    }

    // NOTE: styling date number element
    .fc .fc-daygrid-day-number {
      padding: 0 !important;
    }

    // NOTE: overwriting current day styles
    .fc .fc-daygrid-day.fc-day-today {
      background-color: transparent !important;
    }

    // NOTE: hidding events wrapper
    .fc .fc-daygrid-body-unbalanced .my-day-cell-classname .fc-daygrid-day-events {
      display: none;
    }
  `}
`

export const WeekDay = styled.p`
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
`

export const Day = styled.p<IDayProps>`
  ${({ isToday, hasEvent }) => css`
    font-size: 18px;
    font-weight: 400;
    position: relative;
    width: 26px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    ${isToday && 'color: #fff'};

    ${isToday &&
    css`
      &::before {
        content: '';
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        display: block;
        position: absolute;
        background-color: #b196c1;
      }
    `}

    ${hasEvent &&
    css`
      &&:after {
        content: '';
        bottom: -6px;
        left: 50%;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        position: absolute;
        background-color: #97c08b;
        transform: translateX(-50%);
      }
    `}
  `}
`
