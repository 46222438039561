import React from 'react'
import styled from 'styled-components'
import InternshipChart from '../../../components/charts/InternshipChart'
import { useGetUserAnalytics } from '../../../hooks/queries/user/useGetUserAnalytics'

const SectionWrapper = styled.div`
  flex-basis: 0;
  flex-grow: 999;
  min-inline-size: 50%;
`

const InternshipWrapper = styled.div`
  border: 1px solid #f7f5fa;
  border-radius: 6px;
  padding: 44px 60px;
`

const TitleHeader = styled.h2`
  color: #170738;
  font-size: 22px;
  margin-bottom: 28px;
`

const InternshipProgram = () => {
  const { data: analytics, isLoading, error } = useGetUserAnalytics()
  if (isLoading) return <div>Loading...</div>
  if (error) {
    return <div>Error</div>
  }

  return (
    <SectionWrapper>
      <TitleHeader>Internship Program (3/6 Months)</TitleHeader>
      <InternshipWrapper>{analytics && <InternshipChart data={analytics} />}</InternshipWrapper>
    </SectionWrapper>
  )
}

export default InternshipProgram
