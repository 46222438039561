import React from 'react'
import { buttonOutline, buttonFull, buttonAccordion, buttonAccordionAdmin, buttonEvents } from './buttons.styles'

const ButtonVariants = {
  outline: buttonOutline,
  full: buttonFull,
  accordion: buttonAccordion,
  accordionAdmin: buttonAccordionAdmin,
  events: buttonEvents
}

type ButtonTypes = keyof typeof ButtonVariants

type IButton = {
  children: string
  buttonType: ButtonTypes
  disabled?: boolean
  onClick?: () => void
  margin?: string
  padding: string
  type?: 'submit' | 'reset' | 'button' | undefined
}

const Button = ({ children, buttonType, disabled, margin, padding, ...props }: IButton) => {
  const CustomButton = ButtonVariants[buttonType]

  return (
    <CustomButton {...props} margin={margin} padding={padding} disabled={disabled}>
      {children}
    </CustomButton>
  )
}

export default Button
