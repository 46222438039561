import React from 'react'
import { Routes, Route } from 'react-router'
import AxiosInterceptor from 'src/components/interceptor'

import Main from 'src/pages/Main'
import PageWrapper from 'src/pages/PageWrapper'
import Notifications from 'src/pages/notifications'
import EventsPage from 'src/pages/events/EventsPage'
import LandingPage from 'src/pages/landing/LandingPage'
import ProfilePage from 'src/pages/profile/ProfilePage'
import AnalyticsPage from 'src/pages/analytics/AnalyticsPage'
import SyllabusListPage from 'src/pages/syllabusList/SyllabusPage'
import LogIn from 'src/components/forms/authorization/LogInForm'
import QuestionnariesAndExamsPage from 'src/pages/questionnaries'
import Authorization from 'src/pages/authorization/Authorization'
import ChangePasswordForm from 'src/components/forms/profile/ChangePasswordForm'
import ResetPassword from 'src/components/forms/authorization/ResetPasswordForm'
import AddEventPage from 'src/pages/events/Calendar/components/AddEventPage'
import ForgotPassword from 'src/components/forms/authorization/ForgotPasswordForm'
import PersonalInformationForm from 'src/components/forms/profile/PersonalInformationForm'
import EditEventPage from 'src/pages/events/EditEventPage'
import Syllabus from 'src/pages/syllabus'
import MyStack from 'src/components/forms/profile/MyStack'
import MyInterns from 'src/components/forms/profile/MyInterns'
import SurveysPage from 'src/pages/surveys'
import SurveyQuestionsPage from 'src/pages/surveyQuestions'
import CourseOverview from 'src/pages/courseOverview/CourseOverview'
import CourseOverviewContent from '../pages/courseOverview/CourseOverviewContent'
import DesignAndTimingPage from '../pages/design/DesignAndTimingPage'

const Router = () => {
  return (
    <AxiosInterceptor>
      <Routes>
        <Route path="/" element={<Main />}>
          <Route index element={<LandingPage />} />
          <Route path="pages" element={<PageWrapper />}>
            <Route path="profile" element={<ProfilePage />}>
              <Route index element={<PersonalInformationForm />} />
              <Route path="change-password" element={<ChangePasswordForm />} />
              <Route path="my-stack" element={<MyStack />} />
              <Route path="my-interns" element={<MyInterns />} />
            </Route>
            <Route path="syllabus" element={<SyllabusListPage />} />
            <Route path="syllabus/:id" element={<Syllabus />} />
            <Route path="events" element={<EventsPage />} />
            <Route path="events/add-event" element={<AddEventPage />} />
            <Route path="event/:id/edit" element={<EditEventPage />} />
            <Route path="notifications" element={<Notifications />} />
            <Route path="exams" element={<SurveysPage isExam />} />
            <Route path="surveys" element={<SurveysPage />} />
            <Route path="survey/:id" element={<SurveyQuestionsPage />} />
            <Route path="questionnaries-and-exams" element={<QuestionnariesAndExamsPage />} />
            <Route path="overview/:stackId" element={<CourseOverview />}>
              <Route index element={<CourseOverviewContent />} />
              <Route path="design/:monthId" element={<DesignAndTimingPage />} />
            </Route>
            <Route path="analytics" element={<AnalyticsPage />} />
          </Route>
        </Route>
        <Route path="/authorization" element={<Authorization />}>
          <Route index element={<LogIn />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="reset-password" element={<ResetPassword />} />
        </Route>
      </Routes>
    </AxiosInterceptor>
  )
}

export default Router
