import React from 'react'
import { useParams } from 'react-router'
import Section from 'src/components/common/Section'
import SyllabusProvider from 'src/context/syllabusContext'
import { useGetSyllabus } from 'src/hooks/queries/syllabus'
import styled from 'styled-components'
import MonthComponent from '../syllabusList/components/MonthComponent'
import Week from '../syllabusList/components/WeekComponent'
import SyllabusPDF from './components/SyllabusPDF'

const weeksArr = [1, 2, 3, 4]

const Container = styled.div`
  padding: 70px 0;
  color: ${({ theme }) => theme.colors.primary};
  min-height: 100vh;
`

const Syllabus = () => {
  const { id = '' } = useParams()

  const { isLoading } = useGetSyllabus(id)

  if (isLoading) {
    return <Container />
  }
  return (
    <SyllabusProvider>
      <Container>
        <Section>
          <MonthComponent />
          <SyllabusPDF />
          {weeksArr.map((week) => {
            return <Week key={week} week={week} progress="30" />
          })}
        </Section>
      </Container>
    </SyllabusProvider>
  )
}

export default Syllabus
