import styled, { css } from 'styled-components'

export const AuthorizationWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    height: 100vh;

    @media ${theme.devices.tablet} {
      flex-direction: column-reverse;
    }
  `}
`

export const AuthorizationSide = styled.div`
  ${({ theme }) => css`
    height: 100%;
    background-color: ${theme.colors.secondary};
    flex-basis: 40%;
    display: flex;
    padding: 0 40px;

    @media ${theme.devices.tablet} {
      flex-basis: 60%;
    }
  `}
`
interface BackgroundSideProps {
  imageUrl: string
}

export const BackgroundSide = styled.div<BackgroundSideProps>`
  ${({ theme, imageUrl }) => css`
    height: 100%;
    flex-basis: 60%;
    position: relative;
    background-image: linear-gradient(
        to right,
        ${theme.pages.landingPage.heroSection.backgroundColor},
        ${theme.pages.landingPage.heroSection.backgroundColor}
      ),
      url(${imageUrl});
    background-size: cover;
    background-position: center;

    @media ${theme.devices.tablet} {
      flex-basis: 40%;
    }

    div {
      position: absolute;
      top: 93px;
      left: 32px;
      cursor: pointer;

      @media ${theme.devices.tablet} {
        top: auto;
        left: auto;
        bottom: 27px;
        right: 27px;
      }
    }
  `}
`
