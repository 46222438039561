import { AxiosError } from 'axios'
import { useMutation } from 'react-query'
import { ChangePasswordPayload, changePassword } from 'src/api/services/user'

export const useChangePassword = () => {
  const { mutate, isLoading, isError } = useMutation<{ success?: boolean }, AxiosError, ChangePasswordPayload>(
    changePassword
  )

  return { mutate, isLoading, isError }
}
