import styled, { css } from 'styled-components'

export const FooterComponent = styled.footer`
  ${({ theme }) => css`
    background-color: ${theme.layout.footer.backgroundColor};
    padding: 36px 0 40px;
    color: ${theme.layout.footer.textColor};
    font-family: ${({ theme }) => theme.fonts.family.primary};
    overflow: hidden;
  `}
`

export const PrivacyPolicy = styled.a.attrs({
  href: '#'
})`
  ${({ theme }) => css`
    font-family: ${theme.fonts.family.secondary};
    color: ${theme.layout.footer.privacyAndCopyright.color};
    font-size: ${theme.layout.footer.privacyAndCopyright.size};
    font-weight: 400;
    margin-right: 20px;
  `}
`

export const Copyright = styled.span`
  ${({ theme }) => css`
    opacity: 0.8;
    font-family: ${theme.fonts.family.secondary};
    color: ${theme.layout.footer.privacyAndCopyright.color};
    font-size: ${theme.layout.footer.privacyAndCopyright.size};
    font-weight: 400;
    text-align: end;
  `}
`
