import { AxiosResponse } from 'axios'
import { axiosInstanceEdu } from 'src/components/interceptor'

export interface Notification {
  id: string
  title: string | null
  description: string | null
  isRead: boolean
  createDate: string
}

export interface GetNotificationsParams {
  page: number
  pageSize: number
}

interface GetNotificationsPayload {
  page: number
  pageSize: number
  total: number
  internalNotifications: Notification[] | null
  totalUnread: number
}

export const getNotifications = async ({ page, pageSize }: GetNotificationsParams) => {
  const token = localStorage.getItem('access_token')

  if (!token) {
    throw new Error('Unauthorized')
  }

  const { data }: AxiosResponse<GetNotificationsPayload> = await axiosInstanceEdu.get(
    `api/v1/Notifications/internal?page=${page}&pageSize=${pageSize}`,
    {
      headers: { Authorization: `Bearer ${token}` }
    }
  )

  return data
}

export const readNotification = async (notificationId: string) => {
  const token = localStorage.getItem('access_token')

  if (!token) {
    throw new Error('Unauthorized')
  }

  const { data }: AxiosResponse<GetNotificationsPayload> = await axiosInstanceEdu.patch(
    `api/v1/Notifications/${notificationId}`,
    undefined,
    {
      headers: { Authorization: `Bearer ${token}` }
    }
  )

  return data
}
