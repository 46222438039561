import React from 'react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import { useCalendarAPI } from './hooks/useCalendarAPI'
import { CALENDAR_VIEW } from './calendarTypes'
import CustomToolbar from './components/custom-toolbar/CustomToolbar'
import CalendarDesktop from './components/calendar-desktop/CalendarDesktop'
import { CalendarWrapper } from './index.styles'
import CalendarMobile from './components/calendar-mobile/CalendarMobile'
import { theme } from 'src/themes/theme'
import MediaQuery from 'react-responsive'

const Calendar = () => {
  const { calendarRef, month, calendarView, setCalendarView, goToNextMonth, goToPrevMonth, aspectRatio } =
    useCalendarAPI()

  return (
    <CalendarWrapper>
      <CustomToolbar
        month={month}
        setCalendarView={setCalendarView}
        goToNextMonth={goToNextMonth}
        goToPrevMonth={goToPrevMonth}
        calendarView={calendarView}
      />
      <MediaQuery minWidth={+theme.devices.tabletLandscape.slice(-7, -3)}>
        <CalendarDesktop
          dayGridPlugin={dayGridPlugin}
          timeGridPlugin={timeGridPlugin}
          ref={calendarRef}
          calendarView={calendarView}
          aspectRatio={aspectRatio}
          CALENDAR_VIEW={CALENDAR_VIEW}
        />
      </MediaQuery>
      <MediaQuery maxWidth={+theme.devices.tabletLandscape.slice(-7, -3)}>
        <CalendarMobile
          dayGridPlugin={dayGridPlugin}
          timeGridPlugin={timeGridPlugin}
          ref={calendarRef}
          calendarView={calendarView}
          aspectRatio={aspectRatio}
          CALENDAR_VIEW={CALENDAR_VIEW}
        />
      </MediaQuery>
    </CalendarWrapper>
  )
}

export default Calendar
