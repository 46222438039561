import styled, { css } from 'styled-components'

export const CalendarDesktopWrapper = styled.div`
  ${({ theme }) => css`
    // NOTE: Disabling table border
    .fc-theme-standard td,
    .fc-theme-standard th {
      border: 20px solid transparent;

      @media ${theme.devices.midBreakpoint} {
        border: 10px solid transparent;
      }
    }

    .fc-theme-standard .fc-scrollgrid {
      border: none;
    }

    // NOTE: aligning week days to left
    .fc th {
      text-align: left;
    }

    /* ///// Day cell wrapper ///// */ // NOTE: styling day table td
    .my-day-cell-classname {
      position: relative;

      &::after {
        content: '';
        height: 2.5px;
        width: 50px;
        background-color: #170738;
        position: absolute;
        bottom: 0;
        transition: width 0.2s;
      }

      &:hover {
        background-color: #f8f8f8;

        &::after {
          width: 100%;
        }

        // NOTE: styling date row when day cell is hovered
        .fc-daygrid-day-top {
          color: #39b5b5;
          padding-left: 10px;

          @media ${theme.devices.midBreakpoint} {
            padding-left: 6px;
          }
        }

        // NOTE: styling event wrapper when day cell is hovered
        .fc-daygrid-day-events {
          background-color: #f8f8f8 !important;

          &:after {
            width: 100% !important;
          }
        }

        // NOTE: preventing event from getting background when it's hovered
        .fc-daygrid-dot-event.fc-event-mirror,
        .fc-daygrid-dot-event:hover {
          background-color: transparent !important;
        }

        // NOTE: styling single event when day cell is hovered
        .fc-daygrid-event-harness {
          padding-left: 10px;

          @media ${theme.devices.midBreakpoint} {
            padding-left: 6px;
          }
        }

        // NOTE: styling more expander when day cell is hovered
        .more {
          left: 50%;
          opacity: 1;
        }

        // NOTE: styling current day when day cell is hovered
        &.fc-daygrid-day.fc-day-today .fc-daygrid-day-frame {
          background-color: #f8f8f8 !important;
        }
      }

      // NOTE: min-height of cell
      .fc-daygrid-day-frame.fc-scrollgrid-sync-inner {
        min-height: 9.4em;
      }
    }

    // NOTE: overwriting current day styles
    .fc .fc-daygrid-day.fc-day-today {
      background-color: transparent !important;
    }

    .fc-event {
      border: none;
      background: transparent;
    }

    // NOTE: overwriting disabled day styles (days which are not in current month)
    .fc .fc-cell-shaded,
    .fc .fc-day-disabled {
      background-color: transparent !important;
    }

    // NOTE: when cell is expanded
    .my-day-cell-classname .my-on-open {
      background-color: #f8f8f8 !important;

      // NOTE: styling date row when day cell is expanded
      .fc-daygrid-day-top {
        color: #39b5b5;
        padding-left: 10px;

        @media ${theme.devices.midBreakpoint} {
          padding-left: 6px;
        }
      }

      // NOTE: styling single event when day cell is expanded
      .fc-daygrid-event-harness {
        padding-left: 10px;

        @media ${theme.devices.midBreakpoint} {
          padding-left: 6px;
        }
      }

      // NOTE: styling more expander when day cell is expanded
      .more {
        left: 50%;
        opacity: 1;
      }
    }

    // NOTE: disabling margin to dot-event component
    .fc-event.fc-event-start.fc-event-end.fc-event-past.fc-daygrid-event.fc-daygrid-dot-event.my-event {
      margin: 0;

      &:hover {
        background-color: transparent;
      }
    }

    .fc .my-day-cell-classname {
      // NOTE: styling date row
      .fc-daygrid-day-top {
        flex-direction: row;
        transition: padding-left 0.2s;
        margin-bottom: 10px;
        margin-top: 10px;
      }
    }

    // NOTE: styling single event wrapper
    .fc-daygrid-event-harness {
      transition: padding-left 0.2s;
      margin-bottom: 30px;
    }

    // NOTE: styling date number element
    .fc .fc-daygrid-day-number {
      padding: 0 !important;
    }

    // NOTE: more expander style
    .more {
      position: absolute;
      top: 58px;
      left: 0;
      font-size: 12px;
      text-decoration: underline;
      z-index: 99;
      opacity: 0;
      transform: translateX(-50%);
      transition: left 0.2s, opacity 0.2s;

      &:hover {
        cursor: pointer;
      }
    }

    // NOTE: styling more when day cell is expanded
    .more.open {
      top: unset;
      bottom: 16px;
    }

    .fc .fc-daygrid-body-unbalanced .my-day-cell-classname .fc-daygrid-day-events {
      position: absolute;
      max-height: 82px;
      background: #ffffff;
      overflow: hidden;
      width: 100%;
      transition: max-height 0.2s;
      padding-bottom: 20px;
      z-index: 100;
    }

    .my-event-class {
      &::after {
        content: '';
        height: 2.5px;
        width: 50px;
        background-color: #170738;
        position: absolute;
        bottom: 0;
        transition: width 0.2s;
      }
    }

    .fc .fc-daygrid-body-unbalanced .my-day-cell-classname .my-on-open .fc-daygrid-day-events {
      overflow: hidden;
      max-height: 500px;
      z-index: 1000;
      background: #f8f8f8;
    }

    .my-on-open {
      .my-event-class {
        &::after {
          width: 100%;
        }
      }
    }
  `}
`
