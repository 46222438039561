import React, { useState } from 'react'
import Form from 'src/components/common/Form'
import Input from 'src/components/common/Input'
import Row from 'src/components/common/Row'
import Column from 'src/components/common/Column'
import Button from 'src/components/common/buttons/Button'
import styled, { css } from 'styled-components'
import RouterLink from 'src/components/common/RouterLink'
import { useFormHook, yup } from 'src/hooks/custom'
import { RestorePasswordPayload } from 'src/api/services/user'
import { useRestorePassword } from 'src/hooks/mutations/user'
import { colorPalette } from 'src/themes/theme'

type ConfirmMessageProps = {
  displayMessage: boolean
}

export const ConfirmMessage = styled.p<ConfirmMessageProps>`
  ${({ theme, displayMessage }) => css`
    font-size: ${theme.components.form.input.messages.fontSize};
    color: ${theme.components.form.input.messages.confirmMessage.color};
    font-weight: 500;
    margin-top: 26px;
    display: ${displayMessage ? 'block' : 'none'};
  `}
`

const ForgotPasswordSchema = yup.object().shape({
  email: yup.string().email('Enter a valid email').required('Email is required')
})

const ForgotPassword = () => {
  const [linkSent, setLinkSent] = useState(false)
  const { register, handleSubmit, errors } = useFormHook<RestorePasswordPayload>(ForgotPasswordSchema)

  const { mutate, isLoading } = useRestorePassword()

  const onSubmit = ({ email }: RestorePasswordPayload) => {
    mutate(
      { email, callBackUrl: process.env.REACT_APP_WEB_ACTIVATION_URL || '' },
      { onSuccess: () => setLinkSent(true) }
    )
  }

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      title="Forgot Password?"
      text="Enter your email address and we send you a link to reset your password."
      titleMarginBottom="0">
      <Input
        backgroundColor={colorPalette.additional[4]}
        type="email"
        name="email"
        label="Email"
        id="email"
        defaultValue=""
        register={register}
        error={errors.email?.message}
      />
      <ConfirmMessage displayMessage={linkSent}>Link was sent on your email</ConfirmMessage>
      <Row margin="24px 0 0">
        <Column>
          <Button disabled={linkSent || isLoading} buttonType="full" padding="9.5px 57px">
            Send
          </Button>
        </Column>
        <Column>
          <RouterLink to="/authorization">Back to Sign In</RouterLink>
        </Column>
      </Row>
    </Form>
  )
}

export default ForgotPassword
