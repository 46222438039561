import React, { TextareaHTMLAttributes } from 'react'
import styled, { css } from 'styled-components'

interface ITextAreaComponentProps {
  margin?: string
}

const TextAreaComponent = styled.div<ITextAreaComponentProps>`
  ${({ theme, margin }) => css`
    margin: ${margin};

    label {
      font-size: ${theme.components.form.input.label.fontSize};
      color: ${theme.components.form.input.label.color};
    }
  `}
`

interface ITextAreaElementWrapperProps {
  textAreaHeight?: string
}

const TextAreaElementWrapper = styled.div<ITextAreaElementWrapperProps>`
  ${({ theme, textAreaHeight }) => `
  margin-top: 10px;
  border: 1px solid ${theme.components.form.input.valid.borderColor};
  border-radius: ${theme.borderRadius.primary};

  textarea {
    border: none;
    resize: none;
    padding: 10px 13px;
    background-color: transparent;
    width: 100%;
    height: ${textAreaHeight};
    outline: none;
  }
`}
`

interface ITextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string
  id: string
  label: string
  margin?: string
  textAreaHeight?: string
  register: any
}

const TextArea = ({ name, id, label, margin, textAreaHeight, register }: ITextAreaProps) => {
  return (
    <TextAreaComponent margin={margin}>
      <label htmlFor={id}>{label}</label>
      <TextAreaElementWrapper textAreaHeight={textAreaHeight}>
        <textarea {...register(name)} name={name} id={id}></textarea>
      </TextAreaElementWrapper>
    </TextAreaComponent>
  )
}

export default TextArea
