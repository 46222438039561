import React, { useRef, useState } from 'react'
import styled, { keyframes } from 'styled-components'

import DefaultAvatar from 'src/assets/images/default-profile-picture.png'
import { useGetUser } from 'src/hooks/queries/user'
import Icon from 'src/components/icon/Icon'
import useUploadProfilePicture from 'src/hooks/mutations/user/useUploadProfilePicture'
import { toast } from 'react-hot-toast'
import useDeleteProfilePicture from 'src/hooks/mutations/user/useDeleteProfilePicture'

const spinner = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const LoadingSpinner = styled.div`
  width: 18px;
  height: 18px;
  border: 2px solid #00000050;
  border-top: 2px solid #000000;
  border-radius: 50%;
  animation: ${spinner} 1.5s linear infinite;
`

const Container = styled.div`
  position: relative;
  margin-bottom: 40px;
`

const ImageContainer = styled.div`
  position: relative;
`

const Image = styled.img`
  width: 134px;
  height: 134px;
`

const IconContainer = styled.div`
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #170738;
  border: 3px solid #fff;
  border-radius: 17px;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);

  &:hover {
    cursor: pointer;
  }
`

const EditContainer = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  background-color: #fff;
  width: 190px;
  height: 74px;
  bottom: -100px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  z-index: 11;
`

const EditBackground = styled.div`
  position: absolute;
  background-color: #00000099;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: 10;
`

const OptionContainer = styled.button<{ color: string }>`
  ${({ color }) => `
  display: flex;
  align-items: center;
  gap: 14px;
  background-color: transparent;
  border: none;

  p {
    color: ${color};
  }

  &:hover {
    cursor: pointer;
  }
  `}
`

const OptionText = styled.p`
  ${({ theme }) => `
    font-size: 12px;
    line-height: 18px;
    font-family: ${theme.fonts.family.primary};
  `}
`

const Avatar = () => {
  const [editing, setEditing] = useState(false)

  const inputRef = useRef<HTMLInputElement>(null)

  const { user, refetch } = useGetUser()

  const { mutate: uploadPicture, isLoading: isUploading } = useUploadProfilePicture()
  const { mutate: deletePicture, isLoading: isDeleting } = useDeleteProfilePicture()

  const handleImageSelect: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!e.target.files) {
      return
    }
    uploadPicture(
      { image: e.target.files[0] },
      {
        onSuccess: () => {
          refetch()
          toast.success('Image successfully uploaded')
          setEditing(false)
        },
        onError: () => {
          refetch()
          toast.error('Error while uploading image')
          setEditing(false)
        }
      }
    )
  }

  const handleDelete = () => {
    deletePicture(undefined, {
      onSuccess: () => {
        refetch()
        toast.success('Image successfully deleted')
        setEditing(false)
      },
      onError: () => {
        refetch()
        toast.error('Error while deleting image')
        setEditing(false)
      }
    })
  }

  return (
    <>
      {editing && <EditBackground onClick={() => setEditing(false)} />}
      <Container>
        <ImageContainer>
          {user?.imageUrl ? (
            <Image src={user.imageUrl} alt="Profile picture" />
          ) : (
            <Image src={DefaultAvatar} alt="Profile picture" />
          )}
          <IconContainer onClick={() => setEditing(true)}>
            <Icon iconName="iconPencil" size="13px" />
          </IconContainer>
        </ImageContainer>
        {editing && (
          <EditContainer>
            <OptionContainer color="#170738" onClick={() => inputRef.current?.click()} disabled={isUploading}>
              {!isUploading ? <Icon iconName="camera" /> : <LoadingSpinner />}
              <OptionText>Upload picture</OptionText>
              <input
                type="file"
                id="file"
                accept="image/png, image/gif, image/jpeg"
                ref={inputRef}
                onChange={handleImageSelect}
                style={{ display: 'none' }}
              />
            </OptionContainer>
            <OptionContainer color="#BC5252" onClick={handleDelete} disabled={isDeleting}>
              {!isDeleting ? <Icon iconName="iconRecycleBin" color="#BC5252" /> : <LoadingSpinner />}
              <OptionText>Remove picture</OptionText>
            </OptionContainer>
          </EditContainer>
        )}
      </Container>
    </>
  )
}

export default Avatar
