import React from 'react'
import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'
import Icon from '../icon/Icon'
import { useNotifications } from 'src/hooks/queries/notifications'

const NavigationItem = styled(NavLink)`
  ${({ theme }) => css`
    text-decoration: none;
    position: relative;

    &:link,
    &:visited {
      color: ${theme.layout.header.color};
      font-size: ${theme.layout.header.authorizedUserPanel.fontSize};
      font-weight; 400;
    }

    &:hover,
    &.active {
      cursor: pointer;
    }
  }
  `}
`

const TestNavigation = styled.a`
  color: ${({ theme }) => theme.layout.header.color};
  font-size: ${({ theme }) => theme.layout.header.authorizedUserPanel.fontSize};
  font-weight: 400;

  &:hover,
  &.active {
    cursor: pointer;
  }
`

const RedDot = styled.div`
  background-color: #ff1616;
  height: 8px;
  width: 8px;
  border-radius: 4px;
  position: absolute;
  top: 0px;
  right: 0px;
`

const NotificationBell = () => {
  const { data } = useNotifications({ page: 1, pageSize: 20 })

  const hasUnreadNotifications = data?.internalNotifications
    ? data.internalNotifications.filter(({ isRead }) => !isRead).length > 0
    : false

  return (
    <TestNavigation>
      <NavigationItem to="pages/notifications">
        <Icon iconName="notification" />
        {hasUnreadNotifications && <RedDot />}
      </NavigationItem>
    </TestNavigation>
  )
}

export default NotificationBell
