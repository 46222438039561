import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useLocation } from 'react-router'
import { IEvent } from 'src/api/services/events'
import Column from 'src/components/common/Column'
import Form from 'src/components/common/Form'
import Input from 'src/components/common/Input'
import Row from 'src/components/common/Row'
import Select, { IOptions } from 'src/components/common/Select'
import TextArea from 'src/components/common/TextArea'
import Button from 'src/components/common/buttons/Button'
import { useAuth } from 'src/context/authContext'
import { filterEventOwners } from 'src/helpers'
import { useEditEvent } from 'src/hooks/mutations/events'
import { useStacks } from 'src/hooks/queries/extended'
import { useGetStack } from 'src/hooks/queries/extended/useGetStack'
import { useGetUser } from 'src/hooks/queries/user'
import { getUserType } from 'src/pages/events/Calendar/utils'
import styled from 'styled-components'

const getSeniorityOptionsForUserType = (type: string) => {
  switch (type) {
    case 'EduMentor':
      return senOpts.slice(0, 1)
    case 'EduSquadLead':
    case 'EduTeamLead':
      return senOpts.slice(0, 2)
    default:
      return senOpts
  }
}

const transformEventToDefaultValues = (event: IEvent): IAddEventFormInputs => {
  const { comment, endDateUtc, link, name, users, startDateUtc } = event

  const values: IAddEventFormInputs = {
    comment,
    eventDate: moment(startDateUtc).format('YYYY-MM-DD'),
    eventStart: moment(startDateUtc).format('HH:mm'),
    eventEnd: moment(endDateUtc).format('HH:mm'),
    link,
    name,
    users: users.map((user) => ({ label: `${user.firstName} ${user.lastName}`, value: user.id })),
    seniority: { label: '', value: '' },
    stack: { label: '', value: '' }
  }

  return values
}

const ErrorText = styled.p`
  color: #f14f48;
  margin-top: 12px;
`

const Container = styled.div`
  background-color: white;
  z-index: 99999999;
`

const senOpts = [
  { label: 'Interns', value: 'eduInterns' },
  { label: 'Mentors', value: 'eduMentors' },
  { label: 'SquadLeads', value: 'eduSquadLead' },
  { label: 'TeamLeads', value: 'eduTeamLead' }
]

type IAddEventFormInputs = {
  name: string
  link: string
  comment: string
  eventDate: string
  eventStart: string
  eventEnd: string
  stack: IOptions
  users: IOptions[]
  seniority: IOptions
}

interface IAddEventFormProps {
  event: IEvent
}

const EditEventForm = ({ event }: IAddEventFormProps) => {
  const { userRole } = useAuth()
  const { user } = useGetUser()

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setValue
  } = useForm<IAddEventFormInputs>({
    defaultValues: transformEventToDefaultValues(event)
  })

  const isTeamOrSquadLead = userRole === 'EduTeamLead' || userRole === 'EduSquadLead'

  useEffect(() => {
    if (!isTeamOrSquadLead || !user) {
      return
    }

    const userType = getUserType(user.userType)

    const stack = user.eduIntern?.eduStack || user.eduMentor?.eduStack || user.eduTeamLead?.eduStack

    const seniority = senOpts.find((a) => a.value.includes(userType))

    if (!seniority || !stack) {
      return
    }

    setValue('stack', { label: stack?.name, value: stack.id })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTeamOrSquadLead, user])

  const { pathname } = useLocation()
  const { data: stackData } = useStacks({ page: 1, pageSize: 100 })

  useEffect(() => {
    if (!stackData) {
      return
    }
  }, [stackData])

  const { mutate: editEvent, isLoading, isError } = useEditEvent()

  const stack = watch('stack')
  const seniority = watch('seniority')

  useEffect(() => {
    if (!stack.value || !seniority.value) {
      return
    }

    setValue('users', userOpts)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stack, seniority])

  const { eduStack = [] } = useGetStack(stack?.value)

  const stackOpts = stackData?.eduStacks.map((stack) => {
    return { label: stack.name, value: stack.id }
  })

  const userOpts = filterEventOwners(eduStack, seniority)

  const path = pathname.split('/').pop()

  const onSubmit = (params: IAddEventFormInputs) => {
    const { name, link, comment, users, eventDate, eventStart, eventEnd } = params

    editEvent(
      {
        payload: {
          id: event.id,
          name,
          link,
          comment,
          userIds: users.map((user) => user.value),
          endDate: moment(`${eventDate}T${eventEnd}`).toISOString(),
          startDate: moment(`${eventDate}T${eventStart}`).toISOString()
        }
      },
      {
        onSuccess: () => {
          toast.success('Event successfully created', { style: { fontFamily: 'Poppins, sans-serif' }, duration: 3000 })
        }
      }
    )
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)} maxWidth="max-content">
        <Row alignItems="flex-start" flexDirection="column" gap="22px">
          <Column width={path === 'add-event' ? '296px' : '320px'}>
            <Input
              width="100%"
              id="name"
              register={register}
              type="text"
              name="name"
              label="Event Name"
              error={errors?.name?.message}
            />
          </Column>
          <>
            <Column width={path === 'add-event' ? '296px' : '208px'}>
              <Select
                name="seniority"
                options={getSeniorityOptionsForUserType(user?.userTypeText || '')}
                control={control}
                placeholder="Seniority"
                error={errors?.seniority?.message}
                isValid={!errors?.seniority?.message}
                label="Choose Seniority"></Select>
            </Column>
            <Row
              justify="flex-start"
              flexDirection={path === 'add-event' ? 'column' : 'row'}
              gap={path === 'add-event' ? '0' : '18px'}
              alignItems="flex-start">
              <Column width={path === 'add-event' ? '296px' : '208px'}>
                <Select
                  name="stack"
                  control={control}
                  placeholder="Stack"
                  options={stackOpts}
                  label="Choose Stack"
                  disabled={isTeamOrSquadLead}
                  error={errors?.stack?.message}
                  isValid={!errors?.stack?.message}
                />
              </Column>
              <Column width={path === 'add-event' ? '296px' : '208px'}>
                <Select
                  isMulti
                  name="users"
                  control={control}
                  placeholder="Users"
                  label="Choose Users"
                  error={errors?.users?.message}
                  isValid={!errors?.users?.message}
                  options={userOpts}></Select>
              </Column>
            </Row>
          </>
          <Column flex gap="18px" width={path === 'add-event' ? '296px' : '420px'} align="flex-start">
            <Input
              id="eventDate"
              borderColor="#F7F5FA"
              backgroundColor="#F7F5FA"
              register={register}
              type="date"
              name="eventDate"
              error={errors?.eventDate?.message}
              label="Event Date"
            />
            <Input
              id="eventStart"
              borderColor="#F7F5FA"
              backgroundColor="#F7F5FA"
              register={register}
              type="text"
              name="eventStart"
              error={errors?.eventStart?.message}
              label="Start"
            />
            <Input
              id="eventEnd"
              borderColor="#F7F5FA"
              backgroundColor="#F7F5FA"
              register={register}
              type="text"
              name="eventEnd"
              error={errors?.eventEnd?.message}
              label="End"
            />
          </Column>
          <Column width={path === 'add-event' ? '296px' : '320px'}>
            <Input width="100%" id="link" register={register} type="text" name="link" label="Event Link" />
          </Column>
          <Column width={path === 'add-event' ? '296px' : '500px'}>
            <TextArea
              id="comment"
              name="comment"
              label="Add details of meeting"
              textAreaHeight={path === 'add-event' ? '106px' : '170px'}
              register={register}
            />
          </Column>
        </Row>
        <>{isError && <ErrorText>Adding event failed</ErrorText>}</>
        <Row margin="32px 0 0 0" justify="start" gap="11px">
          <Column>
            <Button padding="9px 40px" buttonType="full" disabled={isLoading}>
              Update Event
            </Button>
          </Column>
        </Row>
      </Form>
    </Container>
  )
}

export default EditEventForm
