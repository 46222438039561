import { colorPalette } from 'src/themes/theme'
import styled, { css } from 'styled-components'

interface HeaderComponentProps {
  isLoggedIn: boolean
}

export const HeaderComponent = styled.header<HeaderComponentProps>`
  ${({ theme, isLoggedIn }) => css`
    background-color: ${isLoggedIn ? colorPalette.blue.primary[100] : 'transparent'};
    position: ${isLoggedIn ? 'relative' : 'absolute'};
    width: 100%;
    z-index: 1000;
    padding: 0 145px;
    height: 112px;
    display: flex;
    align-items: center;
    font-family: ${theme.fonts.family.primary};
    overflow: hidden;

    @media ${theme.devices.laptop} {
      padding: 0 80px;
    }

    @media ${theme.devices.tablet} {
      padding: 0 39px;
      top: 0px;
    }
  `}
`

export const LogoText = styled.p`
  font-size: 23px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.secondary};

  &:hover {
    cursor: pointer;
  }
`
