import React from 'react'
import Form from 'src/components/common/Form'
import Input from 'src/components/common/Input'
import { yup, useFormHook } from 'src/hooks/custom'
import Row from 'src/components/common/Row'
import Column from 'src/components/common/Column'
import { useAddMonth } from 'src/hooks/mutations/syllabus'
import Button from 'src/components/common/buttons/Button'

type IAddMonthFormInputs = {
  month: string
}

interface IAddMonthFormProps {
  setModalType?: (arg: string) => void
  setShowModal?: (arg: boolean) => void
}

const AddMonthSchema = yup.object().shape({
  month: yup.string().required()
})

const AddMonthForm = ({ setModalType, setShowModal }: IAddMonthFormProps) => {
  const { register, handleSubmit } = useFormHook<IAddMonthFormInputs>(AddMonthSchema)
  const { mutate, isLoading } = useAddMonth()

  const closeModal = () => {
    setModalType && setModalType('logoutModal')
    setShowModal && setShowModal(false)
  }

  const onSubmit = ({ month }: IAddMonthFormInputs) => {
    mutate({ name: month }, { onSuccess: () => closeModal() })
  }

  const handleCancel = () => {
    closeModal()
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Input label="Month" type="text" id="month" name="month" register={register} />

      <Row margin="32px 0 0" gap="11px">
        <Column>
          <Button buttonType="full" padding="9px 30px" disabled={isLoading}>
            Add Month
          </Button>
        </Column>
        <Column>
          <Button buttonType="full" padding="9px 40px" onClick={handleCancel}>
            Cancel
          </Button>
        </Column>
      </Row>
    </Form>
  )
}

export default AddMonthForm
