import { colorPalette, theme } from 'src/themes/theme'
import styled from 'styled-components'
import Button from '../common/buttons/Button'

export const SurveyWrapper = styled.div`
  padding: 142px;

  form {
    border: 1px solid ${colorPalette.grey.primary[20]};
    padding: 74px;

    @media ${theme.devices.mobile} {
      padding: 48px;
    }
  }

  @media ${theme.devices.mobile} {
    padding: 48px;
  }
`

export const QuestionHeader = styled.p`
  ${({ theme }) => `
    font-family: ${theme.fonts.family.secondary};
    font-weight: 400;
    font-size: 16px;
    line-height: 29px;
    padding-bottom: 30px;
    color: ${colorPalette.grey.primary[100]}
  `};
`

export const QuestionItem = styled.div`
  margin-bottom: 60px;

  textarea {
    padding: 8px;
    outline: none;
    margin-top: 24px;
    min-width: 450px;
    min-height: 84px;
    border-radius: 5px;
    border: 1px solid #f4f4f4;

    @media ${theme.devices.mobile} {
      min-width: 100%;
    }
  }
`

export const Answers = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
  margin-top: 28px;
`

export const SubmitButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const SubmitButton = styled(Button)`
  background-color: ${colorPalette.blue.primary[100]};
  border-color: ${colorPalette.blue.primary[100]};
`
