import { PluginDef } from '@fullcalendar/core'

export interface ICalendar {
  dayGridPlugin: PluginDef
  timeGridPlugin: PluginDef
  calendarView: string
  CALENDAR_VIEW: CalendarViewTypes[]
  aspectRatio: () => any
}

export type CalendarViewTypes = {
  view: string
  value: string
}

export const CALENDAR_VIEW: CalendarViewTypes[] = [
  { view: 'month', value: 'dayGridMonth' },
  { view: 'week', value: 'dayGridWeek' },
  { view: 'day', value: 'dayGridDay' }
]

export interface IDayProps {
  isToday: boolean
  hasEvent?: boolean
}
