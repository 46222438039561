import React from 'react'
import { HeroSection, HeroContent, HeroTitle, HeroText, CustomSwiperStyle } from './hero.styles'
import Section from 'src/components/common/Section'
import { Swiper } from 'swiper/react'
import { Pagination, Autoplay } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import ImageStart from 'src/assets/images/image-start.jpg'
import ImageFirst from 'src/assets/images/image-1.jpg'
import ImageSecond from 'src/assets/images/image-2.jpg'
import ImageThird from 'src/assets/images/image-3.jpg'
import ImageFourth from 'src/assets/images/image-4.jpg'

const Hero = () => {
  return (
    <HeroSection>
      <Swiper
        slidesPerView={1}
        loop={true}
        pagination={{ clickable: true }}
        modules={[Pagination, Autoplay]}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false
        }}
        className="mySwiper">
        <CustomSwiperStyle imageurl={ImageStart}>
          <Section>
            <HeroContent>
              <div>
                <HeroTitle>Through acceleration to progress</HeroTitle>
                <HeroText>Become Professional in Software Development and Digital Marketing</HeroText>
              </div>
            </HeroContent>
          </Section>
        </CustomSwiperStyle>
        <CustomSwiperStyle imageurl={ImageFirst}></CustomSwiperStyle>
        <CustomSwiperStyle imageurl={ImageSecond}></CustomSwiperStyle>
        <CustomSwiperStyle imageurl={ImageThird}></CustomSwiperStyle>
        <CustomSwiperStyle imageurl={ImageFourth}></CustomSwiperStyle>
      </Swiper>
    </HeroSection>
  )
}

export default Hero
