import React from 'react'
import { AuthorizationWrapper, AuthorizationSide, BackgroundSide } from './authorization.styles'
import imageStart from 'src/assets/images/image-start.jpg'
import Icon from 'src/components/icon/Icon'
import { Outlet, useNavigate } from 'react-router-dom'
import { colorPalette, theme } from 'src/themes/theme'

const Authorization = () => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate('/')
  }

  return (
    <AuthorizationWrapper>
      <AuthorizationSide>
        <Outlet />
      </AuthorizationSide>
      <BackgroundSide imageUrl={imageStart}>
        <Icon iconName="iconClose" onClick={handleClick} color={colorPalette.pink.primary[50]} />
      </BackgroundSide>
    </AuthorizationWrapper>
  )
}

export default Authorization
