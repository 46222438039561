import { axiosInstanceEdu } from 'src/components/interceptor'
import { IUser } from './user'

export enum Seniority {
  Junior = 1,
  Middle = 2,
  Senior = 3
}
export interface StackResponse {
  eduStack: {
    description: string
    eduInterns: IUser[]
    eduMentors: IUser[]
    eduSquadLead: any
    eduTeamLead: any
  }
}

export interface PageProps {
  page?: number
  pageSize?: number
}

export interface EduTeamLead {
  id: string
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  dateOfBirthUtc: string
  idNumber: string
  userId: string
}

export interface EduMentor {
  id: string
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  dateOfBirthUtc: string
  idNumber: string
  userId: string
  eduMentorSeniority: Seniority
}

export interface EduIntern {
  id: string
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  dateOfBirthUtc: string
  idNumber: string
  userId: string
}

export interface EduSquadLead {
  id: string
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  dateOfBirthUtc: string
  idNumber: string
  userId: string
  eduSquadLeadSeniority: Seniority
}

export interface IStack {
  id: string
  name: string
  description: string
  eduTeamLead: EduTeamLead
  eduSquadLead: EduSquadLead
  eduInterns: EduIntern[]
  eduMentors: EduMentor[]
}
interface StacksResponse {
  eduStacks: IStack[]
  page: number
  pageSize: number
  total: number
}

export const getStack = async (id: string) => {
  const { data } = await axiosInstanceEdu.get<{ eduStack: IStack }>(`api/v1/EduStack/${id}`)

  return data
}

export const getStacks = async ({ page, pageSize }: PageProps) => {
  const { data } = await axiosInstanceEdu.get<StacksResponse>(`api/v1/EduStack?page=${page}&pageSize=${pageSize}`)

  return data
}
