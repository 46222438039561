import styled, { css } from 'styled-components'

export const Month = styled.p`
  ${({ theme }) => css`
    text-transform: uppercase;
    font-weight: 600;
    font-size: 32px;

    @media ${theme.devices.tabletLandscape} {
      font-size: 24px;
    }

    @media ${theme.devices.mobile} {
      font-size: 16px;
    }
  `}
`

interface ToolbarViewButtonComponent {
  isActive: boolean
}

export const ToolbarViewButtonComponent = styled.button<ToolbarViewButtonComponent>`
  ${({ theme, isActive }) => css`
    text-transform: uppercase;
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 22px;
    font-weight: 500;
    color: ${isActive ? '#000000' : '#00000080'};

    &:not(:last-child) {
      padding-right: 22px;
      border-right: solid 4px #39b5b5;
    }

    &:not(:first-child) {
      padding-left: 22px;
    }

    &:hover {
      color: #000000;
      cursor: pointer;
    }

    @media ${theme.devices.mobile} {
      font-size: 14px;

      &:not(:last-child) {
        border-width: 2px;
      }
    }
  `}
`
