import React from 'react'
import styled from 'styled-components'

interface WeekTabsProps {
  items: number[]
  activeWeek: number
  onChange: (week: number) => void
}

const Wrapper = styled.div`
  display: flex;
  gap: 28px;
  margin-bottom: 64px;
`

const WeekTabItem = styled.button<{ active: boolean }>`
  background-color: ${(props) => (props.active ? '#FB60A5' : 'transparent')};
  color: ${(props) => (props.active ? '#fff' : '#55555F')};
  padding: 4px 14px;
  border-radius: 8px;
  outline: none;
  border: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
`
const WeekTabs = ({ items, onChange, activeWeek }: WeekTabsProps) => {
  const handleWeekChange = (week: number) => {
    onChange(week)
  }
  return (
    <Wrapper>
      {items.map((item, index) => (
        <WeekTabItem key={index} active={index + 1 === activeWeek} onClick={() => handleWeekChange(index + 1)}>
          Week {item}
        </WeekTabItem>
      ))}
    </Wrapper>
  )
}

export default WeekTabs
