import React from 'react'
import { useGetStackSyllabus } from '../../hooks/queries/syllabus'
import { useParams } from 'react-router'
import WeekTabs from './WeekTabs'
import SyllabusContent from './SyllabusContent'
const weeksArr = [1, 2, 3, 4]

const DesignAndTimingPage = () => {
  const { stackId = '', monthId = '' } = useParams<{ stackId: string; monthId: string }>()
  const { id } = useGetStackSyllabus(stackId)
  const [activeWeek, setActiveWeek] = React.useState(1)

  const handleWeekChange = (week: number) => {
    setActiveWeek(week)
  }

  return (
    <div>
      <WeekTabs items={weeksArr} onChange={handleWeekChange} activeWeek={activeWeek} />
      {id && <SyllabusContent monthId={monthId} syllabusId={id} activeWeek={activeWeek} />}
    </div>
  )
}

export default DesignAndTimingPage
