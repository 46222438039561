import React from 'react'
import styled from 'styled-components'

type TextProps = {
  children: string
  fontSize?: string
  color?: string
  bold?: boolean
}

const StyledText = styled.p<TextProps>`
  font-size: ${(props) => props.fontSize || '16px'};
  color: ${(props) => props.color || '#595959'};
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
`
const Text = ({ children, fontSize, color, bold }: TextProps) => {
  return (
    <StyledText fontSize={fontSize} color={color} bold={bold}>
      {children}
    </StyledText>
  )
}

export default Text
