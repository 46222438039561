import moment from 'moment'
import React, { FC, useCallback, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { ISurveyConfiguration, SurveyParticipantSubmissionStatus, SurveyType } from 'src/api/services/survey'
import AccordionGenericBody from 'src/components/common/Accordion/AccordionGenericBody'
import AccordionGenericHeader from 'src/components/common/Accordion/AccordionGenericHeader'
import AccordionGenericItem from 'src/components/common/Accordion/AccordionGenericItem'
import Button from 'src/components/common/buttons/Button'
import Icon from 'src/components/icon/Icon'
import { colorPalette } from 'src/themes/theme'
import styled from 'styled-components'

const Container = styled.div`
  padding: 8px 0px;
`

const AccordionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;
`

const ExamTitle = styled.p`
  font-size: 25px;
  line-height: 30px;
  font-weight: 700;
  text-transform: uppercase;
`

const IconContainer = styled.div<{ isOpen: boolean }>`
  ${({ isOpen }) => `
    transform: rotate(${isOpen ? '180deg' : '0deg'});
    transition: transform 0.3s;
  `}
`

const ExamButtonContainer = styled.div`
  margin-top: 32px;
`

const ExamButton = styled(Button)`
  background-color: ${colorPalette.pink.primary[60]};
  color: ${colorPalette.neutral[1]};
  border: 0px;
  font-size: 14px;
  line-height: 28px;
`

const FinishedExamContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 22px;
`

const FinishedExamScore = styled.p`
  background-color: ${colorPalette.blue.primary[100]};
  color: ${colorPalette.neutral[1]};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 10px 22px;
  border-radius: 4px;
`

const FinishedExamButton = styled(Button)`
  background-color: ${colorPalette.grey.primary[50]};
  color: ${colorPalette.neutral[1]};
  border: 0px;
  font-size: 14px;
  line-height: 28px;
  border-radius: 4px;
  text-transform: uppercase;
`

interface SurveyPreviewProps {
  config: ISurveyConfiguration
}

const SurveyPreview: FC<SurveyPreviewProps> = ({ config }) => {
  const [isOpen, setIsOpen] = useState(moment(config.availabilityDate).isSameOrBefore())

  const examAvailable = useMemo(() => {
    return moment(config.availabilityDate).isSameOrBefore()
  }, [config.availabilityDate])

  const examExpired = useMemo(() => {
    return moment(config.expireDate).isBefore()
  }, [config.expireDate])

  const handleClick = () => {
    setIsOpen((prev) => !prev)
  }

  const renderExamState = useCallback(() => {
    if (!examAvailable) {
      return (
        <p>
          {config.surveyType === SurveyType.Exam ? 'Exam' : 'Survey'} can be started at{' '}
          {moment(config.availabilityDate).format('DD-MM-YYYY HH:mm')}
        </p>
      )
    }

    if (examAvailable && !examExpired) {
      if (config.surveySubmission) {
        switch (config.surveySubmission.status) {
          case SurveyParticipantSubmissionStatus.Started:
            return (
              <Link to={`/pages/survey/${config.id}`}>
                <ExamButton buttonType={'full'} padding={'8px 40px'}>
                  Continue
                </ExamButton>
              </Link>
            )
          case SurveyParticipantSubmissionStatus.Completed:
          case SurveyParticipantSubmissionStatus.Finished:
            return (
              <FinishedExamContainer>
                <Link to={`/pages/survey/${config.id}`}>
                  <FinishedExamButton buttonType={'full'} padding={'8px 40px'}>
                    Finished
                  </FinishedExamButton>
                </Link>
                {config.surveyType === SurveyType.Exam && (
                  <FinishedExamScore>
                    {config.surveySubmission.totalScore} / {config.totalScore}
                  </FinishedExamScore>
                )}
              </FinishedExamContainer>
            )
        }
      }
      return (
        <Link to={`/pages/survey/${config.id}`}>
          <ExamButton buttonType={'full'} padding={'8px 40px'}>
            Start
          </ExamButton>
        </Link>
      )
    }

    if (examExpired && config.surveySubmission) {
      switch (config.surveySubmission.status) {
        case SurveyParticipantSubmissionStatus.Completed:
        case SurveyParticipantSubmissionStatus.Finished:
          return (
            <FinishedExamContainer>
              <Link to={`/pages/survey/${config.id}`}>
                <FinishedExamButton buttonType={'full'} padding={'8px 40px'}>
                  Finished
                </FinishedExamButton>
              </Link>
              {config.surveyType === SurveyType.Exam && (
                <FinishedExamScore>
                  {config.surveySubmission.totalScore} / {config.totalScore}
                </FinishedExamScore>
              )}
            </FinishedExamContainer>
          )
        default:
          return (
            <FinishedExamContainer>
              <FinishedExamButton buttonType={'full'} padding={'8px 40px'}>
                Finished
              </FinishedExamButton>
            </FinishedExamContainer>
          )
      }
    }

    return <p>{config.surveyType === SurveyType.Exam ? 'Exam' : 'Survey'} has finished</p>
  }, [config, examAvailable, examExpired])

  return (
    <Container>
      <AccordionGenericItem>
        <AccordionGenericHeader onClick={handleClick}>
          <AccordionHeader>
            <ExamTitle>{config.title}</ExamTitle>
            <IconContainer isOpen={isOpen}>
              <Icon iconName="chevron" color="black" />
            </IconContainer>
          </AccordionHeader>
        </AccordionGenericHeader>
        <AccordionGenericBody isOpen={isOpen} noWrapper>
          <p>
            This is a {config.title} {config.surveyType === SurveyType.Exam ? 'exam' : 'survey'}
          </p>
          <ExamButtonContainer>{renderExamState()}</ExamButtonContainer>
        </AccordionGenericBody>
      </AccordionGenericItem>
    </Container>
  )
}

export default SurveyPreview
