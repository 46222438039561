import { useMutation, useQueryClient } from 'react-query'
import { readNotification } from 'src/api/services/notifications'
import { NOTIFICATIONS_QUERY_KEY } from './config'

const useReadNotification = () => {
  const client = useQueryClient()

  return useMutation({
    mutationFn: (notificationId: string) => readNotification(notificationId),
    onSettled: () => {
      client.invalidateQueries([NOTIFICATIONS_QUERY_KEY])
    }
  })
}

export default useReadNotification
