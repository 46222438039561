import React from 'react'
import { colorPalette } from 'src/themes/theme'
import styled, { css } from 'styled-components'

const BenefitsListItemComp = styled.li`
  ${({ theme }) => css`
    padding: 48px 28px;
    background-color: ${theme.pages.landingPage.benefitsSection.benefitsListItem.backgroundColor};
    border-radius: ${theme.borderRadius.primary};

    p {
      font-size: ${theme.pages.landingPage.benefitsSection.benefitsListItem.title.size.laptop};
      font-weight: 600;
      margin-bottom: 25px;
      color: ${colorPalette.blue.primary[100]};

      @media ${theme.devices.mobile} {
        font-size: ${theme.pages.landingPage.benefitsSection.benefitsListItem.title.size.mobile};
      }
    }

    ul {
      list-style: disc;
      display: flex;
      flex-direction: column;
      gap: 24px;
      margin-left: 18px;

      li {
        color: ${theme.pages.landingPage.benefitsSection.benefitsListItem.color};
        font-size: ${theme.pages.landingPage.benefitsSection.benefitsListItem.text.size.laptop};
        font-weight: 400;

        @media ${theme.devices.mobile} {
          font-size: ${theme.pages.landingPage.benefitsSection.benefitsListItem.text.size.mobile};
        }
      }
    }
  `}
`

interface BenefitsListItemProps {
  title: string
  list: Array<string>
}

const BenefitsListItem = ({ title, list }: BenefitsListItemProps) => {
  return (
    <BenefitsListItemComp>
      <p>{title}</p>
      <ul>
        {list.map((listItem, id) => {
          return <li key={id}>{listItem}</li>
        })}
      </ul>
    </BenefitsListItemComp>
  )
}

export default BenefitsListItem
