import moment from 'moment'

export const timeFormatter = (date: string) => {
  return new Date(date).toLocaleTimeString('ge-AZ', {
    hour: '2-digit',
    minute: '2-digit'
  })
}

export const getUserType = (userType: number) => {
  switch (userType) {
    case 1:
      return 'SuperAdmin'
    case 2:
      return 'EduAdmin'
    case 4:
      return 'SkillWillAdmin'
    case 8:
      return 'eduIntern'
    case 16:
      return 'eduMentor'
    case 32:
      return 'eduTeamLead'
    case 64:
    default:
      return 'other'
  }
}

export const isImage = (extension: string) => {
  return extension.match(/\.(jpg|jpeg|png|gif)$/i)
}

export const getDateEvents = (
  date: Date,
  events: {
    id: string
    link: string
    title: string
    description: string
    end: string
    start: string
  }[]
) => {
  return events.filter((e) => moment(date).isSame(e.start, 'day'))
}

export const dateFormatter: (dateArg: Date | string) => string = (dateArg: Date | string) => {
  const date = typeof dateArg === 'string' ? new Date(dateArg) : dateArg
  const day = date.getDate()
  const month = date.getUTCMonth() + 1
  const year = date.getUTCFullYear()

  return day + '/' + month + '/' + year
}

export const injectElementInDOM = () => {
  const tdNodes = document.querySelectorAll('.fc-daygrid-day-events')
  tdNodes.forEach((node) => {
    if (node.querySelectorAll('.fc-daygrid-event-harness').length > 0) {
      node.classList.add('my-event-class')
    }
    if (node.querySelectorAll('.fc-daygrid-event-harness').length > 1) {
      const span = document.createElement('span')
      span.textContent = 'More'
      span.classList.add('more')
      node.appendChild(span)
    }
  })
  const moreButtonNodes = document.querySelectorAll('.more')
  moreButtonNodes.forEach((node) => {
    node.addEventListener('click', () => {
      node.closest('.fc-daygrid-day-frame')?.classList.toggle('my-on-open')
      node.classList.toggle('open')
      if (node.classList.contains('open')) {
        node.textContent = 'less'
      } else {
        node.textContent = 'more'
      }
    })
  })
}
