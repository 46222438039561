import React from 'react'
import styled, { css } from 'styled-components'

interface ISectionTextComponentProps {
  fontWeight: string
  maxWidth?: string
  fontFamily?: string
  color?: string
}

interface ISectionText extends ISectionTextComponentProps {
  children: string
}

const SectionTextComponent = styled.p<ISectionTextComponentProps>`
  ${({ theme, fontWeight, maxWidth, fontFamily = theme.fonts.family.primary, color }) => css`
    font-family: ${fontFamily};
    font-size: ${theme.layout.section.text.size.laptop};
    font-weight: ${fontWeight};
    color: ${color};
    line-height: 24px;
    ${maxWidth &&
    css`
      max-width: ${maxWidth};
    `}
    @media ${theme.devices.mobile} {
      font-size: ${theme.layout.section.text.size.mobile};
      font-weight: ${fontFamily !== theme.fonts.family.secondary && 400};
    }
  `}
`

const SectionText = ({ children, fontWeight, maxWidth, fontFamily, color }: ISectionText) => {
  return (
    <SectionTextComponent fontWeight={fontWeight} maxWidth={maxWidth} fontFamily={fontFamily} color={color}>
      {children}
    </SectionTextComponent>
  )
}

export default SectionText
