import React, { createContext, useContext, useState } from 'react'
import { useQueryString } from 'src/hooks/custom'
import { MonthType } from 'src/api/services/syllabus'

export interface ISyllabusContextProps {
  syllabusData: SubjectType[]
  setSyllabusData: (subjectArray: SubjectType[]) => void
  handleSyllabusMaterialAdd: (week: number, title: string) => void
  activeMonth: string
  activeSubjectId: string
  setActiveSubjectId: (id: string) => void
  setActiveMonth: (id: string) => void
}

export type SubjectType = {
  id: string
  eduSyllabusId: string
  month: string | MonthType
  name: string
  description: string
  week: number
}

const SyllabusContext = createContext<ISyllabusContextProps | null>(null)

interface Props {
  children: React.ReactNode
}

const SyllabusProvider = ({ children }: Props) => {
  const [syllabusData, setSyllabusData] = useState<SubjectType[]>([])
  const [activeSubjectId, setActiveSubjectId] = useState<string>('')
  const [activeMonth, setActiveMonth] = useState<string>(localStorage.getItem('activeMonth') || '')

  const { parseQuery } = useQueryString()
  const { id = '' } = parseQuery()

  const handleSyllabusMaterialAdd = (weekArg: number, title: string) => {
    setSyllabusData((prevSyllabus) => {
      return [
        ...prevSyllabus,
        {
          id: Math.random().toString(),
          name: title,
          month: activeMonth,
          week: weekArg,
          eduSyllabusId: String(id),
          description: ''
        }
      ]
    })
  }

  const value = {
    syllabusData,
    setSyllabusData,
    handleSyllabusMaterialAdd,
    activeMonth,
    activeSubjectId,
    setActiveSubjectId,
    setActiveMonth
  }

  return <SyllabusContext.Provider value={value}>{children}</SyllabusContext.Provider>
}

export default SyllabusProvider

export const useSyllabus = () => {
  const value = useContext(SyllabusContext)

  if (!value) {
    throw new Error('Syllabus Context Provider is not defined')
  }
  return value
}
