import { useQuery } from 'react-query'
import { getCourseOverview } from '../../../api/services/courseOverview'

export const COURSE_OVERVIEW = 'COUSE_OVERVIEW'

const useGetCourseOverview = (eduStackId: string) => {
  return useQuery({
    queryKey: [COURSE_OVERVIEW, eduStackId],
    queryFn: () => getCourseOverview(eduStackId),
    enabled: Boolean(eduStackId)
  })
}

export default useGetCourseOverview
