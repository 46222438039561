import React, { InputHTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import Icon from 'src/components/icon/Icon'
import { colorPalette } from 'src/themes/theme'

interface InputComponentProps {
  margin?: string
}

const InputComponent = styled.div<InputComponentProps>`
  ${({ theme, margin }) => css`
    margin: ${margin};

    label {
      font-size: ${theme.components.form.input.label.fontSize};
      color: ${theme.components.form.input.label.color};
    }
  `}
`
interface ErrorMessageElementProps {
  inputValid: boolean
}

interface InputElementWrapperProps extends ErrorMessageElementProps {
  type: string
  backgroundColor?: string
  borderColor?: string
}

const InputElementWrapper = styled.div<InputElementWrapperProps>`
  ${({ theme, inputValid, type, backgroundColor, borderColor, color }) => css`
    display: flex;
    border: ${borderColor
      ? `1px solid ${borderColor}`
      : `1px solid
      ${inputValid ? theme.components.form.input.valid.borderColor : theme.components.form.input.invalid.borderColor}`};
    border-radius: ${theme.borderRadius.primary};
    align-items: center;
    padding-right: ${type === 'password' ? '14px' : '0px'};
    margin-top: 10px;

    input {
      width: 100%;
      padding: 10px 13px 10px 17px;
      outline: none;
      border: none;
      color: ${inputValid
        ? color
          ? color
          : theme.components.form.input.valid.color
        : theme.components.form.input.invalid.color};
      background: ${backgroundColor ? backgroundColor : 'transparent'};
    }

    // prevents from appling user agent styles on auto-fill
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus {
      -webkit-text-fill-color: ${inputValid
        ? theme.components.form.input.valid.color
        : theme.components.form.input.invalid.color};
      transition: background-color 600000s 0s, color 600000s 0s;
    }

    div {
      cursor: pointer;
    }
  `}
`

export const ErrorMessage = styled.div<ErrorMessageElementProps>`
  ${({ theme, inputValid }) => css`
    display: ${!inputValid ? 'flex' : 'none'};
    gap: 8px;
    align-items: center;
    margin-top: 12px;

    span {
      font-size: ${theme.components.form.input.messages.fontSize};
      color: ${theme.components.form.input.messages.errorMessage.color};
    }
  `}
`

export const Label = styled.label`
  color: ${colorPalette.grey.primary[100]};
`

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string
  type: string
  name: string
  id: string
  register: any
  error?: string
  margin?: string
  backgroundColor?: string
  borderColor?: string
  disabled?: boolean
}

const Input = ({
  label,
  id,
  type,
  name,
  register,
  error,
  margin,
  backgroundColor,
  borderColor,
  disabled,
  color
}: InputProps) => {
  const [passwordVisible, setPasswordVisible] = React.useState(false)
  const isInputValid = error ? false : true

  const handleClick = () => {
    setPasswordVisible((prev) => !prev)
  }

  return (
    <InputComponent margin={margin}>
      <Label htmlFor={id}>{label}</Label>
      <InputElementWrapper
        inputValid={isInputValid}
        type={type}
        backgroundColor={backgroundColor}
        color={color}
        borderColor={borderColor}>
        <input
          {...register(name)}
          type={type === 'password' ? (passwordVisible === true ? 'text' : type) : type}
          id={id}
          name={name}
          disabled={disabled}
        />
        {type === 'password' && (
          <Icon iconName={passwordVisible === true ? 'iconEyeSlash' : 'iconEye'} onClick={handleClick} />
        )}
      </InputElementWrapper>
      {!isInputValid && (
        <ErrorMessage inputValid={isInputValid}>
          <Icon iconName="iconWarning" />
          <span>{error}</span>
        </ErrorMessage>
      )}
    </InputComponent>
  )
}

export default Input
