import { AxiosError } from 'axios'
import { QUERY_KEYS } from 'src/enums'
import { useMutation, useQueryClient } from 'react-query'
import { deleteSyllabus } from 'src/api/services/syllabus'

export const useDeleteSyllabus = () => {
  const client = useQueryClient()

  const { mutate, isLoading, isError } = useMutation<void, AxiosError, string>(deleteSyllabus, {
    onSuccess: () => client.invalidateQueries([QUERY_KEYS.ALL_SYLLABUS])
  })

  return { mutate, isLoading, isError }
}
