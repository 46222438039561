import React from 'react'
import { useNavigate } from 'react-router-dom'
import Column from 'src/components/common/Column'
import Row from 'src/components/common/Row'
import Icon, { IconTypes } from 'src/components/icon/Icon'
import { useBurgerMenu } from 'src/context/burgerMenuContext'
import { useModal } from 'src/context/modalContext'
import styled, { css } from 'styled-components'

import Logo from '../logo.svg'
import { useGetUser } from '../../hooks/queries/user'
import { useAuth } from '../../context/authContext'

const footerIcons: IconTypes[] = ['linkedin', 'facebook', 'instagram']

interface MobileNavigationCompProps {
  menuState: boolean
}

const MobileNavigationComp = styled.div<MobileNavigationCompProps>`
  ${({ menuState, theme }) => css`
    font-family: ${theme.fonts.family.primary};
    position: fixed;
    left: ${menuState ? '0' : '100%'};
    width: 100%;
    height: 100vh;
    background-color: ${theme.colors.secondary};
    z-index: 9999;
    color: black;
    padding: 0 38px 32px;
    transition: left 1s;
  `}
`

const MenuContainer = styled.div`
  margin-top: 58px;
  height: calc(100% - 58px);
`

const IconContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: 28px;

  div:hover {
    cursor: pointer;
  }
`

const NavigationItem = styled.a.attrs({
  href: '#'
})`
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.primary};
`

const Separator = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.primary};
`

const PrivacyPolicy = styled.a.attrs({
  href: '#'
})`
  ${({ theme }) => css`
    font-family: ${theme.fonts.family.secondary};
    color: ${({ theme }) => theme.colors.primary};
    font-size: ${theme.layout.footer.privacyAndCopyright.size};
    font-weight: 400;
    margin-right: 20px;
  `}
`

const Copyright = styled.span`
  ${({ theme }) => css`
    font-family: ${theme.fonts.family.secondary};
    color: ${({ theme }) => theme.colors.primary};
    font-size: ${theme.layout.footer.privacyAndCopyright.size};
    font-weight: 400;
    text-align: end;
  `}
`

const LogoText = styled.p`
  font-size: 23px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.primary};
`

const MobileNavigation = () => {
  const { isOpen, setIsOpen } = useBurgerMenu()
  const { setShowModal, setModalType } = useModal()
  const navigate = useNavigate()
  const { isIntern } = useAuth()
  const { user } = useGetUser()
  const stackId = user?.eduIntern?.eduStack.id || ''

  const handleClick = () => {
    setIsOpen(false)
  }

  const handleNavigation = (path: string): void => {
    navigate(path)
    setIsOpen(false)
  }

  const handleOpenEventDetailsModal = () => {
    setModalType('eventDetailsModal')
    setShowModal(true)
  }

  return (
    <MobileNavigationComp menuState={isOpen}>
      <MenuContainer>
        <Row flexDirection="column" height="100%" gap="50px">
          <Column width="100%">
            <IconContainer>
              <Icon iconName="iconClose" onClick={handleClick} />
            </IconContainer>
            <Row flexDirection="column" alignItems="start" gap="32px">
              {isIntern ? (
                <Column>
                  <NavigationItem onClick={() => handleNavigation(`pages/overview/${stackId}`)}>
                    Syllabus
                  </NavigationItem>
                </Column>
              ) : (
                <Column>
                  <NavigationItem onClick={() => handleNavigation('pages/syllabus')}>Syllabus</NavigationItem>
                </Column>
              )}
              <Column>
                <NavigationItem onClick={() => handleNavigation('pages/events')}>Events</NavigationItem>
              </Column>
              <Column>
                <NavigationItem onClick={() => handleNavigation('pages/questionnaries-and-exams')}>
                  Exams
                </NavigationItem>
              </Column>
              <Column>
                <NavigationItem onClick={() => handleNavigation('pages/surveys')}>Surveys</NavigationItem>
              </Column>
              <Column width="246px">
                <Separator></Separator>
              </Column>
              <Column>
                <NavigationItem onClick={() => handleNavigation('pages/profile')}>My Profile</NavigationItem>
              </Column>
              <Column>
                <NavigationItem onClick={() => handleNavigation('pages/profile/change-password')}>
                  Change Password
                </NavigationItem>
              </Column>
              <Column>
                <NavigationItem onClick={() => setShowModal(true)}>Log out</NavigationItem>
              </Column>
            </Row>
          </Column>
          <Column width="100%">
            <Row gap="9px" flexDirection="column-reverse">
              <Column flex justify="space-between" width="100%">
                <Column>
                  <PrivacyPolicy>Privacy Policy</PrivacyPolicy>
                </Column>
                <Column textAlign="end">
                  <Copyright>Copyright © 2021 Sweeft Digital Agency</Copyright>
                </Column>
              </Column>
              <Column flex justify="space-between" width="100%">
                <Column>
                  <img
                    src={Logo}
                    onClick={() => handleNavigation(isIntern ? '/pages/analytics' : '/')}
                    style={{ width: 150, height: '100%' }}
                  />
                </Column>
                <Column flex gap="10px">
                  {footerIcons.map((icon, id) => {
                    return (
                      <a href="#" key={id}>
                        <Icon iconName={icon} color="#170738" />
                      </a>
                    )
                  })}
                </Column>
              </Column>
            </Row>
          </Column>
        </Row>
      </MenuContainer>
    </MobileNavigationComp>
  )
}

export default MobileNavigation
