import { Editor } from '@tinymce/tinymce-react'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useParams } from 'react-router'
import Column from 'src/components/common/Column'
import Input from 'src/components/common/Input'
import Row from 'src/components/common/Row'
import Button from 'src/components/common/buttons/Button'
import { useSyllabus } from 'src/context/syllabusContext'
import { useEditSubject } from 'src/hooks/mutations/syllabus'
import { useGetSyllabus } from 'src/hooks/queries/syllabus'
import { theme } from 'src/themes/theme'
import styled from 'styled-components'

const SyllabusAccordionBodyWrapper = styled.div`
  div {
    // Accordion Title
    h1 {
      font-size: ${theme.components.accordion.body.title.fontSize.laptop};
      font-weight: 700;
      color: ${theme.components.accordion.body.title.color};
      margin-bottom: 40px;

      @media ${theme.devices.mobileLandscape} {
        font-size: ${theme.components.accordion.body.title.fontSize.mobile};
        margin-bottom: 18px;
      }
    }

    p {
      font-family: ${theme.fonts.family.secondary};
      font-size: ${theme.components.accordion.body.text.fontSize.laptop};
      font-weight: 400;
      color: ${theme.components.accordion.body.text.color};
      margin-bottom: 24px;
      line-height: 28px;

      @media ${theme.devices.mobileLandscape} {
        font-size: ${theme.components.accordion.body.text.fontSize.mobile};
        font-weight: 600;
        margin-bottom: 30px;
        line-height: 18px;
      }
    }
  }
`

const EditorWrapper = styled.div`
  height: 562px;
`

interface ISyllabusAccordionBodyProps {
  admin: boolean
  content: string
  id: string
  week: string
  onComplete: () => void
}

const SyllabusAccordionBody = ({ admin, content, id, week, onComplete }: ISyllabusAccordionBodyProps) => {
  const [description, setDescription] = useState(content)

  useEffect(() => {
    console.log('here', content)
    setDescription(content)
  }, [content])

  const { activeSubjectId } = useSyllabus()

  const { id: syllId = '' } = useParams()

  const { mutate: editSubject, isLoading } = useEditSubject()

  const { data } = useGetSyllabus(String(syllId))
  const subjects = data?.eduSyllabus.eduSubjects
  const subjectToEdit = subjects?.find((subj) => subj.id === activeSubjectId)

  const { register, reset, getValues, setValue } = useForm({ defaultValues: { title: subjectToEdit?.name } })

  useEffect(() => {
    reset({ title: subjectToEdit?.name })
  }, [reset, subjectToEdit?.name])

  const setContent = () => ({ __html: content })

  const saveSubjectHandler = () => {
    if (subjectToEdit) {
      editSubject(
        {
          id: activeSubjectId,
          eduSyllabusId: syllId,
          name: getValues('title'),
          week: subjectToEdit?.week,
          monthId: subjectToEdit?.eduMonth.id,
          description
        },
        {
          onSuccess: () => {
            toast.success('Subject successfully updated', {
              style: { fontFamily: 'Poppins, sans-serif' },
              duration: 3000
            })
          }
        }
      )
    }
  }

  const handleDiscard = () => {
    setDescription(content)
    setValue('title', subjectToEdit?.name)
  }

  const adminContent = (
    <>
      <Column width="320px">
        <Input
          margin="0 0 24px"
          width="100%"
          id="title"
          type="title"
          name="title"
          register={register}
          disabled={!subjectToEdit}
          label="Subject Title"
        />
      </Column>
      <EditorWrapper>
        <Editor
          apiKey="rzlhy3ngrilr0am9s4m2odpact4vuo6qrf78vvlz2ftlvm06"
          cloudChannel="6-dev"
          value={description}
          onEditorChange={(a) => setDescription(a)}
          init={{
            menubar: true,
            height: '100%',
            plugins: [
              'advlist',
              'autolink',
              'lists',
              'link',
              'image',
              'preview',
              'anchor',
              'searchreplace',
              'visualblocks',
              'code',
              'fullscreen',
              'insertdatetime',
              'media',
              'code',
              'help',
              'wordcount'
            ],
            toolbar:
              'undo redo | formatselect |  fontfamily  | removeformat | blocks | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | help',
            font_family_formats:
              'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde' +
              '; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino;' +
              'Helvetica=Helvetica Neue, sans-serif; Impact=impact,chicago; Symbol=symbol; Poppins=Poppins, sans-serif; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times;' +
              ' Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats',
            content_style: `body {font-family: ${theme.fonts.family.primary}}`
          }}
        />
      </EditorWrapper>

      <Row justify="flex-end" gap="12px" margin="15px 0 0 0" tablet={{ flexDirection: 'column', gap: '12px' }}>
        <Column>
          <Button buttonType="accordionAdmin" padding="9px 24px" onClick={saveSubjectHandler} disabled={isLoading}>
            Save Changes
          </Button>
        </Column>
        <Column>
          <Button buttonType="full" padding="9px 48px" onClick={handleDiscard} disabled={isLoading}>
            Discard
          </Button>
        </Column>
      </Row>
    </>
  )

  const internContent = (
    <>
      <div dangerouslySetInnerHTML={setContent()}></div>

      <Button buttonType="accordion" margin="15px 0 0 0" padding="9px 40px" onClick={onComplete}>
        Complete
      </Button>
    </>
  )

  return <SyllabusAccordionBodyWrapper>{!admin ? internContent : adminContent}</SyllabusAccordionBodyWrapper>
}

export default SyllabusAccordionBody
