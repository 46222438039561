import React from 'react'
import styled, { css } from 'styled-components'

type PositionBadgeProps = {
  children: string
  backgroundColor?: string
  size?: string
}

type BadgeProps = Omit<PositionBadgeProps, 'children'>

const Badge = styled.div<BadgeProps>`
  ${({ theme, backgroundColor, size }) => css`
    background-color: ${backgroundColor || '#F7F5FA'};
    border-radius: ${theme.borderRadius.primary};
    width: ${size};
    font-size: ${theme.fonts.sizes.xxsmall};
    color: #595959;
    display: flex;
    justify-content: center;
    padding: 7px 0px;
    text-transform: capitalize;
  `}
`

const PositionBadge = ({ children, backgroundColor, size = '120px' }: PositionBadgeProps) => {
  return (
    <Badge backgroundColor={backgroundColor} size={size}>
      {children}
    </Badge>
  )
}

export default PositionBadge
