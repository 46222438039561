import React from 'react'
import styled, { css } from 'styled-components'

interface FormContainerProps {
  formGrid?: {
    gridTemplateColumns?: number
    gap?: string
  }
  margin?: string
  maxWidth?: string
}

const FormContainer = styled.div<FormContainerProps>`
  ${({ theme, formGrid, margin = 'auto', maxWidth }) => css`
    max-width: ${maxWidth ? maxWidth : '320px'};
    width: 100%;
    margin: ${margin};
    font-family: ${theme.fonts.family.primary};

    @media ${theme.devices.tablet} {
      margin-top: 32px;
    }

    ${formGrid &&
    css`
      form {
        display: grid;
        grid-template-columns: repeat(${formGrid.gridTemplateColumns}, 1fr);
        grid-auto-flow: row;
        gap: ${formGrid.gap};
      }
    `}
  `}
`

type TitleProps = {
  titleMarginBottom?: string
  titleFontSize?: string
  titleResponsive?: boolean
}

const Title = styled.h2<TitleProps>`
  ${({
    theme,
    titleMarginBottom = '32px',
    titleFontSize = theme.components.form.title.fontSize,
    titleResponsive
  }) => css`
    margin-bottom: ${titleMarginBottom};
    color: ${theme.components.form.title.color};
    font-size: ${titleFontSize};
    font-weight: 600;

    ${titleResponsive &&
    css`
      @media ${theme.devices.tablet} {
        font-size: 14px;
      }
    `}
  `}
`

const Text = styled.p`
  ${({ theme }) => css`
    color: ${theme.components.form.text.color};
    font-size: ${theme.components.form.text.fontSize};
    margin: 16px 0 30px;
  `}
`

interface IForm extends FormContainerProps {
  children: JSX.Element | JSX.Element[]
  title?: string
  text?: string | JSX.Element
  titleMarginBottom?: string
  titleFontSize?: string
  onSubmit: any
  titleResponsive?: boolean
}

const Form = ({
  children,
  title,
  text,
  titleMarginBottom,
  onSubmit,
  formGrid,
  margin,
  titleFontSize,
  titleResponsive,
  maxWidth
}: IForm) => {
  return (
    <FormContainer formGrid={formGrid} margin={margin} maxWidth={maxWidth}>
      <Title titleFontSize={titleFontSize} titleMarginBottom={titleMarginBottom} titleResponsive={titleResponsive}>
        {title}
      </Title>
      {text && <Text>{text}</Text>}
      <form onSubmit={onSubmit}>{children}</form>
    </FormContainer>
  )
}

export default Form
