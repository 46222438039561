import React from 'react'
import styled, { css } from 'styled-components'

interface ISectionHeadingProps {
  mobile?: boolean
  marginBottom?: string
  color?: string
}

const SectionHeading = styled.h2<ISectionHeadingProps>`
  ${({ theme, mobile, marginBottom = '30px', color }) => css`
    color: ${color};
    font-size: ${theme.layout.section.heading.size.laptop};
    font-weight: 600;
    margin-bottom: ${marginBottom};
    text-transform: uppercase;
    ${mobile &&
    css`
      display: none;
    `}

    @media ${({ theme }) => theme.devices.tabletLandscape} {
      font-size: ${theme.layout.section.heading.size.tabletLandscape};
      ${mobile &&
      css`
        display: block;
      `}
    }

    @media ${({ theme }) => theme.devices.mobile} {
      font-size: ${theme.layout.section.heading.size.mobile};
    }
  `}
`

interface HeadingProps extends ISectionHeadingProps {
  children: string
}

const SectionHeadingComp = ({ children, mobile, marginBottom, color }: HeadingProps) => {
  return (
    <SectionHeading mobile={mobile} marginBottom={marginBottom} color={color}>
      {children}
    </SectionHeading>
  )
}

export default SectionHeadingComp
