import './App.css'
import React from 'react'
import Router from 'src/router'
import { theme } from 'src/themes/theme'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import ModalProvider from 'src/context/modalContext'
import AuthContextProvider from './context/authContext'
import { QueryClientProvider, QueryClient } from 'react-query'
import { Toaster } from 'react-hot-toast'

const client = new QueryClient({
  defaultOptions: { queries: { retry: false, refetchOnReconnect: false, refetchOnWindowFocus: false } }
})

const App = () => (
  <QueryClientProvider client={client}>
    <AuthContextProvider>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <ModalProvider>
            <Router />
            <Toaster />
          </ModalProvider>
        </ThemeProvider>
      </BrowserRouter>
    </AuthContextProvider>
  </QueryClientProvider>
)

export default App
