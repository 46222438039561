import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import Button from 'src/components/common/buttons/Button'
import Icon from 'src/components/icon/Icon'
import { useAuth } from 'src/context/authContext'
import useAddSyllabusPDF from 'src/hooks/mutations/useAddSyllabusPDF'
import { useGetSyllabus } from 'src/hooks/queries/syllabus'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const UploadOptionsWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

const PDFLink = styled.a`
  text-decoration: none;
  color: red;
`

const PDFButton = styled.button`
  ${({ theme }) => `background-color: #fbfcff;
  border: 1px solid #f0e7ff;
  padding: 20px;
  display: flex;
  gap: 6px;
  align-items: center;
  border-radius: 4px;
  font-family: ${theme.fonts.family.primary};
  font-weight: 600;
  margin-bottom: 32px;
  
  &:hover {
    cursor: pointer;
  }
  `}
`

const SyllabusPDF = () => {
  const [showUploadOptions, setShowUploadOptions] = useState(false)
  const [selectedFile, setSelectedFile] = useState<File>()

  const { id = '' } = useParams()
  const { isIntern } = useAuth()

  const { data, isLoading: syllabusLoading } = useGetSyllabus(id)
  const { mutate, isLoading: uploadingPDF } = useAddSyllabusPDF()

  const showUpload = () => {
    setShowUploadOptions(true)
  }

  const cancelUpload = () => {
    setShowUploadOptions(false)
  }

  const handleFileSelect: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    e.target.files && setSelectedFile(e.target.files[0])
  }

  const uploadPDF = () => {
    if (!selectedFile) {
      return
    }

    const formData = new FormData()
    formData.append('Id', id)
    formData.append('Attachment', selectedFile)

    mutate(formData, {
      onSuccess: () => {
        toast.success('PDF successfully uploaded', { style: { fontFamily: 'Poppins, sans-serif' } })
      }
    })
  }

  if (syllabusLoading) {
    return null
  }

  if (data?.eduSyllabus.attachmentDownloadUrl) {
    return (
      <Container>
        <PDFLink rel="noreferer" href={data.eduSyllabus.attachmentDownloadUrl} target="_blank">
          <PDFButton>
            <p>Syllabus PDF</p>
            <Icon iconName="iconDownload" />
          </PDFButton>
        </PDFLink>
      </Container>
    )
  }

  if (!isIntern) {
    return (
      <Container>
        {showUploadOptions ? (
          <UploadOptionsWrapper>
            <input type="file" name="file" accept="application/pdf" onChange={handleFileSelect} />
            <Button buttonType="full" padding="8px" disabled={!selectedFile || uploadingPDF} onClick={uploadPDF}>
              Upload
            </Button>
            <Button buttonType="full" padding="8px" disabled={uploadingPDF} onClick={cancelUpload}>
              Cancel
            </Button>
          </UploadOptionsWrapper>
        ) : (
          <Button buttonType="full" padding="8px" onClick={showUpload} margin="16px 0px">
            Upload PDF
          </Button>
        )}
      </Container>
    )
  }

  return <div />
}

export default SyllabusPDF
