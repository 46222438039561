import React from 'react'
import { HeaderComponent, LogoText } from './header.styles'
import Row from 'src/components/common/Row'
import Column from 'src/components/common/Column'
import Button from 'src/components/common/buttons/Button'
import AuthorizedUserPanel from './AuthorizedUserPanel'
import { useNavigate } from 'react-router-dom'
import { useAuth } from 'src/context/authContext'

import Logo from '../logo.svg'

const Header = () => {
  const { isLoggedIn, isIntern } = useAuth()
  const navigate = useNavigate()

  const handleClick = () => {
    navigate('authorization')
  }

  const handleLogoClick = () => {
    isIntern ? navigate('/pages/analytics') : navigate('/')
  }

  const authorizedUserPanel = <AuthorizedUserPanel />

  return (
    <HeaderComponent isLoggedIn={isLoggedIn}>
      <Row>
        {!isLoggedIn ? (
          <>
            <Column>
              <img src={Logo} onClick={handleLogoClick} style={{ width: 150, height: '100%' }} />
            </Column>
            <Button onClick={handleClick} buttonType="outline" padding="9.5px 42px">
              Log in
            </Button>
          </>
        ) : (
          authorizedUserPanel
        )}
      </Row>
    </HeaderComponent>
  )
}

export default Header
