import React from 'react'
import styled, { css } from 'styled-components'

// NOTE: component style

interface IRowComponentProps {
  margin?: string
  gap?: string
  justify?: string
  flexDirection?: string
  alignItems?: string
  height?: string
  tabletLandscape?: {
    gap?: string
    flexDirection?: string
    margin?: string
  }
  tablet?: {
    gap?: string
    flexDirection?: string
    margin?: string
  }
}

const RowComponent = styled.div<IRowComponentProps>`
  ${({
    theme,
    margin,
    gap,
    flexDirection,
    alignItems,
    height,
    tabletLandscape,
    tablet,
    justify = 'space-between'
  }) => css`
    display: flex;
    width: 100%;
    justify-content: ${justify};
    align-items: ${alignItems ? alignItems : 'center'};
    ${height &&
    css`
      height: ${height};
    `}
    ${flexDirection &&
    css`
      flex-direction: ${flexDirection};
    `}
    ${margin &&
    css`
      margin: ${margin};
    `};
    ${gap &&
    css`
      gap: ${gap};
    `}
    ${tabletLandscape &&
    css`
      @media ${theme.devices.tabletLandscape} {
        gap: ${tabletLandscape?.gap || 0};
        ${tabletLandscape?.flexDirection &&
        css`
          flex-direction: ${tabletLandscape?.flexDirection};
        `};
        ${tabletLandscape?.margin &&
        css`
          margin: ${tabletLandscape?.margin};
        `};
      }
    `}
    ${tablet &&
    css`
      @media ${theme.devices.tablet} {
        gap: ${tablet?.gap || 0};
        ${tablet?.flexDirection &&
        css`
          flex-direction: ${tablet?.flexDirection};
        `};
        ${tablet?.margin &&
        css`
          margin: ${tablet?.margin};
        `};
      }
    `}
  `}
`
// NOTE: component

interface IRowProps extends IRowComponentProps {
  children: JSX.Element | JSX.Element[]
}

const Row = ({
  children,
  margin,
  gap,
  flexDirection,
  alignItems,
  height,
  tabletLandscape,
  tablet,
  justify
}: IRowProps) => {
  return (
    <RowComponent
      margin={margin}
      gap={gap}
      justify={justify}
      flexDirection={flexDirection}
      alignItems={alignItems}
      height={height}
      tabletLandscape={tabletLandscape}
      tablet={tablet}>
      {children}
    </RowComponent>
  )
}

export default Row
