import React, { FC } from 'react'
import Modal from '../common/Modal'
import Row from '../common/Row'
import Button from '../common/buttons/Button'
import styled from 'styled-components'
import { useDeleteEvent } from 'src/hooks/mutations/events'

const DeleteEventModalWrapper = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;

    p {
      font-family: ${theme.fonts.family.primary}
    }
  `}
`

interface DeleteEventModalProps {
  eventId: string
  onCancel: () => void
}

const DeleteEventModal: FC<DeleteEventModalProps> = ({ eventId, onCancel }) => {
  const { mutateAsync: deleteEvent, isLoading } = useDeleteEvent()

  const handleDeleteClick = async () => {
    try {
      await deleteEvent(eventId)
      onCancel()
    } catch (ex) {
      console.warn(ex)
    }
  }

  return (
    <Modal destination={document.getElementById('root') as HTMLElement} padding="36px">
      <DeleteEventModalWrapper>
        <p>Delete Event?</p>
        <Row gap="16px">
          <Button buttonType={'full'} padding={'8px'} onClick={handleDeleteClick} disabled={isLoading}>
            Delete
          </Button>
          <Button buttonType={'full'} padding={'8px'} onClick={onCancel} disabled={isLoading}>
            Cancel
          </Button>
        </Row>
      </DeleteEventModalWrapper>
    </Modal>
  )
}

export default DeleteEventModal
