export enum QUERY_KEYS {
  EVENTS = 'events',
  EVENT = 'event',
  MONTHS = 'months',
  STACKS = 'stacks',
  STACK = 'stack-by-id',
  REGIONS = 'regions',
  ALL_USERS = 'users',
  SYLLABUS = 'syllabus',
  INTERN_INFO = 'intern',
  USER_PROFILE = 'user-profile',
  ALL_SYLLABUS = 'all-syllabuses',
  STACK_SYLLABUS = 'stack-syllabus',
  USER_ANALYTICS = 'user-analytics',
  USER_UPCOMING_EVENTS = 'upcoming-events',
  INTERN_ANALYTICS = 'intern-analytics'
}

export enum USER_ROLES {
  ADMIN = 'EduAdmin', // should be eduAdmin
  MENTOR = 'EduMentor',
  STUDENT = 'EduIntern',
  SUPER_ADMIN = 'SuperAdmin'
}
