import { AxiosError } from 'axios'
import { QUERY_KEYS } from 'src/enums'
import { deleteMonth } from 'src/api/services/syllabus'
import { useMutation, useQueryClient } from 'react-query'

export const useDeleteMonth = () => {
  const client = useQueryClient()

  const { mutate, isLoading, isError } = useMutation<{ success?: boolean }, AxiosError, string>(deleteMonth, {
    onSuccess: () => client.invalidateQueries([QUERY_KEYS.MONTHS])
  })

  return { mutate, isLoading, isError }
}
