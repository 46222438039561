import { useQuery } from 'react-query'
import { getSurveyConfigurations } from 'src/api/services/survey'

const SURVEY_CONFIGURATIONS_QUERY_KEY = 'SURVEY_CONFIGURATIONS'

const useSurveyConfigurations = () => {
  return useQuery({
    queryKey: [SURVEY_CONFIGURATIONS_QUERY_KEY],
    queryFn: getSurveyConfigurations
  })
}

export default useSurveyConfigurations
