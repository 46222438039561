import React from 'react'
import styled, { css } from 'styled-components'

interface IAccordionGenericHeaderProps {
  children: JSX.Element | JSX.Element[]
  onClick: () => void
}

const AccordionGenericHeader = ({ children, onClick }: IAccordionGenericHeaderProps) => {
  return <div onClick={onClick}>{children}</div>
}

export default AccordionGenericHeader
