import moment from 'moment'
import React, { FC } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  ${({ theme }) => `
    width: 100%;
    height: 124px;
    padding: 24px;
    border: 1px solid #f7f5fa;
    border-radius: 24px;
    font-family: ${theme.fonts.family.primary};
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: border-color 0.25s;

    &:hover {
      border-color: #3f99bc;
      cursor: pointer;
    }
  `}
`

const EventTitle = styled.p`
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #170738;
`

const TimeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const EventTime = styled.p`
  background-color: #f7f5fa;
  border-radius: 4px;
  padding: 16px;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
`

interface EventOverviewProps {
  event: {
    id: string
    link: string
    title: string
    description: string
    end: string
    start: string
  }
  onClick: (event: { id: string; link: string; title: string; description: string; end: string; start: string }) => void
}

const EventOverview: FC<EventOverviewProps> = ({ event, onClick }) => {
  return (
    <Container onClick={() => onClick(event)}>
      <EventTitle>{event.title}</EventTitle>
      <TimeContainer>
        <EventTime>{moment(event.start).format('HH:mm')}</EventTime> -
        <EventTime>{moment(event.end).format('HH:mm')}</EventTime>
      </TimeContainer>
    </Container>
  )
}

export default EventOverview
