import { useQuery } from 'react-query'
import { QUERY_KEYS } from 'src/enums'
import { useAuth } from 'src/context/authContext'
import { getMonths } from 'src/api/services/syllabus'

export const useGetMonths = () => {
  const { isLoggedIn } = useAuth()

  const { data, isError, isLoading } = useQuery([QUERY_KEYS.MONTHS], getMonths, { enabled: isLoggedIn })

  return { eduMonths: data?.eduMonths, isError, isLoading }
}
