import qs from 'qs'
import { useLocation } from 'react-router-dom'

export const useQueryString = () => {
  const { search } = useLocation()

  const parseQuery = () => qs.parse(search, { ignoreQueryPrefix: true })

  const getStringFromQuery = (query: any) =>
    qs.stringify(query, {
      arrayFormat: 'repeat',
      indices: true,
      encode: false,
      skipNulls: true
    })

  return { search, parseQuery, getStringFromQuery }
}
