import React from 'react'
import Modal from 'src/components/common/Modal'
import { useModal } from 'src/context/modalContext'
import AddMonthForm from 'src/components/forms/syllabus/AddMonthForm'

const AddMonthModal = () => {
  const { setModalType, setShowModal } = useModal()

  return (
    <Modal destination={document.getElementById('root') as HTMLElement} padding="30px 64px 69px 64px">
      <AddMonthForm setModalType={setModalType} setShowModal={setShowModal} />
    </Modal>
  )
}

export default AddMonthModal
