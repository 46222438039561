import React, { MouseEvent, useState } from 'react'
import { CourseOverview } from '../../api/services/courseOverview'
import styled from 'styled-components'
import Icon from '../../components/icon/Icon'
import * as Collapsible from '@radix-ui/react-collapsible'
import { useGetMonths } from '../../hooks/queries/syllabus'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router'
import Row from '../../components/common/Row'

interface Props {
  data: CourseOverview[]
  onClick: (e: MouseEvent<HTMLLIElement>, index: number) => void
  activeIndex: number
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const ListItem = styled.li<{ active: boolean }>`
  list-style: none;
  font-size: 14px;
  color: ${({ active }) => (active ? '#073763' : '#64646D')};
  font-weight: ${({ active }) => (active ? '700' : '400')};
  text-decoration: ${({ active }) => (active ? 'underline' : 'none')};
  text-decoration-color: #fb60a5;
  text-underline-offset: 4px;
  text-decoration-thickness: 2px;
`

const NavbarLink = styled(Link)<{ active: boolean }>`
  list-style: none;
  font-size: 14px;
  color: ${({ active }) => (active ? '#073763' : '#64646D')};
  font-weight: ${({ active }) => (active ? '700' : '400')};
  text-decoration: ${({ active }) => (active ? 'underline' : 'none')};
  text-decoration-color: #fb60a5;
  text-underline-offset: 4px;
  text-decoration-thickness: 2px;
`

const CourseTitle = styled.p`
  font-size: 16px;
  font-weight: 700;
  color: #073763;
  font-family: Poppins, sans-serif;
  cursor: pointer;
`

const IconContainer = styled.div<{ isOpen: boolean }>`
  ${({ isOpen }) => `
    transform: rotate(${!isOpen ? '-90deg' : '0deg'});
    transition: transform 0.3s;
  `}
`

const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
`
const CourseOverviewSideBar = ({ data, onClick, activeIndex }: Props) => {
  const navigate = useNavigate()
  const [activeDesignIndex, setActiveDesignIndex] = useState(0)
  const [tab, setTab] = useState<'course' | 'design' | 'none'>('course')
  const { eduMonths } = useGetMonths()
  const handleOverviewClick = () => {
    navigate('./')
    setTab((prevState) => (prevState === 'course' ? 'none' : 'course'))
  }

  const handleDesignOpen = () => {
    navigate(`./design/${eduMonths?.[0].id}`)
    setTab((prevState) => (prevState === 'design' ? 'none' : 'design'))
  }

  const handleDesignClick = (i: number) => {
    setActiveDesignIndex(i)
  }

  return (
    <Wrapper>
      <Collapsible.Root open={tab === 'course'} onOpenChange={handleOverviewClick}>
        <Collapsible.Trigger asChild>
          <Flex>
            <CourseTitle>Course Overview</CourseTitle>
            <IconContainer isOpen={tab === 'course'}>
              <Icon iconName="chevron" color="black" size="20px" />
            </IconContainer>
          </Flex>
        </Collapsible.Trigger>
        <Collapsible.Content>
          <List>
            {data
              .filter((item) => item.name.toLowerCase() !== 'course overview')
              .map((item, index) => {
                return (
                  <ListItem
                    key={item.id}
                    active={activeIndex === index}
                    onClick={(e) => {
                      onClick(e, index)
                    }}>
                    {item.name}
                  </ListItem>
                )
              })}
          </List>
        </Collapsible.Content>
      </Collapsible.Root>

      <Collapsible.Root open={tab === 'design'} onOpenChange={handleDesignOpen}>
        <Collapsible.Trigger asChild>
          <Flex>
            <CourseTitle>Design and timing</CourseTitle>
            <IconContainer isOpen={tab === 'design'}>
              <Icon iconName="chevron" color="black" size="20px" />
            </IconContainer>
          </Flex>
        </Collapsible.Trigger>
        <Collapsible.Content>
          <List style={{ width: '50%' }}>
            {eduMonths?.map((item, index) => {
              return (
                <Row key={item.id} alignItems="center" justify="space-between">
                  <NavbarLink
                    active={activeDesignIndex === index}
                    to={`./design/${item.id}`}
                    onClick={() => handleDesignClick(index)}>
                    {item.name}
                  </NavbarLink>
                  <Icon iconName="iconCheckmark" color={activeDesignIndex === index ? '#FB60A5' : ''} />
                </Row>
              )
            })}
          </List>
        </Collapsible.Content>
      </Collapsible.Root>
    </Wrapper>
  )
}

export default CourseOverviewSideBar
