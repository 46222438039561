import React, { FC } from 'react'
import styled from 'styled-components'

import { ReactComponent as Check } from 'src/assets/images/icons/check.svg'

const Container = styled.div`
  padding-top: 114px;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
`

const Checkmark = styled(Check)`
  margin-bottom: 40px;
`

const Title = styled.h1`
  color: #170738;
  font-size: 26px;
  line-height: 29px;
  padding-bottom: 24px;
`

const InfoText = styled.p`
  display: flex;
  gap: 16px;
  color: #a8a8a8;
  font-size: 18px;
  line-height: 29px;
  padding-bottom: 16px;
`

interface PartiallyRatedProps {
  isExam: boolean
}

const PartiallyRated: FC<PartiallyRatedProps> = ({ isExam }) => {
  return (
    <Container>
      <Checkmark />
      <Title>{isExam ? 'Test is finished!' : 'Thanks! Survey is finished!'}</Title>
      {isExam && <InfoText>Test has been sent, it will be processed and you will receive a response</InfoText>}
    </Container>
  )
}

export default PartiallyRated
