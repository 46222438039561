import React from 'react'
import Sidebar from './Sidebar'
import { breakpoints } from 'src/themes/theme'
import styled, { css } from 'styled-components'
import { useDevice } from 'src/hooks/custom'
import { Outlet, useLocation } from 'react-router-dom'

const ContentWrapper = styled.div`
  ${({ theme }) => css`
    max-width: 1082px;
    height: 100%;
    margin: 0 auto;
    padding: 85px 60px;
    display: grid;
    grid-template-columns: max-content 1fr;
    gap: 115px;
    min-height: 100vh;

    @media ${theme.devices.tablet} {
      grid-template-columns: minmax(240px, 1fr);
      justify-items: center;
      padding: 50px 40px;
      gap: 50px;
    }
  `}
`

const ProfilePage = () => {
  const { pathname } = useLocation()
  const path = pathname.split('/').pop()
  const { width: deviceWidth } = useDevice()
  const tabletWidth = +breakpoints.tablet.slice(0, -2)

  return (
    <>
      <ContentWrapper>
        {deviceWidth < tabletWidth && path === 'change-password' ? null : <Sidebar />}
        <Outlet />
      </ContentWrapper>
    </>
  )
}

export default ProfilePage
