import { AxiosError } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { CreateSyllabusPDFPayload, createSyllabusPDF } from 'src/api/services/syllabus'
import { QUERY_KEYS } from 'src/enums'

const useAddSyllabusPDF = () => {
  const client = useQueryClient()
  return useMutation<any, AxiosError, FormData>({
    mutationFn: createSyllabusPDF,
    onSettled: (_, __, formData) => {
      client.invalidateQueries([QUERY_KEYS.SYLLABUS, formData.get('Id')])
    }
  })
}

export default useAddSyllabusPDF
