import { useQuery } from 'react-query'
import { QUERY_KEYS } from 'src/enums'
import { useAuth } from 'src/context/authContext'
import { getStack } from 'src/api/services/stack'

export const useGetStack = (id: string) => {
  const { isLoggedIn } = useAuth()

  const { data, isError, isLoading } = useQuery([QUERY_KEYS.STACK, id], () => getStack(id), {
    enabled: isLoggedIn && Boolean(id)
  })

  return { eduStack: data?.eduStack, isError, isLoading }
}
