import { AxiosError } from 'axios'
import { QUERY_KEYS } from 'src/enums'
import { addMonth } from 'src/api/services/syllabus'
import { useMutation, useQueryClient } from 'react-query'

export const useAddMonth = () => {
  const client = useQueryClient()

  const { mutate, isLoading, isError } = useMutation<{ success?: boolean }, AxiosError, { name: string }>(addMonth, {
    onSuccess: () => client.invalidateQueries([QUERY_KEYS.MONTHS])
  })

  return { mutate, isLoading, isError }
}
