import { useQuery } from 'react-query'
import { QUERY_KEYS } from 'src/enums'
import { useAuth } from 'src/context/authContext'
import { getSyllabusByStackId } from 'src/api/services/syllabus'

export const useGetStackSyllabus = (stackId: string) => {
  const { isIntern } = useAuth()

  const { data, isError, isLoading } = useQuery(
    [QUERY_KEYS.STACK_SYLLABUS, stackId],
    () => getSyllabusByStackId(stackId),
    { enabled: isIntern }
  )

  const stackSubs = data?.eduSyllabus.eduSubjects
  const id = data?.eduSyllabus.id

  return { stackSubs, isError, isLoading, id }
}
