import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from 'src/context/authContext'
import axios, { AxiosError } from 'axios'

export const axiosInstanceAdmin = axios.create({
  baseURL: process.env.REACT_APP_ADMIN_BASEURL
})

export const axiosInstanceIdentity = axios.create({
  baseURL: process.env.REACT_APP_IDENTITY_BASEURL
})

export const axiosInstanceEdu = axios.create({ baseURL: process.env.REACT_APP_EDU_BASEURL })

interface Props {
  children: React.ReactNode
}

let refreshTokenPromise: null | Promise<any>

const AxiosInterceptor: React.FC<Props> = ({ children }) => {
  const { logOut } = useAuth()
  const navigate = useNavigate()
  const [isSet, setIsSet] = useState(false)

  useEffect(() => setIsSet(true), [])

  useEffect(() => {
    const reqSuccInterceptor = (config: any) => {
      if (config.headers) {
        config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('access_token')
      }
      return config
    }

    // Function to refresh the access token
    const getNewAccessToken = async () => {
      try {
        const response = await axiosInstanceIdentity.post('/api/v1/auth/refresh', {
          token: localStorage.getItem('access_token'),
          refreshToken: localStorage.getItem('refresh_token')
        })
        const newAccessToken = response.data.token
        const newRefreshToken = response.data.refreshToken

        // Update the access token in local storage
        localStorage.setItem('access_token', newAccessToken)
        localStorage.setItem('refresh_token', newRefreshToken)

        return newAccessToken
      } catch (error) {
        // Handle the refresh token error here (e.g., logout the user)
        throw new Error('Failed to refresh access token. Please log in again.')
      }
    }
    const reqErrInterceptor = (error: AxiosError) => Promise.resolve(error)

    const adminReqInterceptor = axiosInstanceAdmin.interceptors.request.use(reqSuccInterceptor, reqErrInterceptor)
    const identityReqInterceptor = axiosInstanceIdentity.interceptors.request.use(reqSuccInterceptor, reqErrInterceptor)
    const eduReqInterceptor = axiosInstanceEdu.interceptors.request.use(reqSuccInterceptor, reqErrInterceptor)

    const adminRespInterceptor = axiosInstanceAdmin.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config

        if (error?.response?.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true
          // when multiple requests are made at the same time
          if (!refreshTokenPromise) {
            refreshTokenPromise = getNewAccessToken().then((newAccessToken) => {
              refreshTokenPromise = null
              return newAccessToken
            })
          }

          return refreshTokenPromise
            .then((newAccessToken) => {
              originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`
              return axiosInstanceAdmin(originalRequest)
            })
            .catch((error) => {
              logOut()
              navigate('/authorization')
            })
        }
        return Promise.reject(error)
      }
    )

    const eduRespInterceptor = axiosInstanceEdu.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config

        if (error?.response?.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true
          // when multiple requests are made at the same time
          if (!refreshTokenPromise) {
            refreshTokenPromise = getNewAccessToken().then((newAccessToken) => {
              refreshTokenPromise = null
              return newAccessToken
            })
          }

          return refreshTokenPromise
            .then((newAccessToken) => {
              originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`
              return axiosInstanceEdu(originalRequest)
            })
            .catch((error) => {
              logOut()
              navigate('/authorization')
            })
            .finally(() => {
              refreshTokenPromise = null
            })
        }
        return Promise.reject(error)
      }
    )

    const identityRespInterceptor = axiosInstanceIdentity.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config

        if (error?.response?.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true
          // when multiple requests are made at the same time
          if (!refreshTokenPromise) {
            refreshTokenPromise = getNewAccessToken().then((newAccessToken) => {
              refreshTokenPromise = null
              return newAccessToken
            })
          }

          return refreshTokenPromise
            .then((newAccessToken) => {
              originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`
              return axiosInstanceIdentity(originalRequest)
            })
            .catch((error) => {
              logOut()
              navigate('/authorization')
            })
        }
        return Promise.reject(error)
      }
    )

    return () => {
      // axiosInstanceAdmin.interceptors.request.eject(adminReqInterceptor)
      // axiosInstanceIdentity.interceptors.request.eject(identityReqInterceptor)
      // axiosInstanceIdentity.interceptors.response.eject(adminRespInterceptor)
      // axiosInstanceIdentity.interceptors.response.eject(identityRespInterceptor)
    }
  }, [navigate, logOut])

  return <React.Fragment>{isSet && children}</React.Fragment>
}

export default AxiosInterceptor
