import React from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

const StyledLink = styled(Link)`
  ${({ theme }) => css`
    &:link,
    &:visited {
      font-size: ${theme.components.form.link.fontSize};
      color: ${theme.components.form.link.color};
      font-weight: 400;
      text-decoration: none;
    }

    &:hover,
    &:active {
      text-decoration: underline;
    }
  `}
`

type RouterLinkType = {
  children: string
  to: string
}

const RouterLink = ({ children, to }: RouterLinkType) => {
  return <StyledLink to={to}>{children}</StyledLink>
}

export default RouterLink
