import React, { useState } from 'react'
import { SectionWrapper } from 'src/components/common/Section'
import { useNotifications } from 'src/hooks/queries/notifications'
import Notification from './Notification'
import { NotificationsList, PageTitle } from './notifications.styles'

const Notifications = () => {
  const [page] = useState(1)

  const { data } = useNotifications({ page, pageSize: 20 })

  return (
    <NotificationsList>
      <SectionWrapper>
        <PageTitle>Notifications</PageTitle>
        {data?.internalNotifications &&
          data?.internalNotifications.map((notification) => (
            <Notification key={notification.id} notification={notification} />
          ))}
      </SectionWrapper>
    </NotificationsList>
  )
}

export default Notifications
