import React from 'react'
import Section from 'src/components/common/Section'
import Row from 'src/components/common/Row'
import Column from 'src/components/common/Column'
import Icon from 'src/components/icon/Icon'
import { FooterComponent, Copyright, PrivacyPolicy } from './footer.styles'
import Logo from '../logo.svg'

const Footer = () => {
  return (
    <FooterComponent>
      <Section>
        <Row gap="163px" tabletLandscape={{ flexDirection: 'column-reverse', gap: '9px' }}>
          <Column>
            <img src={Logo} style={{ width: 150, height: '100%' }} />
          </Column>
          <Column textAlign="center" flexProp="0 0 auto" mobile={{ flexProp: '0 1 auto' }}>
            <Copyright>© 2023 Making Science Sweeft, Inc. All rights reserved.</Copyright>
          </Column>
          <Column flex gap="10px">
            <a href="https://www.instagram.com/sweeft.making.science/" target="_blank" rel="noreferrer">
              <Icon iconName="linkedin" />
            </a>
            <a href="https://www.linkedin.com/company/ms-sweeft/" target="_blank" rel="noreferrer">
              <Icon iconName="facebook" />
            </a>
            <a href="https://www.facebook.com/making.science.sweeft" target="_blank" rel="noreferrer">
              <Icon iconName="instagram" />
            </a>
          </Column>
        </Row>
      </Section>
    </FooterComponent>
  )
}

export default Footer
