import React, { FC, useState } from 'react'
import Section from 'src/components/common/Section'
import { useSurveyConfigurations } from 'src/hooks/queries/survey'
import styled, { css } from 'styled-components'
import SurveyPreview from './components/SurveyPreview'
import { SurveyType } from 'src/api/services/survey'
import { theme } from 'src/themes/theme'

const Container = styled.div`
  ${({ theme }) => css`
    padding: 80px 0 0;
    color: ${({ theme }) => theme.colors.primary};
    min-height: 100vh;

    @media ${theme.devices.tablet} {
      padding-top: 40px;
    }
  `}
`

const Title = styled.h1`
  padding-bottom: 32px;
  color: #170738;
  font-weight: 600;
  font-size: 40px;
  line-height: 60px;
`

const SurveyPreviewsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const NoExams = styled.p`
  ${({ theme }) => `
    font-size: 16px;
    line-height: 32px;
    color: #bc5252;
    font-weight: 500;
    font-family: ${theme.fonts.family.primary}
  `}
`

interface SurveysPageProps {
  isExam?: boolean
}

const SurveysPage: FC<SurveysPageProps> = ({ isExam = false }) => {
  const { data } = useSurveyConfigurations()
  return (
    <Container>
      <Section>
        <Title>{isExam ? 'Exams' : 'Surveys'}</Title>
        {data?.surveyConfigurations.length === 0 ? (
          <NoExams>
            It appears that you have not taken or been assigned any exams or surveys. Please check back later
          </NoExams>
        ) : (
          <SurveyPreviewsContainer>
            {data?.surveyConfigurations
              .filter((config) => (config.surveyType === SurveyType.Exam) === isExam)
              .map((config) => (
                <SurveyPreview key={config.id} config={config} />
              ))}
          </SurveyPreviewsContainer>
        )}
      </Section>
    </Container>
  )
}

export default SurveysPage
