import { USER_ROLES } from 'src/enums'
import { IUser } from 'src/api/services/user'
import jwtDecode, { JwtPayload } from 'jwt-decode'
import { IOptions } from 'src/components/common/Select'

type JwtExtPayload = JwtPayload & { role: USER_ROLES; userId: string; userInfo: any }

export const decodeJWT = () => {
  const token = localStorage.getItem('access_token')
  const { role, userId, userInfo } = jwtDecode<JwtExtPayload>(token || '')

  return { role, userId, userInfo }
}

export const filterEventOwners = (eduStack: any, seniority: IOptions) => {
  const sen = seniority?.value

  const userOpts =
    sen === 'eduTeamLead' || sen === 'eduSquadLead'
      ? [
          {
            value: eduStack?.[sen]?.userId,
            label: eduStack?.[sen]?.userId ? `${eduStack?.[sen]?.firstName} ${eduStack?.[sen]?.lastName}` : ''
          }
        ]
      : eduStack?.[sen]?.flatMap((user: IUser) =>
          user.userId ? { label: `${user.firstName} ${user.lastName}`, value: user.userId } : []
        )

  return userOpts
}
