import React from 'react'
import styled, { css } from 'styled-components'
import Date from '../../../components/common/Date'
import Row from '../../../components/common/Row'
import Text from '../../../components/common/Text'
import Icon from '../../../components/icon/Icon'
import { Link } from 'react-router-dom'
import { useGetUserUpcomingEvents } from '../../../hooks/queries/user/useGetUserUpcommingEvents'
import moment from 'moment/moment'

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  margin-left: auto;
  flex-basis: 20rem;
  flex-grow: 1;
`

const TimeStamp = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #d9d9d9;
`

const RowWrapper = styled.div`
  display: flex;
  border-bottom: 1px solid #f7f5fa;
  padding-block: 16px;
`

const TitleHeader = styled.h2`
  color: #170738;
  font-size: 22px;
`
const UpcomingEventsList = () => {
  const { data: events, isLoading, error } = useGetUserUpcomingEvents()

  if (isLoading) return <div></div>
  if (error) return <div>Error</div>

  return (
    <ListWrapper>
      <Row justify="space-between" alignItems="center">
        <TitleHeader>Upcoming Events</TitleHeader>
        <Link to={`../events`}>
          <Icon iconName="iconCalendarArrow" color="#170738" />
        </Link>
      </Row>
      {events?.events.slice(0, 3).map((event, index) => (
        <RowWrapper key={index}>
          <Row gap="18px" justify="flex-start">
            <Date date={moment(event.startDateUtc).date()} day={moment(event.startDateUtc).format('dddd')} />
            <div>
              <Text color="#170738" fontSize="13px">
                {event.name}
              </Text>
              <Row justify="flex-start" gap="5px">
                <TimeStamp />
                <Text color="#A7AAB2" fontSize="13px">{`${moment(event.startDateUtc).format('HH:mm')} - ${moment(
                  event.endDateUtc
                ).format('HH:mm')}`}</Text>
              </Row>
            </div>
          </Row>
        </RowWrapper>
      ))}
    </ListWrapper>
  )
}

export default UpcomingEventsList
