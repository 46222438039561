import React from 'react'
import styled, { css } from 'styled-components'
import ImageStart from './../assets/images/image-start.jpg'
import { Outlet } from 'react-router'

type ProfilePageContainerProps = {
  imageUrl: 'string'
}

const PageContainer = styled.div<ProfilePageContainerProps>`
  ${({ theme, imageUrl }) => css`
    background-image: linear-gradient(
        to right,
        ${theme.pages.profilePage.backgroundColor},
        ${theme.pages.profilePage.backgroundColor}
      ),
      url(${imageUrl});
    height: auto;
    font-family: ${theme.fonts.family.primary};
  `}
`

const ContentBackground = styled.div`
  background-color: ${({ theme }) => theme.pages.profilePage.contentSectionBackgroundColor};
  height: 100%;
  width: 100%;
`

// type PageWrapperType = {
//   children: JSX.Element | JSX.Element[]
// }

// const PageWrapper = ({ children }: PageWrapperType) => {
//   return <PageContainer imageUrl={ImageStart}>{children}</PageContainer>
// }

const PageWrapper = () => {
  return (
    <PageContainer imageUrl={ImageStart}>
      <ContentBackground>
        <Outlet />
      </ContentBackground>
    </PageContainer>
  )
}

export default PageWrapper
