import React, { FC, useState } from 'react'
import { IStack } from 'src/api/services/stack'
import Modal from '../common/Modal'
import styled from 'styled-components'
import Row from '../common/Row'
import Button from '../common/buttons/Button'
import Column from '../common/Column'
import { useCreateSyllabus } from 'src/hooks/mutations/syllabus'
import { toast } from 'react-hot-toast'

interface CreateSyllabusModalProps {
  stack: IStack | undefined
  onClose: () => void
}

const CreateSyllabusModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
`

const CreateSyllabusModal: FC<CreateSyllabusModalProps> = ({ stack, onClose }) => {
  const [selectedFile, setSelectedFile] = useState<File>()

  const { mutate: createSyllabus, isLoading } = useCreateSyllabus()

  const createSyllabusWithPDF = () => {
    if (!stack) {
      return
    }

    createSyllabus(
      { Name: stack.name, EduStackId: stack.id, Attachment: selectedFile },
      {
        onSuccess: () => {
          onClose()
          toast.success('Syllabus successfully created', {
            style: { fontFamily: 'Poppins, sans-serif' },
            duration: 3000
          })
        }
      }
    )
  }

  const createSyllabusManually = () => {
    if (!stack) {
      return
    }
    createSyllabus(
      { Name: stack.name, EduStackId: stack.id },
      {
        onSuccess: () => {
          onClose()
          toast.success('Syllabus successfully created', {
            style: { fontFamily: 'Poppins, sans-serif' },
            duration: 3000
          })
        }
      }
    )
  }

  const handleFileSelect: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    event.target.files && setSelectedFile(event.target.files[0])
  }

  if (!stack) {
    return null
  }

  return (
    <Modal destination={document.getElementById('root') as HTMLElement} padding="36px">
      <CreateSyllabusModalWrapper>
        <p>Upload PDF?</p>
        <Row gap="16px">
          <input type="file" name="file" accept="application/pdf" onChange={handleFileSelect} />
          <Button
            buttonType={'full'}
            padding={'8px'}
            onClick={createSyllabusWithPDF}
            disabled={isLoading || !selectedFile}>
            Upload
          </Button>
        </Row>
        <Button buttonType="full" padding="8px" onClick={createSyllabusManually} disabled={isLoading}>
          Enter data manually
        </Button>
        <Button buttonType={'full'} padding={'8px'} onClick={onClose} disabled={isLoading}>
          Cancel
        </Button>
      </CreateSyllabusModalWrapper>
    </Modal>
  )
}

export default CreateSyllabusModal
