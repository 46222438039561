import React, { FC, useEffect, useState } from 'react'
import {
  ISurveyConfigurationQuestion,
  ISurveySubmission,
  SurveyParticipantSubmissionStatus
} from 'src/api/services/survey'
import { colorPalette, theme } from 'src/themes/theme'
import styled from 'styled-components'
import Button from '../common/buttons/Button'
import SurveyQuestion from 'src/pages/surveyQuestions/components/SurveyQuestion'
import { useParams } from 'react-router'
import useSurveyConfiguration from 'src/hooks/queries/survey/useSurveyConfiguration'
import Icon from '../icon/Icon'
import { convertMsToTime } from 'src/utils/timer'
import moment from 'moment'
import 'prismjs/themes/prism.css'
import 'prismjs'
import Prism from 'prismjs'

const ExamWrapper = styled.div`
  padding: 48px 142px;

  @media ${theme.devices.tablet} {
    padding: 24px 0px 48px 48px;
  }
`

const ExamHeader = styled.div`
  background-color: ${colorPalette.blue.primary[20]};
  padding: 24px 44px;
  color: white;
  border-radius: 4px 4px 0px 0px;

  font-size: 18px;
  line-height: 29px;
`

const Form = styled.form`
  border-radius: 0px 0px 4px 4px;
  background-color: #fbfcff;
  padding: 80px;
  display: flex;
  flex-direction: column;
`

const SwitchButtonRow = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 40px;
`

const PrevButton = styled(Button)`
  ${({ disabled }) => `
    opacity: ${disabled ? '0.7' : '1'};
  `}
  background-color: ${colorPalette.grey.primary[40]};
  border-color: ${colorPalette.grey.primary[40]};
  color: ${colorPalette.neutral[1]};
`

const NextButton = styled(Button)`
  ${({ disabled }) => `
    opacity: ${disabled ? '0.7' : '1'};
  `}
  background-color: ${colorPalette.pink.primary[50]};
  border-color: ${colorPalette.pink.primary[50]};
  color: ${colorPalette.neutral[1]};
`

const NumberRow = styled.div`
  display: flex;
  gap: 16px;
  overflow: scroll;
`
const NumberButton = styled.button<{ active: boolean }>`
  ${({ active }) => `
    background-color: #ffffff00;
    border: 0px;
    font-weight: 500;
    font-size: 26px;
    line-height: 39px;
    height: 39px;
    width: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${active ? colorPalette.pink.primary[60] : colorPalette.pink.primary[20]};

    &:hover {
        cursor: pointer;
    }
  `}
`

const QuestionInfo = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 28px;
`

const QuestionNumber = styled.p`
  border-radius: 4px;
  background-color: ${colorPalette.blue.primary[20]};
  padding: 8px 16px;
  color: ${colorPalette.neutral[1]};
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
`
const QuestionTitle = styled.p`
  ${({ theme }) => `
    color: ${colorPalette.grey.primary[100]};
    font-family: ${theme.fonts.family.secondary};
    font-size: 16px;
    line-height: 30px;
  `}
`

const TimerContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: auto;
`

const TimerText = styled.p`
  ${({ theme }) => `
    font-family ${theme.fonts.family.primary};
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: ${colorPalette.grey.primary[100]}
`}
`

const Grade = styled.p`
  margin-left: auto;
`

const SubmitButton = styled(Button)`
  align-self: flex-end;
  background-color: ${colorPalette.blue.primary[100]};
  border-color: ${colorPalette.blue.primary[100]};
`

interface SurveyExamProps {
  questions: ISurveyConfigurationQuestion[]
  onSubmit: () => void
  submission: ISurveySubmission
}

const SurveyExam: FC<SurveyExamProps> = ({ questions, onSubmit, submission }) => {
  const { id = '' } = useParams()

  const [timerText, setTimerText] = useState(convertMsToTime(0))

  const { data } = useSurveyConfiguration(id)

  useEffect(() => {
    if (!data || !submission) {
      return
    }

    const endTime = moment(submission.startDateTime).add(data.surveyConfiguration.duration, 'minutes')

    setTimerText(convertMsToTime(endTime.diff(moment())))
    const timer = setInterval(() => {
      if (endTime.diff(moment()) <= 1000) {
        clearTimeout(timer)
        onSubmit()
      }
      setTimerText(convertMsToTime(endTime.diff(moment())))
    }, 1000)

    return () => {
      clearTimeout(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, submission])

  const [activeQuestionIndex, setActiveQuestionIndex] = useState(0)
  const [isAnswering, setIsAnswering] = useState(false)

  useEffect(() => {
    Prism.highlightAll()
  }, [activeQuestionIndex])

  const handlePrevClick = () => {
    activeQuestionIndex !== 0 && setActiveQuestionIndex((prev) => prev - 1)
  }

  const handleNextClick = () => {
    activeQuestionIndex !== questions.length - 1 && setActiveQuestionIndex((prev) => prev + 1)
  }

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()

    onSubmit()
  }

  return (
    <ExamWrapper>
      <ExamHeader>{data?.surveyConfiguration.title ?? ''}</ExamHeader>
      <Form onSubmit={handleSubmit}>
        <QuestionInfo>
          <QuestionNumber>Question {activeQuestionIndex + 1}</QuestionNumber>
          <QuestionTitle>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  questions[activeQuestionIndex].text + ' (' + questions[activeQuestionIndex].score + ' point(s)' + ')'
              }}
            />
          </QuestionTitle>
          {submission.status === SurveyParticipantSubmissionStatus.Started ? (
            <TimerContainer>
              <TimerText>{timerText}</TimerText>
              <Icon iconName="iconTimer" color={colorPalette.pink.primary[60]} />
            </TimerContainer>
          ) : questions[activeQuestionIndex].submission ? (
            <Grade>
              {questions[activeQuestionIndex].submission?.score || 0}/{questions[activeQuestionIndex].score}
            </Grade>
          ) : null}
        </QuestionInfo>
        <SurveyQuestion
          configId={id}
          question={questions[activeQuestionIndex]}
          index={activeQuestionIndex}
          submission={submission}
          setIsAnswering={setIsAnswering}
          showTitle={false}
          disabled={submission.status !== SurveyParticipantSubmissionStatus.Started}
        />
        <SwitchButtonRow>
          <PrevButton
            disabled={activeQuestionIndex === 0}
            buttonType="full"
            padding={'9px 45px'}
            type="button"
            onClick={handlePrevClick}>
            Previous
          </PrevButton>
          <NextButton
            disabled={activeQuestionIndex === questions.length - 1}
            buttonType="full"
            padding={'9px 45px'}
            type="button"
            onClick={handleNextClick}>
            Next
          </NextButton>
        </SwitchButtonRow>
        <NumberRow>
          {questions.map((q, i) => (
            <NumberButton
              active={i === activeQuestionIndex}
              onClick={() => setActiveQuestionIndex(i)}
              key={q.id}
              type="button">
              {i + 1}
            </NumberButton>
          ))}
        </NumberRow>
        <SubmitButton
          buttonType={'full'}
          padding={'8px 45px'}
          type="submit"
          disabled={isAnswering || submission.status !== SurveyParticipantSubmissionStatus.Started}>
          Submit
        </SubmitButton>
      </Form>
    </ExamWrapper>
  )
}

export default SurveyExam
