import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { useModal } from 'src/context/modalContext'
import styled, { css } from 'styled-components'

const ModalOverlay = styled.div`
  ${({ theme }) => css`
    position: fixed;
    background-color: ${theme.components.modal.overlay.backgroundColor};
    inset: 0;
    z-index: 99999;
  `}
`

interface IModalWindowProps {
  padding: string
  tablet?: {
    padding: string
  }
}

const ModalWindow = styled.div<IModalWindowProps>`
  ${({ theme, padding, tablet }) => css`
    position: fixed;
    background-color: ${theme.components.modal.window.backgroundColor};
    padding: ${padding};
    border-radius: ${theme.borderRadius.primary};
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    ${tablet &&
    css`
      @media ${theme.devices.tablet} {
        padding: ${tablet.padding};
      }
    `}
  `}
`

interface IModalType extends IModalWindowProps {
  children: JSX.Element | JSX.Element[]
  destination: HTMLElement
  onClick?: () => void
}

const Modal = ({ children, padding, destination, tablet, onClick }: IModalType) => {
  const { setShowModal } = useModal()

  useEffect(() => {
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = 'visible'
    }
  }, [])

  const handleModalClose: React.MouseEventHandler = (event) => {
    if (event.target === event.currentTarget) {
      if (onClick) {
        onClick()
        return
      }
      setShowModal(false)
    }
  }

  return ReactDOM.createPortal(
    <ModalOverlay onClick={handleModalClose}>
      <ModalWindow padding={padding} tablet={tablet}>
        {children}
      </ModalWindow>
    </ModalOverlay>,
    destination
  )
}

export default Modal
