import { colorPalette, theme } from 'src/themes/theme'
import styled from 'styled-components'

export const NotificationsList = styled.div`
  min-height: 90vh;
  padding: 102px 0px;

  @media ${theme.devices.tablet} {
    padding: 64px 0px;
  }
`

export const PageTitle = styled.div`
  font-size: 24px;
  font-weight: 700;
  color: ${colorPalette.blue.primary[100]};
  text-transform: uppercase;
  margin-bottom: 88px;
`

export const NotificationItem = styled.div`
  display: flex;
  padding: 32px 0px;
  justify-content: space-between;

  &:hover {
    background-color: #f0f0f0;
  }

  @media ${theme.devices.tablet} {
    gap: 16px;
    padding: 24px 0px;
  }
`

export const Description = styled.div`
  ${({ theme }) => `
    display: flex;
    gap: 32px;
    align-items: center;

    font-family: ${theme.fonts.family.secondary};
    font-size: 14px;
    line-height: 29px;
    font-weight: 400;

    @media ${theme.devices.tablet} {
      gap: 16px;
    }
  `}
`

export const Dot = styled.div<{ isRead: boolean }>`
  ${({ isRead }) => `
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: ${isRead ? colorPalette.grey.primary[30] : colorPalette.pink.primary[60]};
  `}
`

export const ContentWrapper = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;

  > p {
    color: ${colorPalette.grey.primary[100]};
  }

  @media ${theme.devices.tablet} {
    > p {
      font-size: 14px;
    }
  }
`

export const DateItem = styled.section`
  ${({ theme }) => `
    display: flex;
    gap: 32px;

    > p {
      font-family: ${theme.fonts.family.secondary};
      font-size: 14px;
      line-height: 29px;
      font-weight: 500;
      color: ${colorPalette.blue.primary[100]};
    }

    @media ${theme.devices.tablet} {
      gap: 4px;
      align-items: center;
      flex-direction: column;
      
      > p {
        font-size: 14px;
      }
    }
  `}
`
