import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import Input from 'src/components/common/Input'
import InputReadOnly from 'src/components/common/InputReadOnly'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useGetUser } from 'src/hooks/queries/user'
import { useAuth } from 'src/context/authContext'
import { useToggle } from 'src/hooks/custom'
import { IUser } from 'src/api/services/user'
import moment from 'moment'
import Button from 'src/components/common/buttons/Button'
import useEditUser from 'src/hooks/mutations/user/useEditUser'
import { toast } from 'react-hot-toast'
import { colorPalette } from 'src/themes/theme'

const PersonalInfoForm = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.fonts.family.primary};
    width: 100%;

    @media ${theme.devices.mobile} {
      max-width: 320px;
    }
  `}
`

const Title = styled.h2`
  ${({ theme }) => css`
    margin-bottom: 25px;
    color: ${theme.components.form.title.color};
    font-size: ${theme.pages.profilePage.personalInformationForm.fontSize.desktop};

    @media ${theme.devices.mobile} {
      font-size: ${theme.pages.profilePage.personalInformationForm.fontSize.mobile};
    }
  `}
`

const FormLayout = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(1fr, 296px));
    grid-template-rows: repeat(3, 1fr);
    grid-auto-flow: column;
    gap: 22px 32px;

    & > div {
      margin: 0 !important;
      max-width: 100%;
    }

    @media ${theme.devices.mobile} {
      grid-template-rows: repeat(5, 1fr);
      margin-bottom: 46px;
    }
  `}
`

const SecondForm = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(1fr, 296px));
    grid-template-rows: repeat(2, 1fr);
    grid-auto-flow: column;
    gap: 22px 32px;
    border-top: 2px solid ${({ theme }) => theme.pages.profilePage.personalInformationForm.formSeparatorColor};
    margin-top: 40px;
    padding-top: 36px;

    & > div {
      margin: 0 !important;
      max-width: 100%;
    }

    @media ${theme.devices.mobile} {
      grid-template-rows: repeat(4, 1fr);
      border: none;
      margin-top: 0;
      padding-top: 0;
    }
  `}
`

const SecondFormAndButtonWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 32px;

    @media ${theme.devices.mobile} {
      flex-direction: column-reverse;
      gap: 46px;
    }

    button {
      align-self: start;
    }
  `}
`

type IPersonalInfoFormInputs = {
  email: string
  birthDate: Date
  idNumber: string
  phoneNumber: string
  Region: string
}

const PersonalInformationForm = () => {
  const { user } = useGetUser()
  const { isIntern } = useAuth()

  const [isEditing, setIsEditing] = useToggle(true)

  const { register, handleSubmit, control, reset } = useForm<IUser & { date: string }>({ defaultValues: user })

  const { mutate, isLoading } = useEditUser()

  useEffect(() => {
    const dateOfBirth =
      user?.eduIntern?.dateOfBirthUtc || user?.eduMentor?.dateOfBirthUtc || user?.eduTeamLead?.dateOfBirthUtc

    reset({ ...user, date: moment(dateOfBirth).format('YYYY-MM-DD') })
  }, [user, reset])

  const onSubmit: SubmitHandler<
    IUser & {
      date: string
    }
  > = (data) => {
    if (!user) return

    const { email, firstName, lastName, idNumber, phoneNumber } = data

    mutate(
      { userId: user.id, email, firstName, lastName, idNumber, phoneNumber },
      {
        onSuccess: () => {
          toast.success('User information updated', {
            style: { fontFamily: 'Poppins, sans-serif' },
            duration: 3000
          })
        },
        onError: () => {
          toast.error('Could not update user information', {
            style: { fontFamily: 'Poppins, sans-serif' },
            duration: 3000
          })
        }
      }
    )
  }

  const mentorName = user?.eduIntern?.eduMentor
    ? `${user?.eduIntern?.eduMentor?.firstName} ${user?.eduIntern?.eduMentor?.lastName}`
    : ''

  const teamLeadName = user?.eduIntern?.eduTeamLead
    ? `${user?.eduIntern?.eduTeamLead?.firstName} ${user?.eduIntern?.eduTeamLead?.lastName}`
    : ''

  const squadLeadName = user?.eduIntern?.eduSquadLead
    ? `${user?.eduIntern?.eduSquadLead.firstName} ${user?.eduIntern?.eduSquadLead.lastName}`
    : ''

  const projects = user?.eduIntern?.eduProjects ? user.eduIntern.eduProjects.map((p) => p.name).join(', ') : ''

  return (
    <PersonalInfoForm>
      <Title>Personal Information</Title>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormLayout>
          <Input
            backgroundColor={colorPalette.additional[4]}
            color={colorPalette.textAndIcons[7]}
            label="Email"
            name="email"
            id="email"
            type="email"
            disabled
            register={register}
          />
          <Input
            backgroundColor={colorPalette.additional[4]}
            label="Birth Date"
            name="date"
            id="date"
            type="date"
            register={register}
            disabled
            color={colorPalette.textAndIcons[7]}
          />
          <Input
            backgroundColor={colorPalette.additional[4]}
            color={colorPalette.grey.primary[100]}
            label="ID Number"
            name="idNumber"
            id="idNum"
            type="text"
            register={register}
          />
          <Input
            backgroundColor={colorPalette.additional[4]}
            color={colorPalette.grey.primary[100]}
            label="Phone Number"
            name="phoneNumber"
            id="phone"
            type="text"
            register={register}
          />
        </FormLayout>
        <SecondFormAndButtonWrapper>
          {isIntern ? (
            <SecondForm>
              <InputReadOnly label="Project" name="project" type="text" value={projects} />
              <InputReadOnly label="Mentor" name="mentor" type="text" value={mentorName} />
              <InputReadOnly label="Team Lead" name="teamLead" type="text" value={teamLeadName} />
              <InputReadOnly label="Squad Lead" name="squadLead" type="text" value={squadLeadName} />
            </SecondForm>
          ) : null}
        </SecondFormAndButtonWrapper>
        <Button type="submit" buttonType={'full'} padding={'8px 16px'} margin="32px 0px" disabled={isLoading}>
          Save Changes
        </Button>
      </form>
    </PersonalInfoForm>
  )
}

export default PersonalInformationForm
