import React from 'react'
import styled, { css } from 'styled-components'
import { ReactComponent as FacebookICon } from 'src/assets/images/icons/facebook.svg'
import { ReactComponent as InstagramIcon } from 'src/assets/images/icons/Instagram.svg'
import { ReactComponent as LinkedinIcon } from 'src/assets/images/icons/linkedin.svg'
import { ReactComponent as BackEndIcon } from 'src/assets/images/icons/icon-backend.svg'
import { ReactComponent as FrontEndIcon } from 'src/assets/images/icons/icon-frontend.svg'
import { ReactComponent as DataAndMarketingIcon } from 'src/assets/images/icons/icon-data-and-marketing.svg'
import { ReactComponent as MobileIcon } from 'src/assets/images/icons/icon-mobile.svg'
import { ReactComponent as NetworkingIcon } from 'src/assets/images/icons/icon-networking.svg'
import { ReactComponent as ProductDesignIcon } from 'src/assets/images/icons/icon-product-design.svg'
import { ReactComponent as TestingIcon } from 'src/assets/images/icons/icon-testing.svg'
import { ReactComponent as ProfileIcon } from 'src/assets/images/icons/icon-profile.svg'
import { ReactComponent as NotificationIcon } from 'src/assets/images/icons/icon-notification.svg'
import { ReactComponent as HamburgerMenu } from 'src/assets/images/icons/icon-hamburger-menu.svg'
import { ReactComponent as IconClose } from 'src/assets/images/icons/icon-close.svg'
import { ReactComponent as IconCheckmark } from 'src/assets/images/icons/icon-checkmark.svg'
import { ReactComponent as IconEyeSlash } from 'src/assets/images/icons/icon-eye-slash.svg'
import { ReactComponent as IconEye } from 'src/assets/images/icons/icon-eye.svg'
import { ReactComponent as IconWarning } from 'src/assets/images/icons/icon-warning.svg'
import { ReactComponent as SelectorArrow } from 'src/assets/images/icons/selector-arrow.svg'
import { ReactComponent as AccordionArrow } from 'src/assets/images/icons/accordion-arrow.svg'
import { ReactComponent as AccordionArrowSm } from 'src/assets/images/icons/accordion-arrow-small.svg'
import { ReactComponent as IconPencil } from 'src/assets/images/icons/icon-pencil.svg'
import { ReactComponent as IconRecycleBin } from 'src/assets/images/icons/icon-recycle-bin.svg'
import { ReactComponent as IconPlus } from 'src/assets/images/icons/icon-plus.svg'
import { ReactComponent as IconCalendarArrow } from 'src/assets/images/icons/icon-calendar-arrow.svg'
import { ReactComponent as IconTimer } from 'src/assets/images/icons/timer.svg'
import { ReactComponent as IconCross } from 'src/assets/images/icons/cross.svg'
import { ReactComponent as IconDownload } from 'src/assets/images/icons/icon-download.svg'
import { ReactComponent as IconThreeDots } from 'src/assets/images/icons/three-dots.svg'
import { ReactComponent as IconEmail } from 'src/assets/images/icons/email.svg'
import { ReactComponent as ChevronDown } from 'src/assets/images/icons/ChevronDown.svg'
import { ReactComponent as ChevronLeft } from 'src/assets/images/icons/ChevronLeft.svg'
import { ReactComponent as Camera } from 'src/assets/images/icons/camera.svg'
import { ReactComponent as Feedback } from 'src/assets/images/icons/icon-feedback.svg'
import { ReactComponent as DefaultProfile } from 'src/assets/images/icons/icon-default-profile.svg'
import { ReactComponent as InvertedProfile } from 'src/assets/images/icons/inverted-profile.svg'

interface IconContainerProps {
  size?: string
  color?: string
  onClick?: () => void
  cursorPointer?: boolean
  rotate?: string
}

const IconContainer = styled.div<IconContainerProps>`
  ${({ size, color, cursorPointer, rotate }) => css`
    &:hover {
      ${cursorPointer && 'cursor: pointer'}
    }

    svg {
      display: block;
      ${size &&
      css`
        width: ${size};
        height: ${size};
      `}
      ${rotate &&
      css`
        transform: rotate(${rotate}deg);
      `}

      
      path {
        ${color &&
        css`
          fill: ${color};
        `}
      }
    }
  `}
`

const IconVariants = {
  facebook: <FacebookICon />,
  instagram: <InstagramIcon />,
  linkedin: <LinkedinIcon />,
  backend: <BackEndIcon />,
  frontend: <FrontEndIcon />,
  dataAndMarketing: <DataAndMarketingIcon />,
  mobile: <MobileIcon />,
  networking: <NetworkingIcon />,
  productDesign: <ProductDesignIcon />,
  testing: <TestingIcon />,
  profile: <ProfileIcon />,
  notification: <NotificationIcon />,
  hamburgerMenu: <HamburgerMenu />,
  iconClose: <IconClose />,
  iconCheckmark: <IconCheckmark />,
  iconEyeSlash: <IconEyeSlash />,
  iconEye: <IconEye />,
  iconWarning: <IconWarning />,
  selectorArrow: <SelectorArrow />,
  accordionArrow: <AccordionArrow />,
  accordionArrowSm: <AccordionArrowSm />,
  iconPencil: <IconPencil />,
  iconRecycleBin: <IconRecycleBin />,
  iconPlus: <IconPlus />,
  iconCalendarArrow: <IconCalendarArrow />,
  iconTimer: <IconTimer />,
  iconCross: <IconCross />,
  iconDownload: <IconDownload />,
  iconThreeDots: <IconThreeDots />,
  iconEmail: <IconEmail />,
  chevron: <ChevronDown />,
  chevronLeft: <ChevronLeft />,
  camera: <Camera />,
  iconFeedback: <Feedback />,
  iconDefaultProfile: <DefaultProfile />,
  iconInvertedProfile: <InvertedProfile />
}

export type IconTypes = keyof typeof IconVariants

interface IconProps extends IconContainerProps {
  iconName: IconTypes
}

const Icon = ({ iconName, size, color, cursorPointer, rotate, ...otherProps }: IconProps) => {
  const icon = IconVariants[iconName]

  return (
    <IconContainer size={size} color={color} rotate={rotate} cursorPointer {...otherProps}>
      {icon}
    </IconContainer>
  )
}

export default Icon
