import React, { FC } from 'react'
import { useGetEvents } from 'src/hooks/queries/events/useGetEvents'
import { getDateEvents } from 'src/pages/events/Calendar/utils'
import Modal from '../common/Modal'
import styled from 'styled-components'
import moment from 'moment'
import EventOverview from '../events/EventOverview'

const Container = styled.div`
  ${({ theme }) => `
    font-family: ${theme.fonts.family.primary};
    width: 428px;
    height: 428px;
    overflow: scroll;
  `}
`

const EventDate = styled.p`
  color: #595959;
`

const EventWeekDay = styled.p`
  display: flex;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #170738;
  margin-bottom: 34px;
`

const OverviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

interface DayOverviewModalProps {
  date: Date | undefined
  onClose: () => void
  onClickEvent: (event: any) => void
  eventSelected: boolean
}

const DayOverviewModal: FC<DayOverviewModalProps> = ({ date, onClose, onClickEvent, eventSelected }) => {
  const { events } = useGetEvents()

  if (!date || !events) {
    return null
  }

  const filteredEvents = getDateEvents(date, events)

  if (!filteredEvents || eventSelected) {
    return null
  }

  return (
    <Modal destination={document.getElementById('root') as HTMLElement} padding="36px" onClick={onClose}>
      <Container>
        <EventWeekDay>
          {moment(date).format('dddd')}
          <EventDate>, {moment(date).format('D MMMM')}</EventDate>
        </EventWeekDay>
        <OverviewContainer>
          {filteredEvents.map((e) => (
            <EventOverview
              event={e}
              key={e.id}
              onClick={() => {
                onClickEvent(e)
              }}
            />
          ))}
        </OverviewContainer>
      </Container>
    </Modal>
  )
}

export default DayOverviewModal
