import styled, { css } from 'styled-components'
import { SwiperSlide } from 'swiper/react'

export const HeroSection = styled.div`
  ${({ theme }) => css`
    height: 100vh;
    color: ${theme.pages.landingPage.heroSection.textColor};
    position: relative;

    @media ${theme.devices.mobile} {
      min-height: 100%;
    }

    .swiper {
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 100;

      .swiper-slide {
        height: 100%;
      }

      .swiper-pagination {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: end;
        gap: 56px;
        bottom: 50%;
        left: auto; // This is needed to overwrite predefined left: 0
        right: 145px;
        transform: translateY(50%);
        width: 46px;

        @media ${theme.devices.laptop} {
          right: 80px;
        }

        @media ${theme.devices.tabletLandscape} {
          bottom: 10%;
          flex-direction: row;
          right: auto;
          width: 100%;
          transform: translateY(0);
          height: 46px;
        }

        @media ${theme.devices.mobile} {
          gap: 40px;
        }

        &-bullet {
          width: 17px;
          height: 17px;
          background-color: ${theme.pages.landingPage.heroSection.swiperPagination.square.color.primary};
          border-radius: 0;
          margin: 0;
          opacity: 1;
          flex-shrink: 0;

          @media ${theme.devices.tabletLandscape} {
            width: 16px;
            height: 16px;
          }

          &:nth-child(even) {
            align-self: start;
          }

          &-active {
            background-color: ${theme.pages.landingPage.heroSection.swiperPagination.square.color.active};
          }
        }
      }
    }
  `}
`

export const HeroContent = styled.div`
  margin-top: 20vh;
  grid-template-columns: 6fr 1fr;
  padding-bottom: 209px;
  max-width: 760px;

  @media ${({ theme }) => theme.devices.tabletLandscape} {
    grid-template-columns: 1fr;
    row-gap: 88px;
  }
`

interface ICustomSwiperStyleProps {
  imageurl: string
}

export const CustomSwiperStyle = styled(SwiperSlide)<ICustomSwiperStyleProps>`
  ${({ theme, imageurl }) => css`
    background-image: linear-gradient(
        to right,
        ${theme.pages.landingPage.heroSection.backgroundColor},
        ${theme.pages.landingPage.heroSection.backgroundColor}
      ),
      url(${imageurl});
  `}
  background-size: cover;
  background-position: center;
  padding-top: 41px;
`

export const HeroTitle = styled.h1`
  font-size: 60px;
  font-weight: 600;
  max-width: 760px;
  line-height: 9vh;
  text-transform: uppercase;

  @media screen and (max-height: 900px) {
    line-height: 11vh;
  }

  @media ${({ theme }) => theme.devices.tabletLandscape} {
    font-size: 44px;
    line-height: 8vh;
  }

  @media ${({ theme }) => theme.devices.tabletLandscape} and (max-height: 600px) {
    line-height: 15vh;
  }

  @media ${({ theme }) => theme.devices.mobile} {
    max-width: 284px;
    line-height: 8vh;
  }
`
export const HeroText = styled.p`
  font-size: 20px;
  max-width: 424px;
  margin-top: 24px;
  font-family: ${({ theme }) => theme.fonts.family.secondary};
  font-weight: 100;
  line-height: 4vh;

  @media ${({ theme }) => theme.devices.tabletLandscape} {
    font-size: 18px;
    margin-top: 4vh;
    line-height: 4vh;
  }

  @media ${({ theme }) => theme.devices.mobile} {
    max-width: 200px;
  }

  @media screen and (max-height: 600px) {
    line-height: 6vh;
  }
`
