import React from 'react'
import { useModal } from 'src/context/modalContext'
import Modal from '../common/Modal'
import AddEventForm from '../forms/add-event/AddEventForm'

const AddEventModal = () => {
  const { setModalType, setShowModal } = useModal()

  return (
    <Modal destination={document.getElementById('root') as HTMLElement} padding="30px 64px 69px 64px">
      <AddEventForm setModalType={setModalType} setShowModal={setShowModal} />
    </Modal>
  )
}

export default AddEventModal
