import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import Form from 'src/components/common/Form'
import Input from 'src/components/common/Input'
import Icon from 'src/components/icon/Icon'
import Button from 'src/components/common/buttons/Button'
import RouterLink from 'src/components/common/RouterLink'
import { useFormHook, yup } from 'src/hooks/custom'
import { useLogin } from 'src/hooks/mutations/user'
import { colorPalette } from 'src/themes/theme'

const FormOptions = styled.div`
  ${({ theme }) => css`
    display: flex;
    margin-top: 9px;
    justify-content: space-between;
    margin-bottom: 24px;
    font-size: ${theme.components.form.formOptions.fontSize};

    div:first-child {
      display: flex;
      align-items: center;
      gap: 9px;
      color: ${theme.components.form.formOptions.colors.primary};

      span {
        margin-right: 12px;
      }

      input {
        display: none;
      }
    }
  `}
`
type LablelProps = {
  checkboxChecked: boolean
}

const Label = styled.label<LablelProps>`
  ${({ checkboxChecked }) => css`
    width: 13px;
    height: 13px;
    border: 1px solid #c3c3c3;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    div {
      visibility: ${checkboxChecked ? 'visible' : 'hidden'};
    }
  `}
`

const ErrorText = styled.p`
  margin-bottom: 16px;
  font-size: 12px;
  line-height: 18px;
`

type ILogInFormInputs = {
  email: string
  password: string
}

const LogInSchema = yup.object().shape({
  email: yup.string().email('Enter a valid email').required('Email is required'),
  password: yup.string().required('Password is required')
})

const LogIn = () => {
  const [checkboxValue, setCheckboxValue] = useState(false)
  const { mutate, isLoading, isError } = useLogin()

  const { register, handleSubmit, errors } = useFormHook<ILogInFormInputs>(LogInSchema)

  const handleCheckboxClick = () => {
    setCheckboxValue((prev) => !prev)
  }

  const onSubmit = (data: ILogInFormInputs) => {
    mutate({ userName: data.email, password: data.password })
  }

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} title="Log in" titleMarginBottom="8px">
        <Input
          backgroundColor={colorPalette.additional[4]}
          register={register}
          error={errors.email?.message}
          type="email"
          label="Email"
          name="email"
          id="email"
        />
        <Input
          backgroundColor={colorPalette.additional[4]}
          register={register}
          error={errors.password?.message}
          type="password"
          label="Password"
          name="password"
          id="password"
        />
        <FormOptions>
          <div>
            <input name="rememberMe" type="checkbox" checked={checkboxValue} readOnly />
            <Label checkboxChecked={checkboxValue} onClick={handleCheckboxClick}>
              <Icon iconName="iconCheckmark" />
            </Label>
            <span>Remember me</span>
          </div>
          <div>
            <RouterLink to="forgot-password">Forgot password?</RouterLink>
          </div>
        </FormOptions>
        <>{isError && <ErrorText>Email or password is incorrect</ErrorText>}</>
        <Button buttonType="full" padding="9.5px 54px" disabled={isLoading}>
          Log In
        </Button>
      </Form>
    </>
  )
}

export default LogIn
