import { useState, useRef, useEffect } from 'react'

const useCalendarAPI = () => {
  const [calendarView, setCalendarView] = useState('dayGridMonth')
  const [month, setMonth] = useState(null)
  const calendarRef = useRef<any>(null)

  useEffect(() => {
    const API = getCalendarInstance()
    API && API.changeView(calendarView)
    API && setMonth(API.currentData.viewTitle)
  }, [calendarView])

  const getCalendarInstance = () => {
    const { current: calendarDom } = calendarRef
    const API = calendarDom ? calendarDom.getApi() : null
    return API
  }

  const aspectRatio = () => {
    return getCalendarInstance().setOption('aspectRatio', 1.35)
  }

  const goToNextMonth = () => {
    getCalendarInstance().next()
    setMonth(getCalendarInstance().currentData.viewTitle)
  }

  const goToPrevMonth = () => {
    getCalendarInstance().prev()
    setMonth(getCalendarInstance().currentData.viewTitle)
  }

  return {
    calendarView,
    setCalendarView,
    month,
    setMonth,
    calendarRef,
    goToNextMonth,
    goToPrevMonth,
    aspectRatio,
    getCalendarInstance
  }
}

export { useCalendarAPI }
