import React, { ChangeEventHandler, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useParams } from 'react-router'
import SyllabusAccordion from 'src/components/accordion-variants/syllabus-accordion/SyllabusAccordion'
import Column from 'src/components/common/Column'
import Row from 'src/components/common/Row'
import Icon from 'src/components/icon/Icon'
import { useAuth } from 'src/context/authContext'
import { useSyllabus } from 'src/context/syllabusContext'
import { useAddSubject } from 'src/hooks/mutations/syllabus'
import { useGetSyllabus } from 'src/hooks/queries/syllabus'
import styled, { css } from 'styled-components'

const WeekComponentWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 55px;
  }

  &:last-child {
    margin-bottom: 140px;
  }
`

const WeekText = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.pages.syllabusPage.week.text.fontSize.laptop};
    font-weight: bold;
    text-transform: uppercase;
    color: ${theme.pages.syllabusPage.week.text.color};
    // width: content-width; //NOTE: if these does not affect anything then delete

    @media ${theme.devices.mobileLandscape} {
      font-size: ${theme.pages.syllabusPage.week.text.fontSize.mobile};
    }
  `}
`

const WeekComponentInput = styled.input`
  border: none;
  background-color: transparent;
  font-size: 16px;
  font-weight: 500;
  color: #000;

  &:focus {
    outline: none;
  }

  &::-webkit-input-placeholder {
    font-size: 18px;
    font-weight: bold;
    color: #000;
  }
`

type ProgressBarType = {
  progress: string
}

const ProgressBar = styled.div<ProgressBarType>`
  ${({ theme, progress }) => css`
    position: relative;
    width: 100%;
    height: ${theme.pages.syllabusPage.week.progressBar.height.laptop};
    background-color: ${theme.pages.syllabusPage.week.progressBar.backgroundColor};
    border-radius: ${theme.borderRadius.secondary};
    overflow: hidden;

    @media ${theme.devices.mobileLandscape} {
      height: ${theme.pages.syllabusPage.week.progressBar.height.mobile};
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      /* width: ${progress}%; */
      width: 100%;
      height: 100%;
      background-color: ${theme.pages.syllabusPage.week.progressBar.progress.backgroundColor};
      border-radius: ${theme.borderRadius.secondary};
      z-index: 9999;
    }
  `}
`

interface IWeekProps {
  progress: string
  week: number
}

const Week = ({ progress, week }: IWeekProps) => {
  const { isIntern } = useAuth()

  const [title, setTitle] = useState('')
  const { id = '' } = useParams()

  const { activeMonth } = useSyllabus()

  const { data } = useGetSyllabus(id)
  const subjects = data?.eduSyllabus?.eduSubjects || []

  const { mutate, isLoading } = useAddSubject()

  const filteredSubs = subjects?.filter((sub: any) => sub?.eduMonth?.id === activeMonth && sub?.week === week)

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => setTitle(e.target.value)

  const handleAddSubject = () => {
    if (isLoading || !title) {
      return
    }
    mutate(
      { eduSyllabusId: id, monthId: activeMonth, name: title, week: week as 1 | 2 | 3 | 4, description: '<p></p> ' },
      {
        onSuccess: () => {
          setTitle('')
          toast.success('Subject successfully added', { style: { fontFamily: 'Poppins, sans-serif' } })
        }
      }
    )
  }

  return (
    <WeekComponentWrapper>
      <Row gap="35px" margin="0 0 48px 0" tabletLandscape={{ flexDirection: 'row', gap: '14px', margin: '0 0 33px 0' }}>
        <Column flexProp="0 0 auto">
          <WeekText>Week {week}</WeekText>
        </Column>
        <>
          {isIntern && (
            <Column width="100%">
              <ProgressBar progress={progress} />
            </Column>
          )}
        </>
      </Row>
      {!isIntern && (
        <Row margin="0 0 56px" justify="flex-start" gap="26px">
          <Column>
            <Icon onClick={handleAddSubject} iconName="iconPlus" cursorPointer />
          </Column>
          <Column>
            <WeekComponentInput value={title} type="text" placeholder="ADD SUBJECT" onChange={handleInputChange} />
          </Column>
        </Row>
      )}
      <SyllabusAccordion items={filteredSubs} />
    </WeekComponentWrapper>
  )
}

export default Week
