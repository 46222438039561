import React, { SelectHTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import { Controller } from 'react-hook-form'
import reactSelect, { components } from 'react-select'
import Icon from '../icon/Icon'
import { ErrorMessage } from './Input'

const SelectionComponent = styled.div`
  ${({ theme }) => css`
    label {
      font-size: ${theme.components.form.input.label.fontSize};
      color: ${theme.components.form.input.label.color};
    }
  `}
`

const SelectElementWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    border: 1px solid ${theme.components.form.input.valid.borderColor};
    border-radius: ${theme.borderRadius.primary};
    align-items: center;
    padding-right: '14px';
    margin-top: 10px;

    div {
      cursor: pointer;
    }
  `}
`

const ReactSelect = styled(reactSelect)`
  width: 100%;

  .css-13cymwt-control {
    border: none;
    background: transparent;
    font-size: 14px;
    box-shadow: none;
  }
`

export interface IOptions {
  label: string
  value: string
}

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  label?: string
  name: string
  options: IOptions[] | undefined
  control: any
  placeholder: string
  isValid?: boolean
  error?: string
  isMulti?: boolean
}

const style = {
  control: (base: any) => ({ ...base, border: 0, boxShadow: 'none', fontSize: '14px', padding: '0 14px' })
}

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon iconName="selectorArrow" />
    </components.DropdownIndicator>
  )
}

const Select = ({
  label,
  name,
  options,
  control,
  isMulti = false,
  placeholder,
  isValid = true,
  error,
  disabled = false
}: SelectProps) => {
  return (
    <SelectionComponent>
      <label>{label}</label>
      <SelectElementWrapper>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <ReactSelect
              styles={style}
              {...field}
              options={options}
              isMulti={isMulti}
              isDisabled={disabled}
              placeholder={placeholder}
              components={{ DropdownIndicator, IndicatorSeparator: () => null }} // IndicatorSeparator: () => null removes separator
            />
          )}
        />
      </SelectElementWrapper>
      <ErrorMessage inputValid={Boolean(isValid)}>
        <Icon iconName="iconWarning" />
        <span>{error}</span>
      </ErrorMessage>
    </SelectionComponent>
  )
}

export default Select
