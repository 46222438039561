import React from 'react'
import Column from 'src/components/common/Column'
import Row from 'src/components/common/Row'
import Section from 'src/components/common/Section'
import { ContactUsComponent, ContactInfo, ContactUsText } from './contact-us.styles'

const ContuctUs = () => {
  return (
    <ContactUsComponent>
      <Section>
        <Row tabletLandscape={{ gap: '61px', flexDirection: 'column' }}>
          <Column>
            <ContactUsText>
              <span>contact</span>
              <span>us</span>
            </ContactUsText>
          </Column>
          <Column>
            <ContactInfo>
              <p>Head Office</p>
              <p>13 Tamarashvili, Tbilisi, Georgia</p>
              <p>+995 558 90 02 91</p>
            </ContactInfo>
          </Column>
        </Row>
      </Section>
    </ContactUsComponent>
  )
}

export default ContuctUs
