import { axiosInstanceEdu } from 'src/components/interceptor'
import { IUser } from './user'

export type EventOwnerType = 'interns' | 'mentors' | 'teamleads' | ''
export interface AddEventPayload {
  name: string
  endDate: string
  startDate: string
  link: string
  comment: string
  userIds: string[]
}
export interface EditEventPayload extends AddEventPayload {
  id: string
}
export interface IEvent {
  comment: string
  endDateUtc: string
  id: string
  link: string
  name: string
  startDateUtc: string
  users: IUser[]
}

interface EventsResponse {
  events: IEvent[]
}

export const createEvent = async (params: Omit<AddEventPayload, 'id'>, type: EventOwnerType) => {
  const { data } = await axiosInstanceEdu.post(`/api/v1/Event/${type}`, params)

  return data
}

export const editEvent = async (params: EditEventPayload, type: EventOwnerType) => {
  const { data } = await axiosInstanceEdu.put(`/api/v1/Event/${type}`, params)

  return data
}

export const editGeneralEvent = async (params: EditEventPayload) => {
  const { id } = params
  const { data } = await axiosInstanceEdu.put(`/api/v1/Event/${id}`, params)

  return data
}

export const getEvents = async () => {
  const { data } = await axiosInstanceEdu.get<EventsResponse>('api/v1/Event', { params: { pageSize: '100' } })

  return data
}

export const getUserEvents = async () => {
  const token = localStorage.getItem('access_token')

  if (!token) {
    throw new Error('Unauthorized')
  }

  const { data } = await axiosInstanceEdu.get<EventsResponse>('api/v1/User/events', {
    headers: { Authorization: `Bearer ${token}` }
  })

  return data
}

export const getEvent = async (eventId: string) => {
  const { data } = await axiosInstanceEdu.get<{ event: IEvent }>(`api/v1/Event/${eventId}`)

  return data
}

export const deleteEvent = async (id: string) => {
  const { data } = await axiosInstanceEdu.delete(`/api/v1/Event/${id}`)

  return data
}
