import { produce } from 'immer'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { ISurveyConfigurationQuestion, ISurveySubmission, SurveyQuestionType } from 'src/api/services/survey'
import CheckBox from 'src/components/common/Checkbox'
import RadioGroup from 'src/components/common/RadioGroup'
import Icon from 'src/components/icon/Icon'
import { Answers, QuestionHeader, QuestionItem } from 'src/components/survey/survey.styles'
import { useDebounce } from 'src/hooks/custom'
import { useSubmitQuestionAnswer } from 'src/hooks/mutations/survey'
import useChangeQuestionAnswer from 'src/hooks/mutations/survey/useChangeQuestionAnswer'
import useUploadAnswerAttachment from 'src/hooks/mutations/useUploadAnswerAttachment'
import { SURVEY_SUBMISSION_ID_QUERY_KEY } from 'src/hooks/queries/survey/useGetSurveySubmissionId'
import { SURVEY_CONFIGURATION_QUESTION_QUERY_KEY } from 'src/hooks/queries/survey/useSurveyConfigurationQuestions'
import { isImage } from 'src/pages/events/Calendar/utils'
import styled from 'styled-components'

const RATING_ANSWERS = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']

const AttachmentImage = styled.img`
  display: block;
  width: 66%;
`

const AttachmentLink = styled.a`
  text-decoration: none;
  color: red;
`

const AttachmentButton = styled.button`
  ${({ theme }) => `background-color: #fbfcff;
  border: 1px solid #f0e7ff;
  padding: 20px;
  display: flex;
  gap: 6px;
  align-items: center;
  border-radius: 4px;
  font-family: ${theme.fonts.family.primary};
  font-weight: 600;
  
  &:hover {
    cursor: pointer;
  }
  `}
`

const UploadContainer = styled.div`
  ${({ theme }) => `
  margin-top: 32px;
  font-family: ${theme.fonts.family.primary};
  display: flex;
  align-items: center;
  gap: 16px;
  `}
`

interface SurveyQuestionProps {
  question: ISurveyConfigurationQuestion
  index: number
  submission: ISurveySubmission
  setIsAnswering: (val: boolean) => void
  showTitle?: boolean
  configId: string
  disabled?: boolean
}

const SurveyQuestion: FC<SurveyQuestionProps> = ({
  question,
  index,
  submission,
  setIsAnswering,
  showTitle = true,
  configId,
  disabled = false
}) => {
  // STATE
  const [answer, setAnswer] = useState<string | string[]>(
    question.questionType === SurveyQuestionType.MultipleChoice ? [] : ''
  )
  const [showEditAttachment, setShowEditAttachment] = useState(true)

  // HOOKS

  const debouncedAnswer = useDebounce(answer, 500)

  const { mutate: submitAnswer } = useSubmitQuestionAnswer()
  const { mutate: changeAnswer } = useChangeQuestionAnswer()
  const { mutate: uploadFile } = useUploadAnswerAttachment()

  const queryClient = useQueryClient()

  const saveAnswer = useCallback(() => {
    if (debouncedAnswer.length === 0) {
      return
    }
    const questionInSubmission = submission.surveyQuestionSubmissions.find((s) => s.questionId === question.id)

    if (questionInSubmission) {
      if (questionInSubmission.answer === answer || questionInSubmission.answerIds === answer) {
        return
      }

      setIsAnswering(true)
      changeAnswer(
        {
          submissionId: submission.id,
          questionId: question.id,
          ...(question.questionType === SurveyQuestionType.MultipleChoice
            ? { answerIds: answer as string[] }
            : { answer: answer as string })
        },
        {
          onSettled: () => {
            setIsAnswering(false)
            queryClient.invalidateQueries([SURVEY_SUBMISSION_ID_QUERY_KEY, configId])
          }
        }
      )

      return
    }
    setIsAnswering(true)

    submitAnswer(
      {
        submissionId: submission.id,
        questionId: question.id,
        ...(question.questionType === SurveyQuestionType.MultipleChoice
          ? { answerIds: answer as string[] }
          : { answer: answer as string })
      },
      {
        onSettled: () => {
          setIsAnswering(false)
          queryClient.invalidateQueries([SURVEY_SUBMISSION_ID_QUERY_KEY, configId])
          queryClient.invalidateQueries([SURVEY_CONFIGURATION_QUESTION_QUERY_KEY, configId])
        }
      }
    )
  }, [answer, changeAnswer, debouncedAnswer, question, setIsAnswering, submission, submitAnswer, configId, queryClient])

  // EFFECTS

  useEffect(() => {
    setAnswer(question.questionType === SurveyQuestionType.MultipleChoice ? [] : '')
    setShowEditAttachment(!question?.submission?.attachment)
  }, [question])

  useEffect(() => {
    const questionInSubmission = submission.surveyQuestionSubmissions.find((s) => s.questionId === question.id)

    if (!questionInSubmission) {
      return
    }

    setAnswer(questionInSubmission.answer || questionInSubmission.answerIds)
  }, [question, submission])

  useEffect(() => {
    saveAnswer()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedAnswer])

  // FUNCTIONS

  const handleChangeAnswer = (value: string) => {
    if (question.questionType === SurveyQuestionType.MultipleChoice) {
      const updatedAnswers = produce(answer, (draft) => {
        const answerIndex = draft.indexOf(value)

        if (!Array.isArray(draft)) {
          throw new Error('State is of type string')
        }

        if (answerIndex !== -1) {
          draft.splice(answerIndex, 1)
        } else {
          draft.push(value)
        }
      })

      setAnswer(updatedAnswers)

      return
    }

    setAnswer(value)
  }

  const renderQuestion = () => {
    switch (question.questionType) {
      case SurveyQuestionType.MultipleChoice:
        return (
          <Answers>
            {question.answers.map((a) => (
              <CheckBox
                key={a.id}
                checked={answer.includes(a.id)}
                onChange={() => handleChangeAnswer(a.id)}
                value={a.value}
                disabled={disabled}
              />
            ))}
          </Answers>
        )
      case SurveyQuestionType.OpenEnded:
        return (
          <textarea
            placeholder="Type your answer"
            value={answer}
            onChange={(e) => handleChangeAnswer(e.target.value)}
            disabled={disabled}
          />
        )
      case SurveyQuestionType.Rating:
        return (
          <RadioGroup
            options={Array.from({ length: question.score }, (_, i) => (i + 1).toString())}
            value={answer as string}
            onChange={(e) => !disabled && setAnswer(e)}
          />
        )
    }
  }

  const handleFileSelect: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!e.target.files) return

    setIsAnswering(true)
    uploadFile(
      { submissionId: submission.id, questionId: question.id, attachment: e.target.files[0] },
      {
        onSettled: () => {
          setIsAnswering(false)
          queryClient.invalidateQueries([SURVEY_SUBMISSION_ID_QUERY_KEY, configId])
          queryClient.invalidateQueries([SURVEY_CONFIGURATION_QUESTION_QUERY_KEY, configId])
        }
      }
    )
  }

  const renderUploadAttachment = () => {
    return question?.submission?.attachment && !showEditAttachment ? (
      <>
        <a href={question.submission.attachment.downloadUrl} target="_blank" rel="noreferrer">
          Download
        </a>
        <Icon
          iconName={'iconPencil'}
          color="black"
          size={'14px'}
          onClick={() => !disabled && setShowEditAttachment(true)}
        />
      </>
    ) : (
      <input type="file" onChange={handleFileSelect} disabled={disabled} />
    )
  }

  return (
    <QuestionItem>
      {showTitle ? <QuestionHeader>{`${index + 1}. ${question.text}`}</QuestionHeader> : null}
      {question.attachment ? (
        isImage(question.attachment.extension) ? (
          <AttachmentImage src={question.attachment.downloadUrl} />
        ) : (
          <AttachmentLink rel="noreferer" href={question.attachment.downloadUrl} target="_blank">
            <AttachmentButton type="button">
              <p>Download attachment</p>
              <Icon iconName="iconDownload" />
            </AttachmentButton>
          </AttachmentLink>
        )
      ) : null}
      {renderQuestion()}
      {question.questionType === SurveyQuestionType.OpenEnded && (
        <UploadContainer>
          <p>Attachment: </p>
          {renderUploadAttachment()}
        </UploadContainer>
      )}
    </QuestionItem>
  )
}

export default SurveyQuestion
