import React from 'react'
import MediaQuery from 'react-responsive'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import Column from 'src/components/common/Column'
import Row from 'src/components/common/Row'
import Icon from 'src/components/icon/Icon'
import { useBurgerMenu } from 'src/context/burgerMenuContext'
import styled, { css } from 'styled-components'
import NotificationBell from './NotificationBell'
import { useGetUser } from 'src/hooks/queries/user'
import { colorPalette } from 'src/themes/theme'

import Logo from '../logo.svg'
import { useAuth } from 'src/context/authContext'
import ProfileLink from './ProfileLink'

const NavigationItem = styled(NavLink)`
  ${({ theme }) => css`
    text-decoration: none;
    padding-bottom: 4px;

    &:link,
    &:visited {
      color: ${theme.layout.header.color};
      font-size: ${theme.layout.header.authorizedUserPanel.fontSize};
      font-weight: 400;
      opacity: 1;
    }

    &:hover,
    &.active {
      cursor: pointer;
      border-bottom-color: ${colorPalette.pink.primary[60]};
      border-bottom-width: 2px;
      border-bottom-style: solid;
    }
  }
  `}
`

const HumburgerMenu = styled.div`
  &:hover {
    cursor: pointer;
  }
`

const AuthorizedUserPanel = () => {
  const { setIsOpen } = useBurgerMenu()
  const { isIntern } = useAuth()
  const navigate = useNavigate()

  const { user } = useGetUser()
  const stackId = user?.eduIntern?.eduStack.id || ''

  const clickHandler = () => {
    setIsOpen(true)
  }

  const handleLogoClick = () => {
    isIntern ? navigate('/pages/analytics') : navigate('/')
  }

  return (
    <>
      <MediaQuery maxWidth={890}>
        <Row>
          <Column>
            <img src={Logo} onClick={handleLogoClick} style={{ width: 150, height: '100%' }} />
          </Column>
          <HumburgerMenu onClick={clickHandler}>
            <Icon iconName="hamburgerMenu" />
          </HumburgerMenu>
        </Row>
      </MediaQuery>
      <MediaQuery minWidth={891}>
        <Row gap="45px" tabletLandscape={{ gap: '30px' }}>
          <Column flex flexProp="1">
            <img src={Logo} onClick={handleLogoClick} style={{ width: 150, height: '100%' }} />
          </Column>
          <Column flex flexProp="1">
            <Row gap="24px" tabletLandscape={{ gap: '24px' }}>
              {isIntern ? (
                <Column>
                  <NavigationItem to={`pages/overview/${stackId}`}>Syllabus</NavigationItem>
                </Column>
              ) : (
                <Column>
                  <NavigationItem to="pages/syllabus">Syllabus</NavigationItem>
                </Column>
              )}

              <Column>
                <NavigationItem to="pages/events">Events</NavigationItem>
              </Column>
              <Column>
                <NavigationItem to="pages/exams">Exams</NavigationItem>
              </Column>
              <Column>
                <NavigationItem to="pages/surveys">Surveys</NavigationItem>
              </Column>
            </Row>
          </Column>
          <Column flex flexProp="1">
            <Row justify="flex-end" gap="32px">
              <NotificationBell />
              <ProfileLink />
            </Row>
          </Column>
        </Row>
      </MediaQuery>
    </>
  )
}

export default AuthorizedUserPanel
