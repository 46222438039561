import { useQuery } from 'react-query'
import { getSurveyConfigurationQuestions } from 'src/api/services/survey'

export const SURVEY_CONFIGURATION_QUESTION_QUERY_KEY = 'SURVEY_CONFIGURATION_QUESTIONS'

const useSurveyConfigurationQuestions = (configId: string, surveySubmissionId: string) => {
  return useQuery({
    queryKey: [SURVEY_CONFIGURATION_QUESTION_QUERY_KEY, configId],
    queryFn: () => getSurveyConfigurationQuestions(configId),
    enabled: !!surveySubmissionId
  })
}

export default useSurveyConfigurationQuestions
