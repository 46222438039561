import styled, { css } from 'styled-components'
import React from 'react'

interface IAccordionOpenProp {
  isOpen: boolean
}

interface IAccordionDynamicHeightProp extends IAccordionOpenProp {
  height: string
}

const AccordionBodyComponent = styled.div<IAccordionDynamicHeightProp>`
  ${({ theme, isOpen, height }) => css`
    box-sizing: border-box;
    height: ${isOpen ? height : '0px'};
    overflow: hidden;
    transition: height 0.3s;
  `}
`

interface IAccordionContentWrapper {
  admin: boolean
}

const AccordionContentWrapper = styled.div<IAccordionContentWrapper>`
  ${({ theme, admin }) => css`
    padding: 49px 68px;
    background-color: ${theme.components.accordion.body.contentWrapper.backgroundColor};
    border-radius: ${theme.borderRadius.accordion.body};
    display: flex;
    flex-direction: column;

    button {
      align-self: ${admin ? 'flex-end' : 'flex-start'};
    }

    @media ${theme.devices.mobileLandscape} {
      padding: 36px 24px 28px;

      button {
        align-self: flex-end;
      }
    }
  `}
`

interface IAccordionGenericBodyProps {
  children: JSX.Element | JSX.Element[]
  isOpen: boolean
  noWrapper?: boolean
}

const AccordionGenericBody = ({ children, isOpen, noWrapper = false }: IAccordionGenericBodyProps) => {
  const accordionBodyRef = React.useRef<HTMLDivElement>(null)
  const [height, setHeight] = React.useState<string>('0px')

  React.useEffect(() => {
    const accordionHeight = accordionBodyRef?.current?.scrollHeight
    accordionHeight && setHeight(accordionHeight + 'px')
  }, [])

  return (
    <AccordionBodyComponent ref={accordionBodyRef} height={height} isOpen={isOpen}>
      {noWrapper ? <>{children}</> : <AccordionContentWrapper admin={false}>{children}</AccordionContentWrapper>}
    </AccordionBodyComponent>
  )
}

export default AccordionGenericBody
