import React from 'react'
import styled, { css } from 'styled-components'
import Row from 'src/components/common/Row'
import Column from 'src/components/common/Column'
import Icon from 'src/components/icon/Icon'
import MediaQuery from 'react-responsive'
import { useSyllabus } from 'src/context/syllabusContext'
import { useDeleteSubject } from 'src/hooks/mutations/syllabus'

interface IAccordionOpenProp {
  isOpen: boolean
}

interface ISyllabusAccordionHeaderProp extends IAccordionOpenProp {
  backgroundColor: string
}

const SyllabusAccordionHeaderComponent = styled.div<ISyllabusAccordionHeaderProp>`
  ${({ theme, isOpen, backgroundColor }) => css`
    padding: 30px 44px;
    padding-bottom: 14px;
    // background-color: ${backgroundColor};
    background-color: #2a647b;
    color: ${theme.components.accordion.color};
    border-radius: ${isOpen ? theme.borderRadius.accordion.header.open : theme.borderRadius.primary};
    transition: border-radius 0.3s;

    @media ${theme.devices.mobileLandscape} {
      padding: 16px 20px;
    }

    &:hover {
      cursor: pointer;
    }
  `}
`

const SyllabusAccordionHeaderTitle = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.components.accordion.header.title.fontSizes.laptop};
    font-weight: 600;
    // color: ${theme.components.accordion.header.color};

    @media ${theme.devices.mobileLandscape} {
      font-size: ${theme.components.accordion.header.title.fontSizes.mobile};
    }
  `}
`

const SyllabusAccordionHeaderProgress = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.components.accordion.header.progressStatus.fontSizes.laptop};
    font-weight: 500;
    // color: #ffffff;

    @media ${theme.devices.mobileLandscape} {
      font-size: ${theme.components.accordion.header.progressStatus.fontSizes.mobile};
    }
  `}
`

const IconWrapper = styled.div<IAccordionOpenProp>`
  ${({ isOpen }) => css`
    transform: rotate(${isOpen ? '-180deg' : '0deg'});
    transition: transform 0.3s;
  `}
`

type ProgressBarType = {
  progress: string
}

const ProgressBar = styled.div<ProgressBarType>`
  ${({ theme, progress }) => css`
    position: relative;
    width: 100%;
    height: ${theme.components.accordion.header.progressBar.height};
    background-color: ${theme.components.accordion.header.progressBar.backgroundColor};
    border-radius: ${theme.borderRadius.secondary};
    overflow: hidden;
    display: none;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      /* width: ${progress}%; */
      width: 100%;
      height: 100%;
      background-color: ${theme.components.accordion.header.progressBar.progress.backgroundColor};
      border-radius: ${theme.borderRadius.secondary};
      z-index: 9999;
    }
  `}
`

interface ISyllabusAccordionHeader {
  id: string
  isOpen: boolean
  index?: string
  title: string
  progress: string
  backgroundColor: string
  onClick?: (index: string) => void
  admin: boolean
}

const SyllabusAccordionHeader = ({ id, title, progress, backgroundColor, isOpen, admin }: ISyllabusAccordionHeader) => {
  const { mutate } = useDeleteSubject()
  const { setSyllabusData, setActiveSubjectId } = useSyllabus()

  const handleSubjectDelete = (e: React.MouseEvent<Element, MouseEvent>, id: string) => {
    e.stopPropagation()
    setSyllabusData([])
    mutate(id, { onSuccess: () => setSyllabusData([]) })
  }

  const editSubject = (id: string) => {
    setActiveSubjectId(id)
  }

  const laptop = {
    internPanel: (
      <Column>{/* <SyllabusAccordionHeaderProgress>{progress} / 100%</SyllabusAccordionHeaderProgress> */}</Column>
    ),
    adminPanel: (
      <Column flex gap="28px">
        <div onClick={() => editSubject(id)}>
          <Icon iconName="iconPencil" />
        </div>
        <div onClick={(event) => handleSubjectDelete(event, id)}>
          <Icon iconName="iconRecycleBin" />
        </div>
      </Column>
    )
  }

  const mobile = {
    internPanel: (
      <Column flex justify="flex-end">
        <SyllabusAccordionHeaderProgress>{progress} / 100%</SyllabusAccordionHeaderProgress>
      </Column>
    ),
    adminPanel: (
      <Column flex gap="10px" justify="flex-end">
        <div onClick={() => editSubject(id)}>
          <Icon size="14px" iconName="iconPencil" />
        </div>
        <div onClick={(event) => handleSubjectDelete(event, id)}>
          <Icon size="14px" iconName="iconRecycleBin" />
        </div>
      </Column>
    )
  }

  const bigDeviceHeader = (
    <SyllabusAccordionHeaderComponent isOpen={isOpen} backgroundColor={backgroundColor}>
      <Row flexDirection="column" gap="12px">
        <Row>
          <Column>
            <Row gap="22px">
              <Column>
                <SyllabusAccordionHeaderTitle>{title}</SyllabusAccordionHeaderTitle>
              </Column>
              <Column>
                <IconWrapper isOpen={isOpen}>
                  <Icon iconName="accordionArrow" />
                </IconWrapper>
              </Column>
            </Row>
          </Column>
          {!admin ? laptop.internPanel : laptop.adminPanel}
        </Row>
        <>
          {!admin && (
            <Row alignItems="stretch">
              <Column width="100%">
                <ProgressBar progress={progress} />
              </Column>
            </Row>
          )}
        </>
      </Row>
    </SyllabusAccordionHeaderComponent>
  )

  const smallDeviceHeader = (
    <SyllabusAccordionHeaderComponent isOpen={isOpen} backgroundColor={backgroundColor}>
      <Row flexDirection="column" gap="12px">
        <Row gap="10px">
          <Column>
            <SyllabusAccordionHeaderTitle>{title}</SyllabusAccordionHeaderTitle>
          </Column>
          <Column>
            <IconWrapper isOpen={isOpen}>
              <Icon iconName="accordionArrowSm" />
            </IconWrapper>
          </Column>
        </Row>
        <Row alignItems="stretch" flexDirection="column" gap="16px">
          <>
            {!admin && (
              <Column width="100%">
                <ProgressBar progress={progress} />
              </Column>
            )}
          </>
          {!admin ? mobile.internPanel : mobile.adminPanel}
        </Row>
      </Row>
    </SyllabusAccordionHeaderComponent>
  )

  return (
    <>
      <MediaQuery maxWidth={720}>{smallDeviceHeader}</MediaQuery>
      <MediaQuery minWidth={721}>{bigDeviceHeader}</MediaQuery>
    </>
  )
}

export default SyllabusAccordionHeader
