import { useQuery } from 'react-query'
import { QUERY_KEYS } from 'src/enums'
import { useAuth } from 'src/context/authContext'
import { PageProps, getStacks } from 'src/api/services/stack'

export const useStacks = (props: PageProps) => {
  const { isLoggedIn } = useAuth()

  return useQuery([QUERY_KEYS.STACKS, props], () => getStacks(props), { enabled: isLoggedIn })
}
