export const data = [
  {
    title: 'Practical Experience',
    list: [
      'Working on real digital products',
      'Solving authentic business cases',
      'Practice sharing from top-notch professionals'
    ]
  },
  {
    title: 'Hard Skills',
    list: [
      'Software Development processes and methodologies',
      'Source Control Management',
      'Scrum methodologies and more'
    ]
  },
  {
    title: 'Soft Skills',
    list: ['Effective Communication', 'Time management', 'Team work and etc.']
  },
  {
    title: 'Theoretical Knowledge',
    list: [
      'Structured syllabus with online resources',
      'Monthly progress tracking',
      'One-on-one knowledge sharing by mentor'
    ]
  },
  {
    title: 'Self Presentation',
    list: ['Interview analysis', 'Peer-to-peer presentations', 'Job Interview simulations']
  },
  {
    title: 'Social Engagement',
    list: [
      'Teaming-up with highly qualified professionals',
      'Interaction with local and international gurus',
      'Diverse social and team-building activities'
    ]
  }
]
