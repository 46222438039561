import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'
import Section from 'src/components/common/Section'
import SectionHeadingComp from 'src/components/common/SectionHeading'
import { useAuth } from 'src/context/authContext'
import SyllabusProvider from 'src/context/syllabusContext'
import { useGetUser } from 'src/hooks/queries/user'
import styled from 'styled-components'
import SyllabusList from './components/SyllabusList'
import { getSyllabusByStackId } from 'src/api/services/syllabus'

const TableTitle = styled.p`
  font-weight: bold;
  margin: 32px 0px 16px;
  font-size: 18px;
`

const SyllabusPageWrapper = styled.div`
  padding: 70px 0;
  color: ${({ theme }) => theme.colors.primary};
  min-height: 100vh;
`

const SyllabusListPage = () => {
  const { isAdmin } = useAuth()
  const navigate = useNavigate()

  const { user } = useGetUser()

  useEffect(() => {
    const checkStackSyllabus = async () => {
      const stackId = user?.eduIntern?.eduStack.id || user?.eduMentor?.eduStack.id || user?.eduTeamLead?.eduStack.id
      if (!stackId) {
        return
      }
      const stackSyllabus = await getSyllabusByStackId(stackId)

      if (!stackSyllabus.eduSyllabus.id) {
        return
      }

      navigate(`/pages/syllabus/${stackSyllabus.eduSyllabus.id}`)
    }

    checkStackSyllabus()
  }, [navigate, user])

  if (!isAdmin) {
    return <SyllabusPageWrapper />
  }

  return (
    <SyllabusPageWrapper>
      <Section>
        <SectionHeadingComp>Syllabus</SectionHeadingComp>
        <div>
          {isAdmin && (
            <>
              <TableTitle>View Syllabus</TableTitle>
              <SyllabusList />
              <TableTitle>Create Syllabus for Stacks</TableTitle>
              <SyllabusList toCreate={true} />
            </>
          )}
        </div>
      </Section>
    </SyllabusPageWrapper>
  )
}

export default SyllabusListPage
