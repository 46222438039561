import React, { FC } from 'react'
import styled from 'styled-components'

import { ISurveyConfiguration, ISurveySubmission } from 'src/api/services/survey'
import Confetti from 'src/assets/images/confetti.png'
import Frown from 'src/assets/images/frown.png'

const Container = styled.div`
  padding-top: 114px;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
`

const SuccessImage = styled.img`
  width: 33%;
  margin-bottom: 40px;
`

const FailImage = styled.img`
  margin-bottom: 40px;
  width: 74px;
`

const Title = styled.h1`
  color: #170738;
  font-size: 26px;
  line-height: 29px;
  padding-bottom: 24px;
`

const InfoText = styled.p`
  display: flex;
  gap: 16px;
  color: #a8a8a8;
  font-size: 18px;
  line-height: 29px;
  padding-bottom: 16px;
`

const Score = styled.p<{ passed: boolean }>`
  ${({ passed }) => `
  color: ${passed ? '#170738' : '#F14F48'};
  font-weight: 700;
  `}
`

interface FullyRatedProps {
  submission: ISurveySubmission
  configuration: ISurveyConfiguration
}

const FullyRated: FC<FullyRatedProps> = ({ submission, configuration }) => {
  const { totalScore: userScore } = submission
  const { totalScore, passingScore } = configuration
  return (
    <Container>
      {userScore >= passingScore ? <SuccessImage src={Confetti} /> : <FailImage src={Frown} />}
      <Title>{userScore >= passingScore ? 'Congrats!' : 'Failed'}</Title>
      <InfoText>
        {userScore >= passingScore ? 'You passed the exam successfully' : 'Unfortunately, you did not pass the exam'}
      </InfoText>
      <InfoText>
        Your score:
        <Score passed={userScore >= passingScore}>
          {userScore}/{totalScore}
        </Score>
      </InfoText>
    </Container>
  )
}

export default FullyRated
