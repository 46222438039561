import { AxiosError } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { EditEventPayload, editGeneralEvent } from 'src/api/services/events'
import { QUERY_KEYS } from 'src/enums'

export const useEditEvent = () => {
  const client = useQueryClient()

  const { mutate, isLoading, isError } = useMutation<void, AxiosError, { payload: EditEventPayload }>(
    ({ payload }) => editGeneralEvent(payload),
    {
      onSuccess: () => client.invalidateQueries([QUERY_KEYS.EVENT])
    }
  )

  return { mutate, isLoading, isError }
}
