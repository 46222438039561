import React from 'react'
import Modal from 'src/components/common/Modal'
import { useNavigate } from 'react-router'
import Button from 'src/components/common/buttons/Button'
import styled, { css } from 'styled-components'
import { useAuth } from 'src/context/authContext'
import { useModal } from 'src/context/modalContext'

const ButtonContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 10px;

    @media ${theme.devices.tablet} {
      flex-direction: column;
      gap: 16px;
    }
  `}
`

const ModalText = styled.p`
  ${({ theme }) => css`
    text-align: center;
    margin-bottom: 45px;
    font-size: ${theme.components.modal.window.logOutModal.fontSize};
    font-weight: 500;
    color: ${theme.components.modal.window.logOutModal.color};
    font-family: ${({ theme }) => theme.fonts.family.primary};
  `}
`

const LogOutModal = () => {
  const { logOut } = useAuth()
  const navigate = useNavigate()
  const { setShowModal } = useModal()

  const handleLogOut = () => {
    setShowModal(false)
    logOut()
    navigate('/')
  }

  return (
    <Modal
      padding="65px 105px 90px"
      tablet={{ padding: '54px 44px 64px' }}
      destination={document.getElementById('root') as HTMLElement}>
      <ModalText>Are you sure you want to log out?</ModalText>
      <ButtonContainer>
        <Button buttonType="full" onClick={handleLogOut} padding="9.5px 62.5px">
          Yes
        </Button>
        <Button buttonType="full" onClick={() => setShowModal(false)} padding="9.5px 65px">
          No
        </Button>
      </ButtonContainer>
    </Modal>
  )
}

export default LogOutModal
