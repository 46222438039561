import React, { createContext, useState, useContext } from 'react'

export type BurgerMenuContextProps = {
  isOpen: boolean
  setIsOpen: (arg: boolean) => void
}

const BurgerMenuContext = createContext<BurgerMenuContextProps | null>(null)
interface Props {
  children: React.ReactNode
}

const BurgerMenuProvider = ({ children }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const value = {
    isOpen,
    setIsOpen
  }

  return <BurgerMenuContext.Provider value={value}>{children}</BurgerMenuContext.Provider>
}

export default BurgerMenuProvider

export const useBurgerMenu = () => {
  const value = useContext(BurgerMenuContext)

  if (!value) {
    throw new Error('BurgerMenu Context Provider is not defined')
  }
  return value
}
