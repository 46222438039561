import React from 'react'
import { colorPalette } from 'src/themes/theme'
import styled from 'styled-components'

interface CheckBoxProps {
  value: string
  checked: boolean
  onChange: (val: boolean) => void
  disabled?: boolean
}

const CustomCheckbox = styled.div`
  display: flex;
  gap: 16px;

  .container {
    cursor: pointer;
    position: relative;
    padding-left: 35px;
  }

  .container input {
    position: absolute;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    border: 2px solid ${colorPalette.pink.primary[60]};
    border-radius: 4px;
  }

  .container input:checked ~ .checkmark {
    background-color: ${colorPalette.pink.primary[60]};
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  .container .checkmark:after {
    top: 2px;
    left: 6px;
    width: 5px;
    height: 10px;
    border: solid #fff;
    transform: rotate(45deg);
    border-width: 0 2px 2px 0;
  }

  p {
    margin-top: 2px !important;
  }
`

const CheckBox = ({ value, checked, onChange, disabled = false }: CheckBoxProps) => {
  return (
    <CustomCheckbox>
      <label className="container">
        <input type="checkbox" checked={checked} onChange={(e) => onChange(e.target.checked)} disabled={disabled} />
        <span className="checkmark" />
        <p>{value}</p>
      </label>
    </CustomCheckbox>
  )
}

export default CheckBox
