import React from 'react'
import { useModal } from 'src/context/modalContext'
import Modal from '../common/Modal'

const EventDetailsModal = () => {
  return (
    <Modal destination={document.getElementById('root') as HTMLElement} padding="30px 64px 69px 64px">
      <div>Event Details</div>
    </Modal>
  )
}

export default EventDetailsModal
