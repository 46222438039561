import { AxiosError } from 'axios'
import { QUERY_KEYS } from 'src/enums'
import { useMutation, useQueryClient } from 'react-query'
import { CreateSyllabusPayload, createSyllabus } from 'src/api/services/syllabus'

export const useCreateSyllabus = () => {
  const client = useQueryClient()

  const { mutate, isLoading, isError } = useMutation<{ success?: boolean }, AxiosError, CreateSyllabusPayload>(
    createSyllabus,
    { onSuccess: () => client.invalidateQueries([QUERY_KEYS.ALL_SYLLABUS]) }
  )

  return { mutate, isLoading, isError }
}
