import React, { useState, createContext, useContext } from 'react'
import { ModalType } from 'src/components/common/ModalVariants'

export interface IModalProps {
  showModal: boolean
  setShowModal: (arg: boolean) => void
  modalType: ModalType
  setModalType: (arg: ModalType) => void
}

const ModalContext = createContext<IModalProps | null>(null)

interface Props {
  children: React.ReactNode
}

const ModalContextProvider = ({ children }: Props) => {
  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState<ModalType>('logoutModal')

  const value = {
    showModal,
    setShowModal,
    modalType,
    setModalType
  }

  return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
}

export default ModalContextProvider

export const useModal = () => {
  const value = useContext(ModalContext)

  if (!value) {
    throw new Error('Modal Context Provider is not defined')
  }
  return value
}
