import { USER_ROLES } from 'src/enums'
import React, { useState, ReactNode, FC, createContext, useContext } from 'react'
interface Props {
  children: ReactNode
}
export interface AuthContextProps {
  userRole: string
  isIntern: boolean
  isAdmin: boolean
  isLoggedIn: boolean
  logOut: () => void
  setToken: (token: string) => void
  setUserRole: (role: string) => void
}

const AuthContext = createContext<AuthContextProps | null>(null)

const AuthContextProvider = ({ children }: Props) => {
  const [token, setToken] = useState(localStorage.getItem('access_token'))
  const [userRole, setUserRole] = useState(localStorage.getItem('role') || '')

  const isIntern = userRole === USER_ROLES.STUDENT
  const isAdmin = userRole === USER_ROLES.SUPER_ADMIN || userRole === USER_ROLES.ADMIN

  const logOut = () => {
    setToken('')
    setUserRole('')
    localStorage.removeItem('role')
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
  }

  const value = {
    logOut,
    isAdmin,
    userRole,
    isIntern,
    setToken,
    setUserRole,
    isLoggedIn: Boolean(token)
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export default AuthContextProvider

export const useAuth = () => {
  const value = useContext(AuthContext)

  if (!value) {
    throw new Error('Auth Context Provider is not defined')
  }

  return value
}
