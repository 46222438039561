import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import Form from 'src/components/common/Form'
import Input from 'src/components/common/Input'
import Row from 'src/components/common/Row'
import Column from 'src/components/common/Column'
import Button from 'src/components/common/buttons/Button'
import { WarningText } from '../profile/ChangePasswordForm'
import { ConfirmMessage } from './ForgotPasswordForm'
import RouterLink from 'src/components/common/RouterLink'
import { useQueryString, useFormHook } from 'src/hooks/custom'
import { ResetPasswordSchema } from 'src/utils/validations'
import { useActiavteUser, useResetPassword } from 'src/hooks/mutations/user'

const getTypeForPayload = (type: string) => {
  switch (type) {
    case 'EduIntern':
      return 1
    case 'Mentor':
      return 2
    case 'TeamLead':
      return 3
    default:
      throw new Error('Invalid type')
  }
}

type IResetPasswordInputs = {
  newPassword: string
  confirmPassword: string
}

const ResetPassword = () => {
  const [successText, setSuccessText] = useState('')

  const navigate = useNavigate()
  const { register, handleSubmit, errors } = useFormHook<IResetPasswordInputs>(ResetPasswordSchema)

  const { mutate: activateUser, isLoading: activatingUser } = useActiavteUser()
  const { mutate: resetPassword, isLoading: resetingPassword, isError } = useResetPassword()

  const { parseQuery } = useQueryString()
  const { id = '', token = '', type = '' } = parseQuery()

  const onSubmit = ({ newPassword }: IResetPasswordInputs) => {
    if (type) {
      activateUser(
        {
          id: String(id),
          type: getTypeForPayload(type as string),
          token: String(token),
          password: newPassword
        },
        {
          onSuccess: () => {
            setSuccessText('Password successfully created')

            setTimeout(() => {
              navigate('/authorization')
            }, 3000)
          }
        }
      )
    } else {
      resetPassword(
        { token: String(token), newPassword, userId: String(id) },
        {
          onSuccess: () => {
            setSuccessText('Password successfully reset')

            setTimeout(() => {
              navigate('/authorization')
            }, 3000)
          }
        }
      )
    }
  }

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      title="Reset Password"
      text={
        <span>
          <WarningText>New password must contain:</WarningText> min. 3 characters, 1 lowercase, 1 uppercase, one numeric
          digit and one special character (.@#$%*)
        </span>
      }>
      <Input
        register={register}
        type="password"
        label="New Password"
        name="newPassword"
        id="newPassword"
        defaultValue=""
        error={errors.newPassword?.message}
      />
      <Input
        register={register}
        type="password"
        label="Confirm Password"
        name="confirmPassword"
        id="confirmPassword"
        defaultValue=""
        error={errors.confirmPassword?.message}
      />

      <ConfirmMessage displayMessage={!!successText}>{successText}</ConfirmMessage>
      <ConfirmMessage displayMessage={isError}>
        Password reset failed. Please check your email for instructions on how to reset your password.
      </ConfirmMessage>
      <Row margin="24px 0 0">
        <Column>
          <Button buttonType="full" padding="9.5px 55.5px" disabled={activatingUser || resetingPassword}>
            Reset
          </Button>
        </Column>
        <Column>
          <RouterLink to="/authorization">Back to Sign In</RouterLink>
        </Column>
      </Row>
    </Form>
  )
}

export default ResetPassword
