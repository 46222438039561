import React, { FC, useState } from 'react'

import {
  ISurveyConfigurationQuestion,
  ISurveySubmission,
  SurveyParticipantSubmissionStatus
} from 'src/api/services/survey'
import Button from 'src/components/common/buttons/Button'
import SurveyQuestion from 'src/pages/surveyQuestions/components/SurveyQuestion'
import { SubmitButton, SubmitButtonContainer, SurveyWrapper } from './survey.styles'
import { useParams } from 'react-router'

interface SurveyProps {
  questions: ISurveyConfigurationQuestion[]
  onSubmit: () => void
  submission: ISurveySubmission
}

const SurveyReview: FC<SurveyProps> = ({ questions, onSubmit, submission }) => {
  const { id = '' } = useParams()

  const [isAnswering, setIsAnswering] = useState(false)

  const renderQuestion = (question: ISurveyConfigurationQuestion, index: number) => {
    return (
      <SurveyQuestion
        configId={id}
        key={question.id}
        question={question}
        index={index}
        submission={submission}
        setIsAnswering={setIsAnswering}
        disabled={submission.status !== SurveyParticipantSubmissionStatus.Started}
      />
    )
  }

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()

    onSubmit()
  }

  return (
    <SurveyWrapper>
      <form onSubmit={handleSubmit}>
        {questions.map(renderQuestion)}
        <SubmitButtonContainer>
          <SubmitButton
            buttonType="full"
            padding="9px 40px"
            type="submit"
            disabled={isAnswering || submission.status !== SurveyParticipantSubmissionStatus.Started}>
            Submit
          </SubmitButton>
        </SubmitButtonContainer>
      </form>
    </SurveyWrapper>
  )
}

export default SurveyReview
