import React, { useState } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router'
import Icon from 'src/components/icon/Icon'
import { useAuth } from 'src/context/authContext'
import { IStack } from 'src/api/services/stack'
import { useStacks } from 'src/hooks/queries/extended'
import { useAllSyllabus } from 'src/hooks/queries/syllabus'
import { useCreateSyllabus, useDeleteSyllabus } from 'src/hooks/mutations/syllabus'
import CreateSyllabusModal from 'src/components/modals/CreateSyllabusModal'

const SyllabusContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`

const SyllabusWrapper = styled.div`
  width: 140px;
  height: 70px;
  border: 1px solid black;
  position: relative;
  border-radius: 8px;
  background: #433b51;
  color: #fff;
  padding: 10px;

  div {
    position: absolute;
    top: 6px;
    right: 8px;
  }

  &:hover {
    cursor: pointer;
  }
`

export type Stack = {
  name: string
  id: string
}

const SyllabusList: React.FC<{ toCreate?: boolean }> = ({ toCreate = false }) => {
  const [selectedStack, setSelectedStack] = useState<IStack>()

  const navigate = useNavigate()
  const { isAdmin, isIntern } = useAuth()

  const { data: stackData } = useStacks({ page: 1, pageSize: 50 })
  const { eduSyllabuses = [] } = useAllSyllabus()

  const { mutate: deleteSyllabus } = useDeleteSyllabus()

  const stacksWithSyllabus = eduSyllabuses.map((syll) => syll.eduStack?.id)
  const stacksWithourSyllabus = stackData?.eduStacks.filter((stack) => !stacksWithSyllabus.includes(stack.id))

  const data = toCreate ? stacksWithourSyllabus : eduSyllabuses

  const createSyllabusHandler = (syll: IStack) => {
    if (isIntern) {
      return
    }

    if (toCreate) {
      return setSelectedStack(syll)
    }

    navigate(`/pages/syllabus/${syll.id}`)
  }

  const deleteSyllabusHandler = (e: React.MouseEvent, id: string) => {
    e.stopPropagation()
    deleteSyllabus(id)
  }

  return (
    <SyllabusContainer>
      {data?.map((syll: any) => (
        <SyllabusWrapper key={syll.id} onClick={() => createSyllabusHandler(syll)}>
          {syll.name}
          {!toCreate && isAdmin && (
            <div onClick={(e) => deleteSyllabusHandler(e, syll.id)}>
              <Icon iconName="iconCross" color="#fff" size="10px" />
            </div>
          )}
        </SyllabusWrapper>
      ))}
      <CreateSyllabusModal stack={selectedStack} onClose={() => setSelectedStack(undefined)} />
    </SyllabusContainer>
  )
}

export default SyllabusList
