import React from 'react'
import { colorPalette } from 'src/themes/theme'
import styled from 'styled-components'

interface Props {
  options: string[]
  value: string
  onChange: (val: string) => void
}

const RadioGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 28px;
`

const RadioButton = styled.div`
  .container {
    display: block;
    position: relative;
    padding-left: 28px;
    cursor: pointer;
  }

  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    width: 18px;
    border: 2px solid ${colorPalette.pink.primary[60]};
    height: 18px;
    border-radius: 50%;
    margin-top: 3px;
  }

  .container input:checked ~ .checkmark {
    background-color: #fff;
    border: 2px solid ${colorPalette.pink.primary[60]};
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  .container input:checked ~ .checkmark:after {
    display: block;
  }

  .container .checkmark:after {
    top: 3px;
    left: 3px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${colorPalette.pink.primary[60]};
  }
`

const RadioGroup: React.FC<Props> = ({ options, value, onChange }) => {
  return (
    <RadioGroupContainer>
      {options?.map((opt) => {
        return (
          <RadioButton key={opt}>
            <label key={opt} className="container">
              <input value={opt} type="radio" checked={opt === value} onChange={(e) => onChange(e.target.value)} />
              <span className="checkmark" />
              {opt}
            </label>
          </RadioButton>
        )
      })}
    </RadioGroupContainer>
  )
}

export default RadioGroup
