import React from 'react'
import Icon from 'src/components/icon/Icon'
import { useGetUser } from 'src/hooks/queries/user'
import styled from 'styled-components'

const Title = styled.h2`
  font-weight: 500;
  color: #170738;
  padding-bottom: 28px;
`

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const StackMemberContainer = styled.div`
  display: flex;
  background-color: #fcfdff;
  padding: 16px;
  align-items: center;
  justify-content: space-between;
`

const StackMemberInfoText = styled.p`
  font-size: 14px;
  line-height: 21px;
  color: #595959;
  flex: 1;
`

const EmailContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  flex: 1;
`

const MoreButton = styled.button`
  outline: none;
  background-color: #ffffff00;
  border: 0px;
`

const MyInterns = () => {
  const { user } = useGetUser()

  const userInterns = user?.eduMentor?.eduInterns || []
  return (
    <div>
      <Title>My Interns</Title>
      <List>
        {userInterns.map((intern) => (
          <StackMemberContainer key={intern.id}>
            <StackMemberInfoText>
              {intern.firstName} {intern.lastName}
            </StackMemberInfoText>
            <EmailContainer>
              <Icon iconName="iconEmail" />
              <StackMemberInfoText>{intern.email}</StackMemberInfoText>
            </EmailContainer>
            <MoreButton>{/* <Icon iconName="iconThreeDots" /> */}</MoreButton>
          </StackMemberContainer>
        ))}
      </List>
    </div>
  )
}

export default MyInterns
