import { useQuery } from 'react-query'
import { QUERY_KEYS } from 'src/enums'
import { useAuth } from 'src/context/authContext'
import { getInternAnalytics, getInternInfo } from 'src/api/services/user'

export const useGetIntern = () => {
  const { isIntern } = useAuth()

  const { data, isError, isLoading } = useQuery([QUERY_KEYS.INTERN_INFO], getInternInfo, { enabled: isIntern })

  return { user: data?.user, isError, isLoading }
}

export const useGetInternAnalytic = (id: string) => {
  const { data, isError, isLoading } = useQuery([QUERY_KEYS.INTERN_ANALYTICS, id], () => getInternAnalytics(id), {
    enabled: Boolean(id)
  })

  return { data, isError, isLoading }
}
