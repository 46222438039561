import { AxiosError } from 'axios'
import { QUERY_KEYS } from 'src/enums'
import { useMutation, useQueryClient } from 'react-query'
import { useSyllabus } from 'src/context/syllabusContext'
import { AddSubjectPayload, addSyllabusSubject } from 'src/api/services/syllabus'

export const useAddSubject = () => {
  const client = useQueryClient()
  const { setSyllabusData } = useSyllabus()

  const { mutate, isLoading, isError } = useMutation<void, AxiosError, AddSubjectPayload>(addSyllabusSubject, {
    onSuccess: () => {
      setSyllabusData([])
      client.invalidateQueries([QUERY_KEYS.SYLLABUS])
    }
  })

  return { mutate, isLoading, isError }
}
