import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import styled, { css } from 'styled-components'
import { breakpoints } from 'src/themes/theme'
import { galleryData } from './data'

const CustomSwiper = styled(Swiper)`
  ${({ theme }) => css`
    width: 130%;
    transform: translateX(-11%);

    @media ${theme.devices.tabletLandscape} {
      width: 150%;
      transform: translateX(-16%);
    }

    @media ${theme.devices.tablet} {
      width: 180%;
      transform: translateX(-22%);
    }

    @media ${theme.devices.mobile} {
      width: 240%;
      transform: translateX(-29%);
    }
  `}
`

const CustomSwiperStyle = styled(SwiperSlide)`
  div {
    height: 370px;

    @media ${({ theme }) => theme.devices.mobile} {
      height: 270px;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Gallery = () => {
  const mobileBreakpoint = +breakpoints.mobile.slice(0, -2)

  return (
    <CustomSwiper
      slidesPerView={3}
      spaceBetween={8}
      slidesPerGroup={1}
      loop={true}
      loopFillGroupWithBlank={true}
      breakpoints={{
        [mobileBreakpoint]: {
          slidesPerView: 3,
          spaceBetween: 16
        }
      }}
      className="mySwiper">
      {galleryData.map(({ img, alt }, id) => {
        return (
          <CustomSwiperStyle key={id}>
            <div>
              <img src={img} alt={alt} />
            </div>
          </CustomSwiperStyle>
        )
      })}
    </CustomSwiper>
  )
}

export default Gallery
