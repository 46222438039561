import React from 'react'
import styled, { css } from 'styled-components'
import Icon, { IconTypes } from '../../icon/Icon'
import Text from '../Text'

type UserInfoProps = {
  title?: string
  iconPath?: IconTypes
  gap?: string
}

const Container = styled.div<UserInfoProps>`
  display: flex;
  align-items: center;
  gap: ${(props) => props.gap};
`

const UserInfoRow = ({ title = '', gap = '12px', iconPath = 'iconEmail' }: UserInfoProps) => {
  return (
    <Container gap={gap}>
      <Icon iconName={iconPath} size={'20px'} />
      <Text fontSize="12px">{title}</Text>
    </Container>
  )
}

export default UserInfoRow
