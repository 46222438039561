import { decodeJWT } from 'src/helpers'
import { axiosInstanceIdentity, axiosInstanceAdmin, axiosInstanceEdu } from 'src/components/interceptor'
import { EduIntern } from './stack'
export interface LoginResponse {
  token: string
  refreshToken: string
  success?: boolean
}
export interface ActivateUserPayload {
  id: string
  token: string
  password: string
  type: number | string
}

export interface ICommonInfo {
  id: string
  firstName: string
  lastName: string
  email: string
  userId: string | null
}

interface EduProject {
  id: string
  name: string
  description: string
  type: number
}

export interface IUser {
  id: string
  firstName: string
  lastName: string
  email: string
  idNumber: string
  phoneNumber: string
  status: number
  userType: number
  statusText: string
  userId: string | null
  userTypeText: string
  imageUrl: string | null
  eduIntern?: {
    dateOfBirthUtc: string
    id: string
    eduStack: {
      id: string
      name: string
      eduStack: {
        id: string
        name: string
      }
    }
    eduMentor: ICommonInfo
    eduTeamLead: ICommonInfo
    eduSquadLead: ICommonInfo
    eduProjects: EduProject[]
  }
  eduMentor?: {
    dateOfBirthUtc: string
    eduStack: {
      id: string
      name: string
      eduStack: {
        id: string
        name: string
      }
    }
    eduMentor: ICommonInfo
    eduTeamLead: ICommonInfo
    eduSquadLead: ICommonInfo
    eduInterns: EduIntern[]
  }
  eduTeamLead?: {
    dateOfBirthUtc: string
    eduStack: {
      id: string
      name: string
      eduStack: {
        id: string
        name: string
      }
    }
    eduMentor: ICommonInfo
    eduTeamLead: ICommonInfo
    eduSquadLead: ICommonInfo
  }
}

interface UsersResponse {
  users: IUser[]
}
interface UserInfoResponse {
  user: IUser
}

export interface LoginPayload {
  userName: string
  password: string
}

export interface RestorePasswordPayload {
  email: string
  callBackUrl: string
}

export interface ChangePasswordPayload {
  newPassword: string
  currentPassword: string
}

export interface ResetPasswordPayload {
  userId: string
  token: string
  newPassword: string
}

export interface EditUserPayload {
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  idNumber: string
}

export interface AnalyticsResponse {
  components: Component[] | null
  exams: any[]
  reviewInterns: any[]
  componentsTotalPoints: number
  componentsRecivedTotalPoints: number
  componentsPercentageOfTotalPoints: number
  examsTotalPoints: number
  examsRecivedTotalPoints: number
  examsPercentageOfTotalPoints: number
  overallPoints: number
  overallRecivedPoints: number
  overallPercentageOfRating: number
  userId: string
  firstName: string
  lastName: string
  email: string
  imageUrl: string
}

export interface Component {
  id: string
  name: string
  maxPoint: number
  points: number
  percentageOfPoints: number
}

export interface UpcomingEventsResponse {
  events: Event[]
}

export interface Event {
  id: string
  name: string
  startDateUtc: Date
  endDateUtc: Date
  comment: string
  link: string
  createdByUserId: string
  seniority: number
}

export const loginUser = async (payload: LoginPayload) => {
  const { data } = await axiosInstanceIdentity.post<LoginResponse>('api/v1/auth', payload)

  return data
}

export const getUserInfo = async () => {
  const { userId } = decodeJWT()
  const { data } = await axiosInstanceEdu.get<UserInfoResponse>(`api/v1/User/${userId}`)

  return data
}

export const getUsers = async () => {
  const { data } = await axiosInstanceEdu.get<UsersResponse>(`api/v1/User`, {
    params: { pageSize: 100, page: 1 }
  })

  return data
}

export const getInternInfo = async () => {
  const { userId } = decodeJWT()
  const { data } = await axiosInstanceEdu.get(`api/v1/EduIntern/${userId}`)

  return data
}

export const getInternAnalytics = async (id: string) => {
  const { data } = await axiosInstanceEdu.get(`api/v1/EduIntern/${id}/analytic`)

  return data
}

export const changePassword = async (payload: ChangePasswordPayload) => {
  const { data } = await axiosInstanceEdu.put('/api/v1/User/change-password', payload)

  return data
}

export const restorePassword = async (payload: RestorePasswordPayload) => {
  const { data } = await axiosInstanceIdentity.post('/api/v1/account/password-reset', payload)

  return data
}

export const resetPassword = async (payload: ResetPasswordPayload) => {
  const { data } = await axiosInstanceIdentity.put('/api/v1/account/password-reset', payload)

  return data
}

export const activateUser = async (payload: ActivateUserPayload) => {
  const { data } = await axiosInstanceIdentity.post('/api/v1/account/activate', payload)

  return data
}

export const uploadProfilePicture = async ({ image }: { image: File }) => {
  const token = localStorage.getItem('access_token')

  if (!token) {
    throw new Error('Unauthorized')
  }

  const formData = new FormData()
  formData.append('Image', image)

  const { data } = await axiosInstanceEdu.post('/api/v1/User/image-upload', formData, {
    headers: { Authorization: `Bearer ${token}` }
  })

  return data
}

export const deleteProfilePicture = async () => {
  const token = localStorage.getItem('access_token')

  if (!token) {
    throw new Error('Unauthorized')
  }
  const { data } = await axiosInstanceEdu.delete('/api/v1/User/image', {
    headers: { Authorization: `Bearer ${token}` }
  })

  return data
}

export const editUserInfo = async (userId: string, payload: EditUserPayload) => {
  const token = localStorage.getItem('access_token')

  if (!token) {
    throw new Error('Unauthorized')
  }

  const { data } = await axiosInstanceEdu.put(`/api/v1/User/id?id=${userId}`, payload, {
    headers: { Authorization: `Bearer ${token}` }
  })

  return data
}

export const getUserAnalyticsInfo = async () => {
  const { userId } = decodeJWT()
  const { data } = await axiosInstanceEdu.get<AnalyticsResponse>(`api/v1/User/${userId}/analytics`)

  return data
}

export const getUserUpcomingEvents = async () => {
  const { data } = await axiosInstanceEdu.get<UpcomingEventsResponse>(`api/v1/User/upcoming-events`)

  return data
}
