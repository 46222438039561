import styled, { css } from 'styled-components'

export const ContactUsComponent = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.pages.landingPage.contactUsSection.backgroundColor};
    padding: 32px 0 52px;
    color: ${theme.pages.landingPage.contactUsSection.textColor};
    font-family: ${({ theme }) => theme.fonts.family.primary};
  `}
`

export const ContactUsText = styled.h1`
  color: ${({ theme }) => theme.pages.landingPage.contactUsSection.contactUsTextColor};
  text-transform: uppercase;
  line-height: 1;

  span {
    display: block;
  }

  span:first-child {
    font-size: 53px;
    font-weight: 600;
    letter-spacing: 1px;
  }

  span:last-child {
    font-size: 201px;
    font-weight: 500;
  }
`

export const ContactInfo = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 21px;
    padding: 38px 49px;
    border: 1px solid ${theme.pages.landingPage.contactUsSection.borderColor};
    border-radius: 4px;

    p:nth-child(1) {
      font-size: ${theme.pages.landingPage.contactUsSection.contactInfo.title.size.laptop};
      font-weight: 600;

      @media ${theme.devices.mobile} {
        font-size: ${theme.pages.landingPage.contactUsSection.contactInfo.title.size.mobile};
      }
    }

    p:nth-child(2) {
      font-size: ${theme.pages.landingPage.contactUsSection.contactInfo.text.size.laptop};
      font-weight: 400;

      @media ${theme.devices.mobile} {
        font-size: ${theme.pages.landingPage.contactUsSection.contactInfo.text.size.mobile};
      }
    }

    p:nth-child(3) {
      color: ${theme.pages.landingPage.contactUsSection.contactInfo.phoneNumber.color};
      font-size: ${theme.pages.landingPage.contactUsSection.contactInfo.phoneNumber.size.laptop};
      font-weight: 400;

      @media ${theme.devices.mobile} {
        font-size: ${theme.pages.landingPage.contactUsSection.contactInfo.phoneNumber.size.mobile};
      }
    }
  `}
`
