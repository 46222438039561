import { axiosInstanceEdu } from 'src/components/interceptor'

export enum SurveyType {
  'Review Mentor' = 1,
  'Review Intern' = 2,
  'Exam' = 3
}

export enum SurveyQuestionType {
  MultipleChoice = 1,
  OpenEnded = 2,
  Rating = 3
}

export enum SurveyParticipantSubmissionStatus {
  Started = 1,
  Finished = 2,
  Completed = 3
}

export interface ISurveyQuestionSubmission {
  id: string
  questionId: string
  surveySubmissionId: string
  answer: string
  score: number
  answerIds: string[]
}

export interface Attachment {
  downloadUrl: string
  extension: string
}

export interface SurveyQuestionSubmission {
  id: string
  questionId: string
  surveySubmissionId: string
  answer: string
  score: number
  answerIds: string[]
  attachment: Attachment | null
}

export interface ISurveySubmission {
  id: string
  surveyConfigurationId: string
  userId: string
  totalScore: number
  startDateTime: string
  finishDateTime: string
  status: number
  statusText: string
  surveyQuestionSubmissions: SurveyQuestionSubmission[]
}

export interface QuestionSubmission {
  questionId: string
  answer?: string
  answerIds?: string[]
}

export interface ISurveyQuestionSubmission {
  id: string
  questionId: string
  surveySubmissionId: string
  answer: string
  score: number
  answerIds: string[]
  attachment: Attachment | null
}

export interface SurveyConfigurationQuestionAnswer {
  id: string
  value: string
}

export interface SurveyParticipantSubmission {
  id: string
  surveyConfigurationId: string
  userId: string
  totalScore: string
  startDateTime: string
  finishDateTime: string
  status: SurveyParticipantSubmissionStatus
  statusText: keyof typeof SurveyParticipantSubmissionStatus
}

export interface ISurveyConfiguration {
  id: string
  title: string
  surveyType: SurveyType
  duration: number
  totalScore: number
  passingScore: number
  availabilityDate: string
  expireDate: string
  configurationDate: string
  surveyId: string
  userIds: string[]
  surveySubmission: SurveyParticipantSubmission
}

export interface ISurveyConfigurationQuestion {
  id: string
  text: string
  questionType: SurveyQuestionType
  score: number
  surveyConfigurationId: string
  answers: SurveyConfigurationQuestionAnswer[]
  submission: ISurveyQuestionSubmission | null
  attachment: Attachment | null
}

export interface GetSurveyConfigurationsPayload {
  surveyConfigurations: ISurveyConfiguration[]
}

export interface GetSurveyConfigurationPayload {
  surveyConfiguration: ISurveyConfiguration
}

export interface GetSurveyConfigurationQuestionsPayload {
  questions: ISurveyConfigurationQuestion[]
}

export interface GetSurveySubmissionPayload {
  surveySubmission: ISurveySubmission
}

export interface SubmitQuestionAnswerParams extends QuestionSubmission {
  submissionId: string
  attachment?: File
}

export interface UploadAnswerAttachmentPayload {
  submissionId: string
  questionId: string
  attachment: File
}

export const getSurveyConfigurations = async () => {
  const token = localStorage.getItem('access_token')

  if (!token) {
    throw new Error('Unauthorized')
  }

  const { data } = await axiosInstanceEdu.get<GetSurveyConfigurationsPayload>('api/v1/SurveyConfiguration', {
    headers: { Authorization: `Bearer ${token}` }
  })

  return data
}

export const getSurveyConfiguration = async (configId: string) => {
  const token = localStorage.getItem('access_token')

  if (!token) {
    throw new Error('Unauthorized')
  }

  const { data } = await axiosInstanceEdu.get<GetSurveyConfigurationPayload>(`api/v1/SurveyConfiguration/${configId}`, {
    headers: { Authorization: `Bearer ${token}` }
  })

  return data
}

export const getSurveyConfigurationQuestions = async (configId: string) => {
  const token = localStorage.getItem('access_token')

  if (!token) {
    throw new Error('Unauthorized')
  }

  const { data } = await axiosInstanceEdu.get<GetSurveyConfigurationQuestionsPayload>(
    `/api/v1/SurveyConfiguration/${configId}/questions`,
    { headers: { Authorization: `Bearer ${token}` } }
  )

  return data
}

export const getSurveySubmission = async (configId: string) => {
  const token = localStorage.getItem('access_token')

  if (!token) {
    throw new Error('Unauthorized')
  }

  const { data } = await axiosInstanceEdu.get<GetSurveySubmissionPayload>(
    `/api/v1/SurveyConfiguration/${configId}/submission`,
    { headers: { Authorization: `Bearer ${token}` } }
  )

  return data
}

export const createSurveySubmissionId = async (configId: string) => {
  const token = localStorage.getItem('access_token')

  if (!token) {
    throw new Error('Unauthorized')
  }

  const { data } = await axiosInstanceEdu.post<{ surveySubmissionId: string }>(
    `/api/v1/SurveyConfiguration/${configId}/submission`,
    undefined,
    { headers: { Authorization: `Bearer ${token}` } }
  )

  return data
}

export const submitQuestionAnswer = async (payload: SubmitQuestionAnswerParams) => {
  const token = localStorage.getItem('access_token')

  if (!token) {
    throw new Error('Unauthorized')
  }

  const { submissionId, answer, questionId, answerIds, attachment } = payload

  const formData = new FormData()

  formData.append('QuestionId', questionId)
  answer && formData.append('Answer', answer)
  answerIds?.forEach((a, i) => {
    formData.append(`AnswerIds[${i}]`, a)
  })
  attachment && formData.append('Attachment', attachment)

  const { data } = await axiosInstanceEdu.post(`/api/v1/SurveySubmission/${submissionId}/question-answer`, formData, {
    headers: { Authorization: `Bearer ${token}` }
  })

  return data
}

export const changeQuestionAnswer = async (payload: SubmitQuestionAnswerParams) => {
  const token = localStorage.getItem('access_token')

  if (!token) {
    throw new Error('Unauthorized')
  }

  const { submissionId, answer, questionId, answerIds, attachment } = payload

  const formData = new FormData()

  formData.append('QuestionId', questionId)
  answer && formData.append('Answer', answer)
  answerIds?.forEach((a, i) => {
    formData.append(`AnswerIds[${i}]`, a)
  })
  attachment && formData.append('Attachment', attachment)

  const { data } = await axiosInstanceEdu.patch(`/api/v1/SurveySubmission/${submissionId}/question-answer`, formData, {
    headers: { Authorization: `Bearer ${token}` }
  })

  return data
}

export const uploadAnswerAttachment = async ({
  questionId,
  submissionId,
  attachment
}: UploadAnswerAttachmentPayload) => {
  const token = localStorage.getItem('access_token')

  if (!token) {
    throw new Error('Unauthorized')
  }

  const formData = new FormData()

  formData.append('QuestionId', questionId)
  formData.append('Attachment', attachment)

  const { data } = await axiosInstanceEdu.post(
    `/api/v1/SurveySubmission/${submissionId}/question-answer-attachment`,
    formData,
    {
      headers: { Authorization: `Bearer ${token}` }
    }
  )

  return data
}

export const finishSurvey = async (submissionId: string) => {
  const token = localStorage.getItem('access_token')

  if (!token) {
    throw new Error('Unauthorized')
  }

  const { data } = await axiosInstanceEdu.patch(`/api/v1/SurveySubmission/${submissionId}/Finish`, undefined, {
    headers: { Authorization: `Bearer ${token}` }
  })

  return data
}
