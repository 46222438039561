import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'

// NOTE: component style

export const SectionWrapper = styled.div`
  ${({ theme }) => css`
    padding: 0 145px;
    position: relative;
    z-index: 500;

    @media ${theme.devices.laptop} {
      padding: 0 80px;
    }

    @media ${theme.devices.tablet} {
      padding: 0 39px;
    }
  `}
`
// NOTE: component

interface ISectionProps {
  children: JSX.Element | JSX.Element[] | ReactNode
}

const Section = ({ children }: ISectionProps) => {
  return <SectionWrapper>{children}</SectionWrapper>
}

export default Section
