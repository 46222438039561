import styled, { css } from 'styled-components'

export const ProgramSection = styled.div`
  padding: 70px 0 100px;
  color: ${({ theme }) =>
    css`
      ${theme.layout.section.heading.color}
    `};
`
export const ProgramList = styled.ul`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    grid-auto-rows: max-content;
    padding: 100px 0;
    gap: 14px;

    @media ${theme.devices.laptop} {
      grid-template-columns: repeat(2, minmax(260px, 1fr));
    }

    @media ${theme.devices.tabletLandscape} {
      grid-template-columns: repeat(2, minmax(260px, 1fr));
    }

    @media ${theme.devices.tablet} {
      grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    }
  `}
`

interface IProgramListItemProps {
  backgroundColor: string
}

export const ProgramListItem = styled.li<IProgramListItemProps>`
  ${({ theme, backgroundColor }) => css`
    height: 162px;
    background-color: ${backgroundColor};
    color: ${theme.pages.landingPage.programsSection.programList.color};
    padding: 28px 34px 30px;
    border-radius: 4px;

    p:first-child {
      font-size: ${theme.pages.landingPage.programsSection.programList.listItem.title.size.laptop};
      font-weight: 600;
      margin-bottom: 15px;

      @media ${theme.devices.mobile} {
        font-size: ${theme.pages.landingPage.programsSection.programList.listItem.title.size.mobile};
      }
    }

    p:last-child {
      font-size: ${theme.pages.landingPage.programsSection.programList.listItem.text.size.laptop};
      font-weight: 400;
      line-height: 24px;

      @media ${theme.devices.mobile} {
        font-size: ${theme.pages.landingPage.programsSection.programList.listItem.text.size.mobile};
      }
    }
  `}
`
export const VideoContainer = styled.div`
  width: 100%;
  height: 552px;
`
