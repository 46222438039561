import React from 'react'
import { PieChart, Pie, Cell, Label } from 'recharts'
import ChartResults from './ChartResults'
import { AnalyticsResponse } from '../../api/services/user'
import { COLORS } from '../../constants/colors'
import styled from 'styled-components'

export interface AnalyticsProps {
  data: AnalyticsResponse
}

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 90px;
  flex-wrap: wrap;
`

const InternshipChart = ({ data }: AnalyticsProps) => {
  const pieData = data.components?.map((component) => {
    return {
      name: component.name,
      value: component.percentageOfPoints
    }
  })

  const rating = data.componentsPercentageOfTotalPoints.toFixed(2) + '%'

  return (
    <Wrapper>
      <PieChart width={250} height={200}>
        <Pie data={pieData} cx={120} cy={100} innerRadius={50} outerRadius={80} fill="#8884d8" dataKey="value">
          {pieData?.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}></Cell>
          ))}
          <Label value={rating} position="center" fontSize={28} fill="#170738" />
        </Pie>
        {/*may be needed*/}
        {/*<Pie*/}
        {/*  data={pieData}*/}
        {/*  cx={120}*/}
        {/*  cy={100}*/}
        {/*  radius={500}*/}
        {/*  innerRadius={40}*/}
        {/*  outerRadius={50}*/}
        {/*  fill="#8884d8"*/}
        {/*  paddingAngle={0}*/}
        {/*  dataKey="value">*/}
        {/*  {pieData.map((entry, index) => (*/}
        {/*    <Cell key={`cell-${index}`} fill={COLORS1[index % COLORS.length]} />*/}
        {/*  ))}*/}
        {/*</Pie>*/}
      </PieChart>
      <ChartResults data={data} />
    </Wrapper>
  )
}

export default InternshipChart
