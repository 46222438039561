import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import Section from 'src/components/common/Section'
import SectionHeadingComp from 'src/components/common/SectionHeading'
import Row from 'src/components/common/Row'
import QuestionnariesAccordion from 'src/components/accordion-variants/questionnaries-accordion/QuestionnariesAccordion'
import SurveyReview from 'src/components/survey'

const QuestionnariesAndExamsPageWrapper = styled.div`
  ${({ theme }) => css`
    padding: 80px 0 0;
    color: ${({ theme }) => theme.colors.primary};
    min-height: 100vh;

    @media ${theme.devices.tablet} {
      padding-top: 40px;
    }
  `}
`

interface ViewButtonProps {
  isActive: boolean
}

const ViewButton = styled.button<ViewButtonProps>`
  ${({ theme, isActive }) => css`
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 22px;
    font-weight: 500;
    color: ${isActive ? '#000000' : '#00000080'};
    margin-bottom: 42px;

    &:hover {
      cursor: pointer;
      color: #000000;
    }

    &:not(:last-child) {
      padding-right: 40px;
      border-right: solid 4px #39b5b5;
    }

    &:last-child {
      padding-left: 40px;
    }
  `}
`

const views = ['Technical exams', 'Surveys']

const Questionnaries = () => {
  const [view, setView] = useState<string>(views[0])

  return (
    <QuestionnariesAndExamsPageWrapper>
      <Section>
        <SectionHeadingComp>Questionaires & Exams</SectionHeadingComp>
        <Row justify="start">
          {views.map((value: string, id: number) => {
            return (
              <ViewButton isActive={value === view} key={id} onClick={() => setView(value)}>
                {value}
              </ViewButton>
            )
          })}
        </Row>
        {/* {view === 'Technical exams' ? <QuestionnariesAccordion /> : <Survey />} */}
      </Section>
    </QuestionnariesAndExamsPageWrapper>
  )
}

export default Questionnaries
