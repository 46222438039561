import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import Icon from 'src/components/icon/Icon'
import { useModal } from 'src/context/modalContext'
import { useGetMonths } from 'src/hooks/queries/syllabus'
import { useAuth } from 'src/context/authContext'
import { useSyllabus } from 'src/context/syllabusContext'
import { useDeleteMonth } from 'src/hooks/mutations/syllabus'

const MonthBar = styled.ul`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-template-rows: max-content;
    gap: 16px;
    align-items: center;
    font-size: ${theme.pages.syllabusPage.monthBar.fontSize.laptop};
    font-weight: 500;
    margin-bottom: 40px;
    white-space: nowrap;
    color: ${theme.pages.syllabusPage.monthBar.color};

    @media ${theme.devices.mobile} {
      font-size: ${theme.pages.syllabusPage.monthBar.fontSize.mobile};
      grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    }
  `}
`

type MonthCompProp = {
  active: boolean
}

const MonthComp = styled.li<MonthCompProp>`
  ${({ theme, active }) => css`
    text-align: center;
    padding: 15px 42px;
    background-color: ${active
      ? theme.pages.syllabusPage.monthBar.backgroundColor.active
      : theme.pages.syllabusPage.monthBar.backgroundColor.inactive};
    cursor: pointer;
    border-radius: ${theme.borderRadius.primary};
    position: relative;
  `}
`

interface IAddIconWrapper {
  months: number
}

const AddIconWrapper = styled.div<IAddIconWrapper>`
  ${({ months }) => css`
    margin-left: ${months > 0 ? '20px' : 0};
  `}

  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > p {
    color: #000;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
  }
`

const IconCrossWrapper = styled.div`
  position: absolute;
  right: 4px;
  top: 4px;
`

const MonthComponent = () => {
  const { setShowModal, setModalType } = useModal()

  const { isIntern } = useAuth()
  const { mutate } = useDeleteMonth()
  const { eduMonths } = useGetMonths()
  const { activeMonth, setActiveMonth } = useSyllabus()

  useEffect(() => {
    if (eduMonths) {
      setActiveMonth(eduMonths[0]?.id)
    }
  }, [eduMonths, setActiveMonth])

  const handleModalOpen = () => {
    setModalType('addMonthModal')
    setShowModal(true)
  }

  const handleMonthDelete = (event: React.MouseEvent<Element, MouseEvent>, id: string) => {
    event.stopPropagation()
    mutate(id)
  }

  const handleMonthSelect = (id: string) => {
    setActiveMonth(id)
    localStorage.setItem('activeMonth', id)
  }

  const months = eduMonths?.map(({ id, name }) => {
    return (
      <MonthComp active={activeMonth === id} onClick={() => handleMonthSelect(id)} key={id}>
        <IconCrossWrapper onClick={(event) => handleMonthDelete(event, id)}>
          {!isIntern && <Icon iconName="iconCross" size="12px" color="#fff" />}
        </IconCrossWrapper>
        {name}
      </MonthComp>
    )
  })

  return (
    <MonthBar>
      {months}
      <AddIconWrapper months={Number(eduMonths?.length)}>
        {!isIntern && (
          <>
            <Icon iconName="iconPlus" size="32px" onClick={handleModalOpen} />
            <p>Add Month</p>
          </>
        )}
      </AddIconWrapper>
    </MonthBar>
  )
}

export default MonthComponent
