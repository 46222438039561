import React from 'react'
import styled, { css } from 'styled-components'

interface IAccordionGenericItemProps {
  children: JSX.Element | JSX.Element[]
}

const AccordionGenericItemWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`

const AccordionGenericItem = ({ children }: IAccordionGenericItemProps) => {
  return <AccordionGenericItemWrapper>{children}</AccordionGenericItemWrapper>
}

export default AccordionGenericItem
