import React from 'react'
import AddEventModal from 'src/components/modals/AddEventModal'
import LogOutModal from 'src/components/modals/LogOutModal'
import AddMonthModal from 'src/components/modals/AddMonthModal'
import EventDetailsModal from 'src/components/modals/EventDetailsModal'

const ModalVariants = {
  logoutModal: <LogOutModal />,
  addEventModal: <AddEventModal />,
  addMonthModal: <AddMonthModal />,
  eventDetailsModal: <EventDetailsModal />
}

export type ModalType = keyof typeof ModalVariants

interface IModalTypeProps {
  modalType: ModalType
}

const ModalVariant = ({ modalType }: IModalTypeProps) => {
  return ModalVariants[modalType]
}

export default ModalVariant
