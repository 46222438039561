import React, { FC } from 'react'
import moment from 'moment'

import { Notification as INotification } from 'src/api/services/notifications'
import { NotificationItem, Description, ContentWrapper, DateItem, Dot } from './notifications.styles'
import useReadNotification from 'src/hooks/queries/notifications/useReadNotification'

interface NotificationProps {
  notification: INotification
}

const Notification: FC<NotificationProps> = ({ notification }) => {
  const { mutate, isLoading } = useReadNotification()

  const handleClick = () => {
    if (isLoading || notification.isRead) {
      return
    }

    mutate(notification.id)
  }

  return (
    <NotificationItem key={notification.id} onClick={handleClick}>
      <Description>
        <Dot isRead={notification.isRead} />
        <ContentWrapper>
          <p>{notification.title}</p>
          <p>{notification.description}</p>
        </ContentWrapper>
      </Description>
      <DateItem>
        <p>{moment(notification.createDate).format('HH:mm')}</p>
        <p>{moment(notification.createDate).format('DD/MM/YYYY')}</p>
      </DateItem>
    </NotificationItem>
  )
}

export default Notification
