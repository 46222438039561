import React, { MouseEvent } from 'react'
import styled from 'styled-components'
import { useGetCourseOverview } from '../../hooks/queries/courseOverview'
import CourseOverviewSideBar from './CourseOverviewSidebar'
import { Outlet } from 'react-router-dom'
import { useParams } from 'react-router'

const CourseOverviewWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 100vh;

  ul,
  ol {
    list-style: initial;
  }
`

const Sidebar = styled.aside`
  flex-basis: 22rem;
  flex-grow: 1;
  padding-right: 42px;
  padding-left: 77px;
  padding-block: 72px;
  position: sticky;
  top: 0;
`

const Content = styled.main`
  flex-basis: 0;
  flex-grow: 999;
  padding-inline: 92px;
  padding-block: 72px;
  min-inline-size: 50%;
  background-color: #fff;
  min-height: 100vh;
`

const LoadingContainer = styled.div`
  padding: 70px 0;
  color: ${({ theme }) => theme.colors.primary};
  min-height: 100vh;
`

const CourseOverview = () => {
  const { stackId = '' } = useParams<{ stackId: string }>()
  const { data: course, isLoading, isError } = useGetCourseOverview(stackId)
  const [activeIndex, setActiveIndex] = React.useState(0)

  const handleItemClick = (e: MouseEvent<HTMLLIElement>, index: number) => {
    e.preventDefault()
    setActiveIndex(index)
    scrollToSection(index)
  }

  const scrollToSection = (index: number) => {
    window.scrollTo({
      top: document.getElementById(`#${index}`)?.offsetTop || 0 + 100,
      behavior: 'smooth'
    })
  }

  if (isLoading) return <LoadingContainer />
  if (isError) return <div>Error...</div>

  return (
    <CourseOverviewWrapper>
      <Sidebar>
        {course && (
          <CourseOverviewSideBar data={course.courseOverviews} onClick={handleItemClick} activeIndex={activeIndex} />
        )}
      </Sidebar>
      <Content>
        <Outlet />
      </Content>
    </CourseOverviewWrapper>
  )
}

export default CourseOverview
