import { FieldValues, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

export const useFormHook = <T extends FieldValues>(FormSchema: any) => {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    getValues,
    formState: { errors },
    control,
    setValue
  } = useForm<T>({ resolver: yupResolver(FormSchema) })

  return { register, handleSubmit, errors, control, reset, getValues, watch, setValue }
}

export { yup }
