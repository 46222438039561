import React, { useState } from 'react'
import { useParams } from 'react-router'
import { SurveyParticipantSubmissionStatus, SurveyQuestionType, SurveyType } from 'src/api/services/survey'
import SurveyReview from 'src/components/survey'
import SurveyExam from 'src/components/surveyExam'
import { useFinishSurvey } from 'src/hooks/mutations/survey'
import {
  useGetSurveySubmission,
  useSurveyConfiguration,
  useSurveyConfigurationQuestions
} from 'src/hooks/queries/survey'
import { theme } from 'src/themes/theme'
import styled from 'styled-components'
import FullyRated from './components/FullyRated'
import PartiallyRated from './components/PartiallyRated'

const Container = styled.div`
  padding: 8px 0px;
  min-height: 100vh;
`

const Title = styled.h1`
  padding: 84px 142px 32px 142px;
  color: #170738;
  font-weight: 600;
  font-size: 40px;
  line-height: 60px;

  @media ${theme.devices.tablet} {
    padding: 24px 0px 48px 48px;
  }
`

const SurveyTypes = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
  padding: 0px 142px;

  @media ${theme.devices.tablet} {
    padding: 0px 48px;
  }
`

const SurveyTypeText = styled.p<{ activeType: boolean }>`
  ${({ activeType }) => `
  color: ${activeType ? '#000000' : '#808080'};
  font-weight: 500;
  font-size: 22px;
  line-height: 42px`}
`

const SurveyTypeSeparator = styled.div`
  background-color: #39b5b5;
  width: 4px;
  height: 39px;
`

const Grade = styled.p`
  padding: 0px 142px;
  margin-top: 32px;
`

const SurveyQuestionsPage = () => {
  const [finishedSurveyState, setFinishedSurveyState] = useState<'not-finished' | 'partially-rated' | 'fully-rated'>(
    'not-finished'
  )

  const { id = '' } = useParams()

  const { data: configData } = useSurveyConfiguration(id)
  const { data: submissionData, refetch: refetchSubmission } = useGetSurveySubmission(id)

  const { data: questionsData, isLoading } = useSurveyConfigurationQuestions(
    id,
    submissionData?.surveySubmission.startDateTime || ''
  )
  const { mutate } = useFinishSurvey()

  const handleSubmit = () => {
    if (!submissionData) {
      return
    }
    mutate(submissionData.surveySubmission.id, {
      onSuccess: async () => {
        await refetchSubmission()
        setFinishedSurveyState(
          questionsData?.questions.every((q) => q.questionType === SurveyQuestionType.MultipleChoice)
            ? 'fully-rated'
            : 'partially-rated'
        )
      }
    })
  }

  if (isLoading || !questionsData || !submissionData || !configData) {
    return <Container />
  }

  if (finishedSurveyState === 'fully-rated') {
    return <FullyRated submission={submissionData.surveySubmission} configuration={configData.surveyConfiguration} />
  }

  if (finishedSurveyState === 'partially-rated') {
    return <PartiallyRated isExam={configData.surveyConfiguration.surveyType === SurveyType.Exam} />
  }

  return (
    <Container>
      <Title>Questionnaires & Exams</Title>
      {submissionData.surveySubmission.status !== SurveyParticipantSubmissionStatus.Started && (
        <Grade>
          {submissionData.surveySubmission.status === SurveyParticipantSubmissionStatus.Finished
            ? 'Grading incomplete'
            : 'Grading completed'}{' '}
          - {submissionData.surveySubmission.totalScore}/{configData.surveyConfiguration.totalScore}
        </Grade>
      )}
      {configData.surveyConfiguration.surveyType === SurveyType.Exam ? (
        <SurveyExam
          questions={questionsData.questions}
          onSubmit={handleSubmit}
          submission={submissionData.surveySubmission}
        />
      ) : (
        <SurveyReview
          questions={questionsData.questions}
          onSubmit={handleSubmit}
          submission={submissionData.surveySubmission}
        />
      )}
    </Container>
  )
}

export default SurveyQuestionsPage
