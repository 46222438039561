import { yup } from 'src/hooks/custom/useForm'

export const ChangePasswordSchema = yup.object().shape({
  currentPassword: yup.string().required('Current password is required'),
  newPassword: yup
    .string()
    .min(3, 'Password should be at least 3 characters long')
    .matches(/^(?=.*[a-z])/, 'Must contain at least one lowercase character')
    .matches(/^(?=.*[0-9])/, 'Must contain at least one number')
    .matches(/^(?=.*[!@#%&$])/, 'Must contain at least one special character (!@#%&$)'),
  confirmPassword: yup.string().oneOf([yup.ref('newPassword'), null], 'Passwords must match')
})

export const ResetPasswordSchema = yup.object().shape({
  newPassword: yup.string().min(8, 'Password should be at least 8 characters long').required('Password is required'),
  confirmPassword: yup.string().oneOf([yup.ref('newPassword'), null], 'Passwords must match')
})

export const AddEventSchema = yup.object().shape({
  name: yup.string().required('Event name is required'),
  link: yup.string(),
  comment: yup.string(),
  stack: yup.mixed().required('Stack is required'),
  users: yup.mixed().required('Users is required'),
  seniority: yup.mixed().required('Seniority is required'),
  eventDate: yup.string().required('Event date is required'),
  eventStart: yup.string().required('Start is Required'),
  eventEnd: yup.string().required('End is Required')
})
