const padTo2Digits = (num: number) => num.toString().padStart(2, '0')

const convertMsToTime = (milliseconds: number) => {
  let seconds = Math.floor(milliseconds / 1000)
  let minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)

  seconds %= 60
  minutes %= 60

  if (!hours) {
    return `${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`
  }

  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`
}

export { padTo2Digits, convertMsToTime }
