import React from 'react'
import styled, { css } from 'styled-components'

type DateProps = {
  date: number
  day: string
}

const ContactUsWrapper = styled.div`
  border: 0.923px solid #f8f8f8;
  border-radius: 7px;
  padding: 11px;
  height: 48px;
  width: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const DateWrapper = styled.p`
  color: #170738;
  font-size: 19px;
`

const DayWrapper = styled.p`
  color: #a7aab2;
  font-size: 12px;
`

const Date = ({ date = 28, day = 'WED' }: DateProps) => {
  return (
    <ContactUsWrapper>
      <DateWrapper>{date}</DateWrapper>
      <DayWrapper>{day.slice(0, 3).toUpperCase()}</DayWrapper>
    </ContactUsWrapper>
  )
}

export default Date
