import React from 'react'
import { EduSyllabusSubject } from '../../api/services/syllabus'
import styled from 'styled-components'
import Icon from '../../components/icon/Icon'
import Row from '../../components/common/Row'

interface StudyMaterialItemProps {
  data: EduSyllabusSubject
  colored: boolean
}

const WithSideBar = styled.article`
  display: flex;
  flex-wrap: wrap;
  max-width: 90ch;
`

const SideBar = styled.div<{ colored: boolean }>`
  flex-basis: 14rem;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ colored }) => (colored ? '#F6AECE' : '#7B9EC0')};
  padding: 50px;
  border-radius: 8px 0 0 8px;
`

const Content = styled.div`
  flex-basis: 0;
  flex-grow: 999;
  min-inline-size: 50%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: #fbfcff;
  border-radius: 0 8px 8px 0;
`

const Title = styled.p`
  font-size: 18px;
  font-weight: 700;
  color: #fff;
`

const SubTitle = styled.p`
  font-size: 18px;
  font-weight: 700;
  color: #170738;
`

const Text = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #170738;
`

const SubText = styled.p<{ completed: boolean }>`
  font-size: 12px;
  font-weight: 400;
  color: #55555f;
  text-align: right;
`

const IconContainer = styled.div<{ isCompleted: boolean }>`
  opacity: ${({ isCompleted }) => (isCompleted ? 1 : 0)};
`

const cssAppliedContent = (body: string) => `
    <div class="desc">
      <style>
        .desc p, 
        .desc li,
        .desc h1,
        .desc h2,
        .desc h3,
        .desc h4,
        .desc h5,
        .desc h6 {
         font-size: 14px;
         color: #170738;
         font-weight: 500;
         line-height: 1.5;
        }
      </style>
      ${body}
    <div>
    `
const StudyMaterialItem = ({ data, colored = false }: StudyMaterialItemProps) => {
  const [isCompleted, setIsCompleted] = React.useState(false)
  const handleCompleted = () => {
    setIsCompleted((prev) => !prev)
  }
  return (
    <WithSideBar>
      <SideBar colored={colored}>
        <Title>{data.name}</Title>
      </SideBar>
      <Content>
        <SubTitle>Resources</SubTitle>
        <div dangerouslySetInnerHTML={{ __html: cssAppliedContent(data.description) || '<p>there is no data</p>' }} />
        <Row alignItems="center" justify="flex-end" gap="8px">
          <SubText completed={isCompleted} onClick={handleCompleted}>
            {isCompleted ? 'Completed' : 'Complete'}
          </SubText>
          <IconContainer isCompleted={isCompleted}>
            <Icon iconName="iconCheckmark" color={'#FB60A5'} size={'20px'} />
          </IconContainer>
        </Row>
      </Content>
    </WithSideBar>
  )
}

export default StudyMaterialItem
