import React from 'react'
import Section from 'src/components/common/Section'
import SectionHeadingComp from 'src/components/common/SectionHeading'
import styled, { css } from 'styled-components'
import AddEventForm from 'src/components/forms/add-event/AddEventForm'

const AddEventPageWrapper = styled.div`
  ${({ theme }) => css`
    padding: 80px 0 0;
    color: ${({ theme }) => theme.colors.primary};
    min-height: 100vh;

    @media ${theme.devices.tablet} {
      padding-top: 40px;
    }
  `}
`

const AddEventPage = () => {
  return (
    <AddEventPageWrapper>
      <Section>
        <SectionHeadingComp>Add Event</SectionHeadingComp>
        <AddEventForm />
      </Section>
    </AddEventPageWrapper>
  )
}

export default AddEventPage
