import moment from 'moment'
import React, { FC } from 'react'
import styled from 'styled-components'
import Modal from '../common/Modal'
import Icon from '../icon/Icon'

const Container = styled.div`
  ${({ theme }) => `
    display: flex;
    gap: 16px;
    width: 428px;
    font-family: ${theme.fonts.family.primary} ;
  `}
`

const EventInfo = styled.div`
  display: flex;
  flex-direction: column;
`

const EventDate = styled.p`
  color: #595959;
`

const EventWeekDay = styled.p`
  display: flex;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #170738;
  margin-bottom: 34px;
`

const EventTimeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const EventTime = styled.p`
  background-color: #f7f5fa;
  border-radius: 4px;
  padding: 16px;
`

const EventTitle = styled.p`
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #170738;
  margin: 24px 0px;
`

const EventDescription = styled.p`
  color: #595959;
  font-size: 14px;
  line-height: 16.93px;
  font-weight: 300;
`

const EventLink = styled.a`
  font-size: 14px;
  line-height: 21px;
  margin-top: 24px;
`

interface EventInfoModalProps {
  event:
    | {
        id: string
        link: string
        title: string
        description: string
        end: string
        start: string
      }
    | undefined
  onClose: () => void
}

const EventInfoModal: FC<EventInfoModalProps> = ({ event, onClose }) => {
  if (!event) {
    return null
  }

  return (
    <Modal destination={document.getElementById('root') as HTMLElement} padding="36px">
      <Container>
        <Icon iconName="chevronLeft" onClick={onClose} />
        <EventInfo>
          <EventWeekDay>
            {moment(event.start).format('dddd')}
            <EventDate>, {moment(event.start).format('D MMMM')}</EventDate>
          </EventWeekDay>
          <EventTimeContainer>
            <EventTime>{moment(event.start).format('HH:mm')}</EventTime> -
            <EventTime>{moment(event.end).format('HH:mm')}</EventTime>
          </EventTimeContainer>
          <EventTitle>{event.title}</EventTitle>
          <EventDescription>{event.description}</EventDescription>
          {event.link ? (
            <EventLink href={event.link} target="_blank" rel="noreferrer">
              {event.link}
            </EventLink>
          ) : null}
        </EventInfo>
      </Container>
    </Modal>
  )
}

export default EventInfoModal
