// style data sent with reach text editor
export const cssAppliedContent = (body: string) => `
    <div class="danger">
      <style>
        .danger p, 
        .danger li {
         font-size: 14px;
         color: #55555F;
         font-weight: 300;
         line-height: 1.5;
        }
        .danger h1,
        .danger h2,
        .danger h3,
        .danger h4,
        .danger h5,
        .danger h6 {
         color: #073763;
         margin-bottom: 16px;
        }
        .danger table {
          border-collapse: collapse;
          overflow: hidden;
          border-right: 1px solid #FB60A5;
          border-left: 1px solid #FB60A5;
          background: #fff;
        }
        .danger tr {
            border-bottom: 1px solid #FB60A5;
            border-top: 1px solid #FB60A5;
        }
        .danger td,
        .danger th{
          padding: 13px;
          font-size: 13px;
          color: #073763;
          font-weight: 500;    
          width: 200px !important;    
        } 
        .danger th {
          font-size: 16px;
          font-weight: 700;
          text-align: left;
        }
        .danger td li {
          font-size: 13px;
          color: #073763;
          font-weight: 500; 
        }
        .danger th:first-of-type {
          border-top-left-radius: 10px;
        }
        .danger th:last-of-type {
          border-top-right-radius: 10px;
        }
        .danger tr:last-of-type td:first-of-type {
          border-bottom-left-radius: 10px;
        }
        .danger tr:last-of-type td:last-of-type {
          border-bottom-right-radius: 10px;
        }
      </style>
      ${body}
    <div>
    `
