import React from 'react'
import { useParams } from 'react-router'
import EditEventForm from 'src/components/forms/edit-event/EditEventForm'
import { useEvent } from 'src/hooks/queries/events/useGetEvents'
import styled, { css } from 'styled-components'

const EditEventWrapper = styled.div`
  ${({ theme }) => css`
    padding: 80px 0 0;
    color: ${({ theme }) => theme.colors.primary};
    min-height: 100vh;

    @media ${theme.devices.tablet} {
      padding-top: 40px;
    }
  `}
`

const EditEventPage = () => {
  const { id = '' } = useParams()

  const { data, isLoading } = useEvent(id)

  if (isLoading) {
    return (
      <EditEventWrapper>
        <div>Loading...</div>
      </EditEventWrapper>
    )
  }

  return <EditEventWrapper>{data ? <EditEventForm event={data.event} /> : null}</EditEventWrapper>
}

export default EditEventPage
