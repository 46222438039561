import { useQuery } from 'react-query'
import { QUERY_KEYS } from 'src/enums'
import { useAuth } from 'src/context/authContext'
import { getAllSyllabus } from 'src/api/services/syllabus'

export const useAllSyllabus = () => {
  const { isAdmin } = useAuth()

  const { data, isError, isLoading } = useQuery([QUERY_KEYS.ALL_SYLLABUS], getAllSyllabus, { enabled: isAdmin })

  return { eduSyllabuses: data?.eduSyllabuses, isError, isLoading }
}
