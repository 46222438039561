import { DefaultTheme } from 'styled-components'

export const colorPalette = {
  violet: {
    100: '#2F264AB2',
    200: '#513661',
    300: '#170738',
    400: '#463961',
    500: '#21365A',
    600: '#8A658A',
    700: '#261F39',
    800: '#B196C1'
  },

  pink: {
    100: '#FDC3DB',
    primary: {
      10: '#FBC1DB',
      20: '#F6AECE',
      30: '#FA99C4',
      40: '#F883B7',
      50: '#FD71AF',
      60: '#FB60A5',
      70: '#FA509C',
      80: '#F44593',
      90: '#F82583',
      100: '#F0076F'
    }
  },

  blue: {
    100: '#3CC0BD',
    200: '#2A647B',
    300: '#21365A',
    400: '#39B5B5',
    500: '#3F99BC',
    primary: {
      10: '#B7CDE3',
      20: '#7B9EC0',
      30: '#5A81A7',
      40: '#46719A',
      50: '#3B658D',
      60: '#2E5D89',
      70: '#215280',
      80: '#164B7D',
      90: '#093F72',
      100: '#073763'
    }
  },

  white: {
    100: '#ffffff',
    200: '#FBFCFC',
    300: '#f7f5fa',
    400: '#f0e7ff',
    500: '#ffffff7a', // half transparent #ffffff for accordion progress bar
    600: '#fbfcff',
    700: '#f8f8f8'
  },

  grey: {
    100: '#e0e0e0',
    200: '#7D7D7D',
    300: '#C3C3C3',
    400: '#595959',
    primary: {
      10: '#F6F6F9',
      20: '#E9E9ED',
      30: '#D2D2DA',
      40: '#BCBCC6',
      50: '#A5A5AF',
      60: '#92929D',
      70: '#7E7E89',
      80: '#74747E',
      90: '#64646D',
      100: '#55555F'
    }
  },

  green: {
    100: '#d0f492'
  },

  red: {
    100: '#F14F48'
  },

  black: {
    100: '#000000',
    200: '#0c0b1291' // half transparent for modal overlay
  },
  additional: {
    1: '#170738',
    2: '#D0F492',
    3: '#E382AB',
    4: '#FBFCFF'
  },
  neutral: {
    1: '#FFFFFF',
    2: '#FAFAFA',
    3: '#F5F5F5',
    4: '#F0F0F0',
    5: '#D9D9D9',
    6: '#BFBFBF',
    7: '#8C8C8C',
    8: '#595959',
    9: '#434343',
    10: '#262626'
  },
  textAndIcons: {
    1: '#151B28',
    2: '#1D2433',
    3: '#34373E',
    4: '#4A505C',
    5: '#6C707A',
    6: '#9D9EA1',
    7: '#B2B2B2',
    8: '#C2C2C4',
    9: '#D3D3D3',
    10: '#E3E3E3',
    11: '#F5F5F5',
    12: '#FFFFFF'
  }
}

export const breakpoints = {
  mobile: '540px',
  mobileLandscape: '720px',
  tabletLandscape: '1030px',
  tablet: '890px',
  laptop: '1400px',
  midBreakpoint: '1350px',
  desktop: ''
}

const fonts = {
  names: {
    poppins: 'Poppins, sans-serif',
    helvetica: 'Helvetica Neue, sans-serif'
  },
  sizes: {
    xxsmall: '12px',
    xsmall: '14px',
    small: '15px',
    medium: '16px',
    large: '18px',
    xlarge: '20px',
    xxlarge: '24px'
  }
}

const theme: DefaultTheme = {
  fonts: {
    family: {
      primary: fonts.names.poppins,
      secondary: fonts.names.helvetica
    },
    sizes: fonts.sizes,
    colors: {
      primary: colorPalette.white[100]
    }
  },

  devices: {
    mobile: `screen and (max-width: ${breakpoints.mobile})`,
    mobileLandscape: `screen and (max-width: ${breakpoints.mobileLandscape})`,
    tablet: `screen and (max-width: ${breakpoints.tablet})`,
    tabletLandscape: `screen and (max-width: ${breakpoints.tabletLandscape})`,
    laptop: `screen and (max-width: ${breakpoints.laptop})`,
    midBreakpoint: `screen and (max-width: ${breakpoints.midBreakpoint})`,
    midBreakpointMin: `screen and (min-width: ${breakpoints.midBreakpoint})`,
    desktop: `screen and (max-width: ${breakpoints.desktop})`
  },

  colors: {
    primary: colorPalette.violet[300],
    secondary: colorPalette.white[100],
    tertiary: colorPalette.green[100]
  },

  components: {
    buttons: {
      fontSize: fonts.sizes.xsmall,
      color: colorPalette.white[100],
      buttonFull: {
        backgroundColor: colorPalette.pink.primary[60],
        borderColor: colorPalette.pink.primary[60],
        disabled: {
          backgroundColor: colorPalette.pink.primary[10],
          borderColor: colorPalette.pink.primary[10]
        }
      },
      buttonOutline: {
        backgroundColor: 'transparent',
        borderColor: colorPalette.white[100]
      },
      buttonAccordion: {
        backgroundColor: colorPalette.green[100],
        color: colorPalette.violet[200]
      },
      buttonAccordionAdmin: {
        backgroundColor: colorPalette.blue[500]
      },
      buttonEvents: {
        backgroundColor: colorPalette.blue[400]
      }
    },

    form: {
      title: {
        color: colorPalette.blue.primary[100],
        fontSize: '32px'
      },
      text: {
        color: colorPalette.grey.primary[100],
        fontSize: fonts.sizes.xsmall
      },
      input: {
        valid: {
          borderColor: colorPalette.grey[100],
          color: colorPalette.grey.primary[60]
        },
        invalid: {
          borderColor: colorPalette.pink.primary[100],
          color: colorPalette.pink.primary[100]
        },
        label: {
          fontSize: fonts.sizes.xsmall,
          color: colorPalette.grey[200]
        },
        messages: {
          fontSize: fonts.sizes.xxsmall,
          errorMessage: {
            color: colorPalette.pink.primary[100]
          },
          confirmMessage: {
            color: colorPalette.grey[400]
          }
        }
      },
      inputReadOnly: {
        borderColor: colorPalette.white[300],
        backgroundColor: colorPalette.white[300]
      },
      link: {
        fontSize: fonts.sizes.xxsmall,
        color: colorPalette.violet[200]
      },
      formOptions: {
        colors: {
          primary: colorPalette.grey[300],
          secondary: colorPalette.violet[200]
        },
        fontSize: fonts.sizes.xxsmall
      }
    },

    accordion: {
      color: colorPalette.white[100],
      header: {
        title: {
          fontSizes: {
            laptop: fonts.sizes.large,
            mobile: fonts.sizes.xxsmall
          }
        },
        progressStatus: {
          fontSizes: {
            laptop: fonts.sizes.medium,
            mobile: fonts.sizes.xxsmall
          }
        },
        progressBar: {
          height: '5px',
          backgroundColor: colorPalette.white[500],
          progress: {
            backgroundColor: colorPalette.white[100]
          }
        }
      },
      body: {
        maxHeight: '2000px',
        contentWrapper: {
          backgroundColor: colorPalette.white[600]
        },
        title: {
          fontSize: {
            laptop: '25px',
            mobile: fonts.sizes.large
          },
          color: colorPalette.violet[200]
        },
        text: {
          fontSize: {
            laptop: fonts.sizes.medium,
            mobile: fonts.sizes.xxsmall
          },
          color: colorPalette.violet[200]
        }
      }
    },

    modal: {
      overlay: {
        backgroundColor: colorPalette.black[200]
      },
      window: {
        backgroundColor: colorPalette.white[100],
        logOutModal: {
          fontSize: fonts.sizes.xsmall,
          color: colorPalette.black[100]
        }
      }
    }
  },

  layout: {
    header: {
      color: colorPalette.white[100],
      authorizedUserPanel: {
        fontSize: fonts.sizes.medium
      }
    },

    footer: {
      backgroundColor: colorPalette.blue.primary[100],
      textColor: colorPalette.white[100],
      contactUsTextColor: colorPalette.pink[100],
      borderColor: colorPalette.white[100],
      privacyAndCopyright: {
        color: colorPalette.neutral[1],
        size: fonts.sizes.medium
      },
      mobileNavigationFooter: {
        backgroundColor: colorPalette.white[100],
        textColor: colorPalette.violet[300]
      }
    },

    section: {
      heading: {
        color: colorPalette.violet[300],
        size: {
          laptop: '40px',
          tabletLandscape: '32px',
          mobile: '22px'
        }
      },
      listItem: {
        backgroundColor: colorPalette.white[200]
      },
      text: {
        size: {
          laptop: fonts.sizes.large,
          mobile: fonts.sizes.xsmall
        }
      }
    }
  },

  pages: {
    syllabusPage: {
      monthBar: {
        color: colorPalette.white[100],
        backgroundColor: {
          active: colorPalette.blue[500],
          inactive: colorPalette.violet[200]
        },
        fontSize: {
          laptop: fonts.sizes.medium,
          mobile: fonts.sizes.xxsmall
        }
      },
      week: {
        text: {
          fontSize: {
            laptop: fonts.sizes.large,
            mobile: fonts.sizes.xxsmall
          },
          color: colorPalette.violet[300]
        },
        progressBar: {
          backgroundColor: colorPalette.white[300],
          height: {
            laptop: '17px',
            mobile: '6px'
          },
          progress: {
            backgroundColor: colorPalette.white[400]
          }
        }
      }
    },

    profilePage: {
      backgroundColor: colorPalette.violet[100],
      contentSectionBackgroundColor: colorPalette.white[100],
      profilePageNavigation: {
        dot: colorPalette.pink.primary[50],
        color: colorPalette.grey.primary[100],
        active: colorPalette.blue.primary[100]
      },
      personalInformationForm: {
        formSeparatorColor: colorPalette.white[300],
        fontSize: {
          desktop: '22px',
          mobile: fonts.sizes.xsmall
        }
      }
    },

    landingPage: {
      heroSection: {
        textColor: colorPalette.white[100],
        backgroundColor: '#07376366',
        swiperPagination: {
          square: {
            color: {
              primary: colorPalette.white[100],
              active: colorPalette.pink.primary[60]
            }
          }
        }
      },

      programsSection: {
        programList: {
          listItemBackgroundColors: {
            first: colorPalette.blue[100],
            second: colorPalette.blue[200],
            third: colorPalette.violet[200],
            fourth: colorPalette.blue[300]
          },
          color: colorPalette.white[100],
          listItem: {
            title: {
              size: {
                laptop: fonts.sizes.large,
                mobile: fonts.sizes.medium
              }
            },
            text: {
              size: {
                laptop: fonts.sizes.medium,
                mobile: fonts.sizes.xsmall
              }
            }
          }
        }
      },

      stacksSection: {
        stackList: {
          title: {
            size: {
              laptop: fonts.sizes.large,
              mobile: fonts.sizes.small
            }
          },
          text: {
            size: {
              laptop: fonts.sizes.medium,
              mobile: fonts.sizes.xsmall
            }
          }
        },
        onHover: {
          borderColor: colorPalette.blue[100]
        }
      },

      benefitsSection: {
        backgroundColor: colorPalette.additional[4],
        color: colorPalette.violet[300],
        benefitsListItem: {
          backgroundColor: colorPalette.neutral[1],
          color: colorPalette.grey.primary[100],
          title: {
            size: {
              laptop: fonts.sizes.large,
              mobile: fonts.sizes.small
            }
          },
          text: {
            size: {
              laptop: fonts.sizes.medium,
              mobile: fonts.sizes.xsmall
            }
          }
        }
      },

      contactUsSection: {
        backgroundColor: colorPalette.blue.primary[100],
        textColor: colorPalette.white[100],
        contactUsTextColor: colorPalette.pink.primary[20],
        borderColor: colorPalette.white[100],
        contactInfo: {
          title: {
            size: {
              laptop: fonts.sizes.xxlarge,
              mobile: fonts.sizes.xlarge
            }
          },
          text: {
            size: {
              laptop: fonts.sizes.large,
              mobile: fonts.sizes.medium
            }
          },
          phoneNumber: {
            color: colorPalette.pink.primary[20],
            size: {
              laptop: fonts.sizes.xlarge,
              mobile: fonts.sizes.medium
            }
          }
        }
      }
    }
  },

  borderRadius: {
    primary: '4px',
    secondary: '2px',
    accordion: {
      header: {
        open: '4px 4px 0 0'
      },
      body: '0 0 4px 4px'
    }
  }
}

export { theme }
