import React from 'react'
import styled, { css } from 'styled-components'
import Section from 'src/components/common/Section'
import SectionHeadingComp from 'src/components/common/SectionHeading'
import BenefitsListItem from './BenefitsListItem'
import { data } from './data'
import { colorPalette } from 'src/themes/theme'

const BenefitsSection = styled.div`
  ${({ theme }) => css`
    padding: 124px 0;
    margin-top: 160px;
    background-color: ${theme.pages.landingPage.benefitsSection.backgroundColor};
    color: ${theme.pages.landingPage.benefitsSection.color};

    @media ${theme.devices.mobile} {
      padding: 60px 0;
    }
  `}
`
const BenefitsList = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px 12px;
  margin-top: 60px;

  @media ${({ theme }) => theme.devices.laptop} {
    grid-template-columns: repeat(auto-fit, minmax(296px, 1fr));
    grid-auto-rows: max-content;
  }
`

const Benefits = () => {
  return (
    <BenefitsSection>
      <Section>
        <SectionHeadingComp color={colorPalette.blue.primary[100]}>Program Benefits</SectionHeadingComp>
        <BenefitsList>
          {data.map(({ title, list }, id) => {
            return <BenefitsListItem key={id} title={title} list={list} />
          })}
        </BenefitsList>
      </Section>
    </BenefitsSection>
  )
}

export default Benefits
