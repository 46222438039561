import React from 'react'
import { Seniority } from 'src/api/services/stack'
import Icon from 'src/components/icon/Icon'
import { useGetStack } from 'src/hooks/queries/extended/useGetStack'
import { useGetUser } from 'src/hooks/queries/user'
import styled from 'styled-components'

const renderMemberSeniority = (seniority: Seniority) => {
  switch (seniority) {
    case 1:
      return 'Junior'
    case 2:
      return 'Middle'
    case 3:
      return 'Senior'
    default:
      return ''
  }
}

const Title = styled.h2`
  font-weight: 500;
  color: #170738;
  padding-bottom: 28px;
`

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const StackMemberContainer = styled.div`
  display: flex;
  background-color: #fcfdff;
  padding: 16px;
  align-items: center;
  justify-content: space-between;
`

const StackMemberInfoText = styled.p`
  font-size: 14px;
  line-height: 21px;
  color: #595959;
  flex: 1;
`

const MoreButton = styled.button`
  outline: none;
  background-color: #ffffff00;
  border: 0px;
`

const MyStack = () => {
  const { user } = useGetUser()

  const stackId = user?.eduTeamLead?.eduStack.id || ''

  const { eduStack } = useGetStack(stackId)

  return (
    <div>
      <Title>My Stack</Title>
      {eduStack ? (
        <List>
          {eduStack.eduMentors?.map((mentor) => (
            <StackMemberContainer key={mentor.id}>
              <StackMemberInfoText>
                {mentor.firstName} {mentor.lastName}
              </StackMemberInfoText>
              <StackMemberInfoText>
                {renderMemberSeniority(mentor.eduMentorSeniority)} • {eduStack.name}
              </StackMemberInfoText>
              <MoreButton>{/* <Icon iconName="iconThreeDots" /> */}</MoreButton>
            </StackMemberContainer>
          ))}
          {eduStack.eduInterns?.map((intern) => (
            <StackMemberContainer key={intern.id}>
              <StackMemberInfoText>
                {intern.firstName} {intern.lastName}
              </StackMemberInfoText>
              <StackMemberInfoText>Intern • {eduStack.name}</StackMemberInfoText>
              <MoreButton>{/* <Icon iconName="iconThreeDots" /> */}</MoreButton>
            </StackMemberContainer>
          ))}
        </List>
      ) : null}
    </div>
  )
}

export default MyStack
