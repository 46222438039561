import React from 'react'
import styled from 'styled-components'
import { AnalyticsProps } from './InternshipChart'
import { COLORS } from '../../constants/colors'

const Rectangle = styled.div<{ color: string }>`
  width: 21px;
  height: 8px;
  background-color: ${({ color }) => color};
`

const Flex = styled.div<{ direction: string; alignItems: string }>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  align-items: ${({ alignItems }) => alignItems};
  gap: 10px;
`

const Subject = styled.p`
  font-size: 14px;
  color: #170738;
`

const ChartResults = ({ data }: AnalyticsProps) => {
  return (
    <div>
      <Flex direction={'column'} alignItems={'flex-start'}>
        {data.components?.map((entry, index) => {
          return (
            <Flex direction={'row'} key={`cell-${index}`} alignItems="center">
              <Rectangle color={COLORS[index % COLORS.length]} />
              <Subject>{entry.name}</Subject>
              <Subject>{entry.points}</Subject>
            </Flex>
          )
        })}
      </Flex>
    </div>
  )
}

export default ChartResults
