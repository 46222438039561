import React from 'react'
import styled, { css } from 'styled-components'
import Section from 'src/components/common/Section'
import Calendar from './Calendar'

const EventsPageWrapper = styled.div`
  ${({ theme }) => css`
    padding: 80px 0 0;
    color: ${({ theme }) => theme.colors.primary};
    min-height: 100vh;

    @media ${theme.devices.tablet} {
      padding-top: 40px;
    }
  `}
`

const EventsPage = () => {
  return (
    <EventsPageWrapper>
      <Section>
        <Calendar />
      </Section>
    </EventsPageWrapper>
  )
}

export default EventsPage
