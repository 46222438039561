import React from 'react'
import AccordionGenericBody from '../../common/Accordion/AccordionGenericBody'
import AccordionGenericHeader from '../../common/Accordion/AccordionGenericHeader'
import AccordionGenericItem from '../../common/Accordion/AccordionGenericItem'
import AccordionHeader from './SyllabusAccordionHeader'
import AccordionBody from './SyllabusAccordionBody'
import { SubjectType, useSyllabus } from 'src/context/syllabusContext'
import { useAuth } from 'src/context/authContext'

interface IAccordionGenericProps {
  items: any
}

const SyllabusAccordion = ({ items }: IAccordionGenericProps) => {
  const [openIndex, setOpenIndex] = React.useState<string | number>('')
  const { isIntern } = useAuth()
  const { setActiveSubjectId } = useSyllabus()

  const handleAccordionToggle = (id: number | string) => {
    setActiveSubjectId(String(id))
    openIndex === id ? setOpenIndex('') : setOpenIndex(id)
  }

  return (
    <>
      {items.map((subject: SubjectType, i: number) => {
        return (
          <AccordionGenericItem key={i}>
            <AccordionGenericHeader onClick={() => handleAccordionToggle(subject?.id)}>
              <AccordionHeader
                id={subject?.id}
                isOpen={subject?.id === openIndex}
                admin={!isIntern}
                title={subject?.name}
                backgroundColor="any"
                progress="30"
              />
            </AccordionGenericHeader>
            <AccordionGenericBody isOpen={subject?.id === openIndex}>
              <AccordionBody
                week="1"
                admin={!isIntern}
                id={subject?.id}
                content={subject?.description}
                onComplete={() => setOpenIndex('')}
              />
            </AccordionGenericBody>
          </AccordionGenericItem>
        )
      })}
    </>
  )
}

export default SyllabusAccordion
