import { IconTypes } from 'src/components/icon/Icon'

type data = {
  stack: string
  technologies: string
  icon: IconTypes
}

export const data: data[] = [
  {
    stack: 'Backend',
    technologies: '.Net · Java · Python · PHP · Node',
    icon: 'backend'
  },
  {
    stack: 'Frontend',
    technologies: 'Mark Up · React · Angular · Wordpress',
    icon: 'frontend'
  },
  {
    stack: 'Mobile',
    technologies: 'Android · iOS · React Native · Flutter',
    icon: 'mobile'
  },
  {
    stack: 'Infrastructure & Networking',
    technologies: 'Linux SysAdmin · DevOps / Cloud Services',
    icon: 'networking'
  },
  {
    stack: 'Testing & Quality Assurance',
    technologies: 'Manual Testing · Test Automation',
    icon: 'testing'
  },
  {
    stack: 'Data & Business Inteligence',
    technologies: 'SQL · Data Science · Data Engineering · Data Analytics',
    icon: 'dataAndMarketing'
  },
  {
    stack: 'Digital Marketing',
    technologies: 'Social Media Advertising · Account Management · Search Engine Optimization · Content Creation',
    icon: 'dataAndMarketing'
  },
  {
    stack: 'Product Design',
    technologies: 'Creative - UI/UX · Graphic Design · Motion Graphics Design',
    icon: 'productDesign'
  }
]
