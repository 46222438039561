export const COLORS = [
  '#165BAA',
  '#A155B9',
  '#F765A3',
  '#1DDD8D',
  '#16BFD6',
  '#D5ACA9',
  '#424B54',
  '#ED6A5A',
  '#953BBF'
]
