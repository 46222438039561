import { EventContentArg } from '@fullcalendar/core'
import React from 'react'
import { useNavigate } from 'react-router'
import Icon from 'src/components/icon/Icon'
import { useAuth } from 'src/context/authContext'
import styled, { css } from 'styled-components'

const EventWrapper = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
`

const EventTitle = styled.p`
  ${({ theme }) => css`
    font-size: 12px;
    font-weight: 500;
    color: #5f5f5f;
    font-family: ${theme.fonts.family.primary};
  `}
`

const EventTime = styled.div`
  ${({ theme }) => css`
    font-size: 12px;
    font-weight: 600;
    font-family: ${theme.fonts.family.secondary};
    color: #5f5f5f;
    display: flex;
    justify-content: space-between;
    gap: 8px;
  `}

  .event-item {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
`

interface EventProps {
  eventInfo: EventContentArg
  onDelete: () => void
}

const Event = ({ eventInfo, onDelete }: EventProps) => {
  const { title, publicId } = eventInfo.event._def

  const navigate = useNavigate()
  const { isAdmin } = useAuth()

  return (
    <EventWrapper id={publicId} className="event-wrapper">
      <EventTitle>{title}</EventTitle>
      <EventTime>
        <span>{eventInfo.timeText}</span>
        {isAdmin && (
          <>
            <Icon
              iconName="iconPencil"
              onClick={() => navigate(`/pages/event/${publicId}/edit`)}
              color="black"
              size="12px"
            />
            <Icon iconName="iconRecycleBin" color="black" size="12px" onClick={onDelete} />
          </>
        )}
      </EventTime>
    </EventWrapper>
  )
}

export default Event
