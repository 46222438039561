import React from 'react'
import { CourseOverview } from '../../api/services/courseOverview'
import styled from 'styled-components'
import { useGetCourseOverview } from '../../hooks/queries/courseOverview'
import { useGetUser } from '../../hooks/queries/user'
import { cssAppliedContent } from '../../constants/dangerouslyStyledHtmlStyles'

interface Props {
  data: CourseOverview[]
}

const ContentItem = styled.div`
  margin-bottom: 64px;
  list-style: initial;
  max-width: 90ch;
  list-style: initial;
  margin-bottom: 64px;
`

const Title = styled.p`
  font-size: 14px;
  color: #fb60a5;
  font-weight: 500;
  margin-bottom: 24px;
`

const LoadingContainer = styled.div`
  padding: 70px 0;
  color: ${({ theme }) => theme.colors.primary};
  min-height: 100vh;
`

const Article = styled.article``

const CourseOverviewContent = () => {
  const { user } = useGetUser()
  const stackId = user?.eduIntern?.eduStack.id || ''
  const { data: course, isLoading, isError } = useGetCourseOverview(stackId)
  const hasCourseOverview = course?.courseOverviews?.some((item) => item.name.toLowerCase() === 'course overview')

  if (isLoading) return <LoadingContainer />
  if (isError) return <div>Error...</div>
  return (
    <div>
      {course?.courseOverviews.map((item, index) => {
        return (
          <Article key={item.id} id={`#${hasCourseOverview ? index - 1 : index}`}>
            <Title>{item.name}</Title>
            <ContentItem dangerouslySetInnerHTML={{ __html: cssAppliedContent(item.description) }} />
          </Article>
        )
      })}
    </div>
  )
}

export default CourseOverviewContent
