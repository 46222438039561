import { useMutation } from 'react-query'
import { AxiosError } from 'axios'

import { UploadAnswerAttachmentPayload, uploadAnswerAttachment } from 'src/api/services/survey'

const useUploadAnswerAttachment = () => {
  return useMutation<void, AxiosError, UploadAnswerAttachmentPayload>({
    mutationFn: uploadAnswerAttachment
  })
}

export default useUploadAnswerAttachment
