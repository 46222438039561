import { AxiosError } from 'axios'
import { QUERY_KEYS } from 'src/enums'
import { useMutation, useQueryClient } from 'react-query'
import { EventOwnerType, AddEventPayload, createEvent } from 'src/api/services/events'

export const useCreateEvent = () => {
  const client = useQueryClient()

  const { mutate, isLoading, isError } = useMutation<
    void,
    AxiosError,
    { payload: AddEventPayload; type: EventOwnerType }
  >(({ payload, type }) => createEvent(payload, type), {
    onSuccess: () => client.invalidateQueries([QUERY_KEYS.EVENTS])
  })

  return { mutate, isLoading, isError }
}
