import React from 'react'
import { ProgramSection, ProgramList, ProgramListItem, VideoContainer } from './program.styles'
import Section from 'src/components/common/Section'
import SectionHeadingComp from 'src/components/common/SectionHeading'
import SectionText from 'src/components/common/SectionText'
import { colorPalette, theme } from 'src/themes/theme'

const programData = [
  {
    title: 'Method',
    text: '1-1 Mentorship',
    backgroundColor: colorPalette.blue.primary[20]
  },
  {
    title: 'Admission',
    text: 'Twice a year',
    backgroundColor: colorPalette.pink.primary[20]
  },
  {
    title: 'Entry Requriements',
    text: 'Basic Knowledge in chosen Stack',
    backgroundColor: colorPalette.blue.primary[20]
  },
  {
    title: 'Program Length',
    text: '18 months',
    backgroundColor: colorPalette.pink.primary[20]
  }
]

const Program = () => {
  return (
    <ProgramSection>
      <Section>
        <SectionHeadingComp color={colorPalette.blue.primary[100]}>About Program</SectionHeadingComp>
        <SectionText
          fontWeight="400"
          fontFamily={theme.fonts.family.primary}
          maxWidth="50%"
          color={colorPalette.grey.primary[100]}>
          Making Science Sweeft offers a paid career acceleration program, which is designed for any interested
          individual willing to build a successful career in the fields of software development and digital marketing
          through enhancing their technical, soft, and hard skills.
        </SectionText>
        <ProgramList>
          {programData.map(({ title, text, backgroundColor }, id) => {
            return (
              <ProgramListItem backgroundColor={backgroundColor} key={id}>
                <p>{title}</p>
                <p>{text}</p>
              </ProgramListItem>
            )
          })}
        </ProgramList>
        <VideoContainer>
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/RCKy522O3C0"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
        </VideoContainer>
      </Section>
    </ProgramSection>
  )
}

export default Program
